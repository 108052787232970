import React from 'react'
import { MdClear } from 'react-icons/md'
import { useReducer, useEffect, useState } from 'react'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { parseISO, format } from 'date-fns'
import ModalConfirmAction from './ModalConfirmAction';

export default function ModalEditTask({task, hideModal, updateTask, deleteTask, completeTask}) {
    const [showConfirmDelete, setShowConfirmDelete] = useState(() => { return false })
    const [updatedTask, updatedTaskDispatch] = useReducer((state, action) => {
        if (action.type === "init") {
            return { ...state, loaded: true }
        }
        if (action.type === "update") {
            let update = {}
            update[action.prop] = action.value
            return { ...state, ...update }
        }
        return state
    }, task)
    const handleDateChange = (d) => {
        const formattedDate = format(d, 'yyyy-MM-dd')
        updatedTaskDispatch({type: "update", value: formattedDate, prop: "date_due"})
    }
    const getCalendarFriendlyDate = () => {
        if (!updatedTask.date_due || updatedTask.date_due === "") return new Date()
        return parseISO(updatedTask.date_due)
    }
    const saveChanges = () => {
        updateTask(updatedTask)
        hideModal()
    }
    const handleDelete = () => {
        deleteTask(task._id)
        hideModal()
    }
    const handleComplete = () => {
        completeTask(task._id)
        hideModal()
    }
    useEffect(() => {
        if (!task) return
        updatedTaskDispatch({type: "init"})
    // eslint-disable-next-line
    }, [])
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        {showConfirmDelete && <ModalConfirmAction hideModal={() => setShowConfirmDelete(false)} confirmButtonText="Delete task" text={`Are you sure you want to delete this task?  \n\n This action is permanent.`} heading="⚠️ Delete Task" takeActionFunction={handleDelete} /> }
                        
                        <div className="modal-header">
                            <h2>Edit Task</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="modal-edit-section">
                            <h3>Task Name</h3>
                            <input type="text" placeholder="What should this recipe be called?" value={updatedTask.name} onChange={(e) => updatedTaskDispatch({type: "update", prop: "name", value: e.target.value })} />
                        </div>

                        <div className="modal-edit-section">
                            <h3>Due Date</h3>
                            <div className="util-row util-align-center util-justify-center">
                                <Calendar date={getCalendarFriendlyDate()} onChange={handleDateChange}/>
                            </div>
                            {updatedTask.date_due && <div className="util-row util-align-center util-justify-center" style={{fontWeight: 400, textDecoration: "underline", fontSize: "14px", cursor: "pointer"}} onClick={() => updatedTaskDispatch({ type: "update", prop: "date_due", value: ""})}>Remove due date</div>}
                            
                        </div>

                        <div className="modal-buttons">
                            <span onClick={saveChanges}>Save Changes</span>
                            {updatedTask.status === "active" && <span onClick={handleComplete} style={{color: "white", backgroundColor: "var(--ml-color-val-green)"}}>Mark Complete</span>}
                        </div>
                        <div className="modal-buttons" style={{marginTop: "16px"}}>
                            <span style={{backgroundColor: "white", color: "black", border: "1px solid rgb(200, 200, 200)"}} onClick={hideModal}>Cancel</span>
                        </div>

                        <div className="modal-buttons-alternative">
                            <span onClick={() => setShowConfirmDelete(true)}>Delete task</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
