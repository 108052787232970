import React from 'react'
import { getSupplyById } from '../../common/Helpers'
import Badge from '../misc/Badge'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { useNavigate  } from 'react-router-dom'

export default function SlimCandleCard({candle, recipe, globalState}) {
    const navigate = useNavigate()
    return (
        <div className="slim-batch-item-container" onClick={() => navigate(`/candles/candle/${candle._id}`)} >
            <div className="slim-batch-item">
                <p>{candle.name}</p>
                {candle.wick && <span>{candle.wick}</span> }
                {!candle.wick && candle.wick_id && <span>{getSupplyById(candle.wick_id, globalState.supplies).supply_name}</span> }
                {!candle.wick && !candle.wick_id && <span>Wax Melt</span> }
                {candle.badges.length > 0 &&
                    <div className="slim-candle-card-badge-container">
                        {candle.badges.slice(0,5).map((badgeName) => (
                            <Badge badgeName={badgeName} key={badgeName} />
                        ))}
                        {candle.badges.length > 6 &&
                            <span>+ {candle.badges.length - 5} others</span>
                        }
                        {candle.badges.length === 6 &&
                            <span>+ 1 more</span>
                        }
                    </div>
                    }
            </div>
            <HiArrowNarrowRight/>
        </div>
    )
}
