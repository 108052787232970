import React from 'react'
import { useState, useEffect } from 'react'

export default function Notes({notes, updateNote, baseId}) {
    const [isEditing, setIsEditing] = useState(() => { return false })
    const [proposedNotes, setProposedNotes] = useState(() => { return notes })
    const revertUpdate = () => {
        setProposedNotes(notes)
        setIsEditing(false)
    }
    const saveChanges = () => {
        if (updateNote) updateNote(proposedNotes)
        setIsEditing(false)
    }
    useEffect(() => {
        setProposedNotes(notes)
    // eslint-disable-next-line
    }, [baseId])
    return (
        <div className="notes-container">
            {!isEditing &&
            <div>
                {proposedNotes && <p>{proposedNotes}</p>}
                {!proposedNotes && <p style={{marginBottom: "24px"}}>No notes found</p>}
                {!proposedNotes && <div className="general-list-show-more-button" onClick={() => setIsEditing(true)}>Tap to add notes</div>}
            </div>
            }
            {!isEditing && proposedNotes && <div onClick={() => setIsEditing(true)} className="general-list-show-more-button" style={{marginTop: "24px"}}>Edit notes</div> }

            {isEditing && 
            <textarea placeholder="What would you like to record?" value={proposedNotes} onChange={(e) => setProposedNotes(e.target.value)} rows="10"></textarea>
            }

            {isEditing &&
            <div>
                <div className="general-list-show-more-button" onClick={saveChanges}>Save Changes</div>
                <div className="general-list-show-more-button general-list-show-more-button-cancel" style={{marginTop: "12px"}} onClick={revertUpdate}>Cancel</div>
            </div>
            }
        </div>
    )
}
