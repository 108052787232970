import React from 'react'
import { MdDocumentScanner } from 'react-icons/md'
import { useNavigate  } from 'react-router-dom'
import { parseISO, format } from 'date-fns'
import HeaderReturnToPage from '../misc/HeaderReturnToPage'
import { sortByPropertyReverse } from '../../common/Helpers'

export default function CreateBatchRecipeSelection({globalState, dispatch}) {
    const navigate = useNavigate();
    const createWithRecipe = (id) => {
        return navigate(`/create/batch/${id}`)
    }
    const getDateAsString = (recipe) => {
        try {
            return format(parseISO(recipe.createdAt), 'PPP')
        } catch (error) {
            return ""
        }
    }
    return (
        <div className="general-page-create">
            <div className="general-page-create-section general-page-create-section-borderless ">
                <HeaderReturnToPage title="All batches" navigationUrl={false}/>
                <h1>New Batch</h1>
                <p>Select a recipe to get started</p>
            </div>
            <div className="general-page-create-section general-page-create-section-borderless">
                <div>
                    {sortByPropertyReverse(globalState.recipes, 'visual_id').map((recipe) => (
                    <div className="general-card general-card-recipe" key={recipe._id} onClick={() => createWithRecipe(recipe._id)}>
                        <div className="general-card-symbol"><MdDocumentScanner/></div>
                        <div>
                            <div className="general-card-name">{recipe.name}</div>
                            <div className="general-card-id">{recipe.visual_id} • {getDateAsString(recipe)}</div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
