import React from 'react'
import { useNavigate  } from 'react-router-dom'
import { parseISO, formatDistanceToNowStrict } from 'date-fns'

export default function PrimaryBatchCard({batch, navLocation}) {
    const navigate = useNavigate();
    const getDateAsString = () => {
        try {
            return formatDistanceToNowStrict(parseISO(batch.pour_date))
        } catch (error) {
            return ""
        }
    }
    const getGone = () => {
        if (!navLocation) return
        return navigate(navLocation)
    }
    return (
        <div className="general-card general-card-recipe" onClick={getGone}>
            <div>
                <div className="general-card-name">{batch.name}</div>
                <div className="general-card-id">{batch.recipe[0].visual_id}-{batch.visual_id} • {getDateAsString()} ago</div>
                <div className="general-card-description-small">{batch.recipe[0].name}</div>
            </div>
        </div>
    )
}
