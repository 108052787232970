import React from 'react'
import { useState, useEffect } from 'react'
import { MdDocumentScanner, MdFilterList } from 'react-icons/md'
import { HiOutlinePlus } from 'react-icons/hi2'
import PrimaryRecipeCard from './PrimaryRecipeCard'
import PreviewRecipeCard from './PreviewRecipeCard'
import RecipePreviewPane from './RecipePreviewPane'
import CollectionCard from '../collections/CollectionCard'
import { useNavigate, useSearchParams  } from 'react-router-dom'
import { parseISO } from 'date-fns'
import GeneralPageSearchBar from '../gadgets/GeneralPageSearchBar'
import LoadingSymbol from '../misc/LoadingSymbol'
import InternalNavigationSections from '../navigation/InternalNavigationSections'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'
import ModalNewCollection from '../modals/ModalNewCollection'
import ModalFilter from '../modals/ModalFilter'
import Api from '../../common/APIUtils'
import { getRecipeWaxes, standardCardSections, getCardsBySection, getSupplyNameById } from '../../common/Helpers'

export default function Recipes({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const [query, setQuery] = useState(() => { return "" })
    const [recipes, setRecipes] = useState(() => { return [] })
    const [favoriteRecipes, setFavoriteRecipes] = useState(() => { return [] })
    const [filter, setFilter] = useState(() => { return {} })
    const [selectedPreviewRecipe, setSelectedPreviewRecipe] = useState(() => { return false })
    const [selectedPreviewRecipeFavorite, setSelectedPreviewRecipeFavorite] = useState(() => { return false })
    const [collectionsQuery, setCollectionsQuery] = useState(() => { return "" })
    const [favoritesQuery, setFavoritesQuery] = useState(() => { return "" })
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const [isShowingFilterModal, setIsShowingFilterModal] = useState(() => { return false })
    const [isShowingNewCollectionModal, setIsShowingNewCollectionModal] = useState(() => { return false })
    const [view, setView] = useState(() => { return "all" })
    const navigate = useNavigate()
    const getCurrentRecipes = () => {
        try {
            if (!globalState.recipes) return []
            let filteredRecipes = globalState.recipes
            if (Object.keys(filter).length > 0) {
                filteredRecipes = globalState.recipes.filter((recipe) => {
                    // waxes, fragrances, wicks, fragranceLoad --- can match ANY of them
                    if ('waxes' in filter) {
                        const waxNames = filter.waxes
                        if (waxNames.includes(recipe.wax)) return true
                        if (recipe.wax) {
                            if (recipe.wax_blend_map) {
                                if (recipe.wax_blend_map.blends) {
                                    if (recipe.wax_blend_map.blends[0]) {
                                        const recipeWaxes = recipe.wax_blend_map.blends.map(r => r.name)
                                        let filterWaxSet = new Set(waxNames)
                                        if (recipeWaxes.some(item => filterWaxSet.has(item))) return true
                                    }
                                }
                            }
                            
                        }
                    }

                    if ('fragrances' in filter) {
                        const fragranceNames = filter.fragrances
                        if (fragranceNames.includes(recipe.fragrance_oil)) return true
                        if (!recipe.fragrance_oil) {
                            if (recipe.fragrance_oil_blend_map) {
                                if (recipe.fragrance_oil_blend_map.blends) {
                                    if (recipe.fragrance_oil_blend_map.blends[0]) {
                                        const recipeFragrances = recipe.fragrance_oil_blend_map.blends.map(r => r.name)
                                        let filterFragranceSet = new Set(fragranceNames)
                                        if (recipeFragrances.some(item => filterFragranceSet.has(item))) return true
                                    }
                                }
                            }
                        }
                    }

                    if ('wicks' in filter) {
                        // wicks is actually wick count
                        if (filter.wicks.includes(recipe.wick_count)) return true
                    }

                    if ('fragranceLoad' in filter) {
                        if (filter.fragranceLoad === 0) {
                            if (recipe.fragrance_load === 0) return true
                        }
                        if (filter.fragranceLoad > 0) {
                            if (parseFloat(recipe.fragrance_load) >= parseFloat(filter.fragranceLoad)) return true
                        }
                    }
                    return false
                })
            }
            const sortedRecipes = filteredRecipes.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            if (!query.trim()) return sortedRecipes
            return sortedRecipes.filter((recipe) => {
                let recipeWaxes = getRecipeWaxes(recipe, false, globalState.supplies)
                let recipeNotes = ""
                let recipeWick = ""
                if (recipe.wick_in_recipe && recipe.wick_name) recipeWick = recipe.wick_name
                if (recipe.wick_in_recipe && recipe.wick_id) recipeWick = getSupplyNameById(globalState.supplies, recipe.wick_id)
                if (recipe.notes) recipeNotes = recipe.notes
                const recipeTextToSearch = `${recipe.name}${recipeNotes}${recipe.fragrance_oil}${recipe.description}${recipe.color_name}${recipeWick}${recipe.visual_id}${recipeWaxes}${recipe.container_name}${recipe.recipe_type}`
                if (recipeTextToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const getFilterClass = () => {
        try {
            if (Object.keys(filter).length === 0) return "general-category-page-utility-button"
            return "general-category-page-utility-button general-category-page-utility-button-active"
        } catch (error) {
            return "general-category-page-utility-button"
        }
    }
    const getFavoriteRecipes = () => {
        try {
            if (!globalState.recipes) return []
            const filteredRecipes = globalState.recipes.filter((recipe) => {
                if (recipe.is_favorite) return true
                return false
            })
            const sortedRecipes = filteredRecipes.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            if (!favoritesQuery.trim()) return sortedRecipes
            return sortedRecipes.filter((recipe) => {
                let recipeWaxes = getRecipeWaxes(recipe, false, globalState.supplies)
                const recipeTextToSearch = `${recipe.name}${recipe.fragrance_oil}${recipe.description}${recipe.color_name}${recipe.visual_id}${recipeWaxes}${recipe.container_name}${recipe.recipe_type}`
                if (recipeTextToSearch.toLowerCase().indexOf(favoritesQuery.trim().toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const getCollectionsByRecent = () => {
        try {
            if (!globalState.collections) return []
            const sortedCollections = globalState.collections.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            if (!collectionsQuery.trim()) return sortedCollections
            return sortedCollections.filter((collection) => {
                let collectionDescription = ""
                if (collection.description) collectionDescription = collection.description
                if (`${collection.name}${collectionDescription}`.toLowerCase().indexOf(collectionsQuery.trim().toLowerCase()) !== -1) return true
                return false
            })
        } catch (error) {
            return []
        }
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const additionalRecipeListStyles = () => {
        try {
            if (recipes.length < 3) return { flexDirection: "column" }
            return {}
        } catch (error) {
            return {}
        }
    }
    const createCollection = (payload) => {
        setIsShowingNewCollectionModal(false)
        api.createNewCollection(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            fetchUserLibrary()
        })        
    }
    const restoreSpecificRecipe = (recipeId) => {
        try {
            api.restoreRecipe({recipe_id: recipeId})
            .then((res) => {
                fetchUserLibrary()
            })
            .catch((err) => {
                fetchUserLibrary()
            })
        } catch (error) {
            return
        }
    }
    const updatedSelectedRecipe = (recipeId) => {
        setSelectedPreviewRecipe(recipeId)
    }
    const updateSelectedFavoritesRecipe = (recipeId) => {
        setSelectedPreviewRecipeFavorite(recipeId)
    }
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
        setRecipes(getCurrentRecipes())
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        setRecipes(getCurrentRecipes())
        setFavoriteRecipes(getFavoriteRecipes())
    // eslint-disable-next-line
    }, [globalState, filter, query])
    useEffect(() => {
        dispatch({type: "nav", payload: {view: "recipes"}})
        fetchUserLibrary()
        if (localStorage.getItem("mlRecipeFilter") !== null) setFilter(JSON.parse(localStorage.getItem("mlRecipeFilter")))
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        const stringFilter = JSON.stringify(filter)
        localStorage.setItem("mlRecipeFilter", stringFilter)
    }, [filter])
    useEffect(() => {
        if(searchParams.get("v")) {
            if (searchParams.get("v") === "collections") return setView("collections")
        }
    // eslint-disable-next-line
    }, [searchParams])
    useEffect(() => {
        if (selectedPreviewRecipe) return 
        if (globalState.recipes.length === 0) return
        setSelectedPreviewRecipe(globalState.recipes[0]._id)
    // eslint-disable-next-line
    }, [])
    return (
        <div>
            {isShowingNewCollectionModal && <ModalNewCollection globalState={globalState} hideModal={() => setIsShowingNewCollectionModal(false)} createCollection={createCollection} />}
            {isShowingFilterModal && <ModalFilter filter={filter} globalState={globalState} setFilter={setFilter} hideModal={() => setIsShowingFilterModal(false)}/>}
            {globalState.library_loaded &&
            <div>
                {globalState.recipes.length > 0 &&
                <div className="general-category-page-main"> 
                    <div className="general-category-page-header">
                        <h2>My Recipes</h2>
                        {view !== "collections" && <div className="general-category-page-create" onClick={() => navigate('/create/recipe')}><HiOutlinePlus/> New Recipe</div>}
                        {view === "collections" && <div className="general-category-page-create" onClick={() => setIsShowingNewCollectionModal(true)}><HiOutlinePlus/> New Collection</div>}
                    </div>
                    <InternalNavigationSections sections={["all", "favorites", "collections", "archive"]} view={view} setView={setView} />
                    <div className="responsive-recipe-container">
                        {view === "all" &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                            <div className="general-category-page-utility-buttons">
                                <div className={getFilterClass()} onClick={() => setIsShowingFilterModal(true)}>
                                    <MdFilterList/>
                                    Filter
                                </div>
                            </div>
                        </div>


                        <div style={{margin: "0px 12px"}}>
                            {standardCardSections.map((batchSection) => (
                                <div key={batchSection.name}>
                                    {getCardsBySection(recipes, batchSection, 'updatedAt').length > 0 && (
                                        <CollapsibleListSection title={batchSection.name}>
                                            <div className="general-card-list-recipes" style={{paddingLeft: "0px"}}>
                                                {getCardsBySection(recipes, batchSection, 'updatedAt').map((recipe) => (
                                                    <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={goToPath} globalState={globalState}/>
                                                ))}
                                            </div>
                                        </CollapsibleListSection>
                                    )}
                                </div>
                            ))}
                        </div>

                        {recipes.length === 0 && query &&
                        <div className="general-card-list-recipes" style={additionalRecipeListStyles()}>                        
                            <div className="general-empty-state-container">
                                <div className="general-empty-state-description">
                                    <div className="general-empty-state-icon-container">
                                        <MdDocumentScanner/>
                                    </div>
                                    <h3>No results found!</h3>
                                    <div className="general-empty-state-instructions">
                                        "{query}" did not match any recipes.  Please try again.
                                    </div>
                                </div>
                                
                                <div className="general-empty-state-button-clear" onClick={() => setQuery("")}>Clear search</div>
                
                            </div>
                            
                        </div>
                        }

                        </>
                        }

                        {view === "favorites" &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar searchTerm={favoritesQuery} setSearchTerm={setFavoritesQuery} />
                        </div>
                        <div className="general-card-list-recipes" style={additionalRecipeListStyles()}>
                            {favoriteRecipes.map((recipe) => (
                                <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={goToPath} globalState={globalState}/>
                            ))}
                            {favoriteRecipes.length === 0 && favoritesQuery &&
                            <div className="general-empty-state-container">
                                <div className="general-empty-state-description">
                                    <div className="general-empty-state-icon-container">
                                        <MdDocumentScanner/>
                                    </div>
                                    <h3>No recipes found!</h3>
                                    <div className="general-empty-state-instructions">
                                        "{favoritesQuery}" did not match any recipes.  Please try again.
                                    </div>
                                    <div className="general-empty-state-button-clear" onClick={() => setFavoritesQuery("")}>Clear search</div>
                                </div>            
                            </div>
                            }
                        </div>
                        </>
                        }

                        {view === "favorites" && !favoritesQuery && globalState.library_loaded && favoriteRecipes.length === 0 &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>No favorite recipes</h3>
                                <div className="general-empty-state-instructions">
                                    Mark recipes as your favorite to see them here
                                </div>
                            </div>
                        </div>
                        }

                        {globalState.collections.length === 0 && view === "collections" && globalState.library_loaded &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>You don't have any collections</h3>
                                <div className="general-empty-state-instructions">
                                    Collections let you group recipes together
                                </div>
                            </div>
                        </div>
                        }

                        {globalState.collections.length > 0 && view === "collections" && globalState.library_loaded &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar searchTerm={collectionsQuery} setSearchTerm={setCollectionsQuery} />
                        </div>
                        <div className="general-card-list-collections" >
                            {getCollectionsByRecent().map((collection) => (
                                <CollectionCard key={collection._id} collection={collection} navLocation={goToPath} globalState={globalState}/>
                            ))}
                        </div>
                        </>
                        }
                    </div>
                    <div className="recipe-container-desktop">
                        {view === "all" &&
                        <div className="general-preview-list-page-container">

                            <div className="general-preview-list-list-container">
                                <div className="general-category-page-utilities">
                                    <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                                    <div className="general-category-page-utility-buttons">
                                        <div className={getFilterClass()} onClick={() => setIsShowingFilterModal(true)}>
                                            <MdFilterList/>
                                            Filter
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="general-card-list-recipes-surrogate">
                                    <div className="general-card-list-recipe-positions">
                                        <div className="general-card-list-recipes" style={{paddingLeft: "0px"}}>
                                            {recipes.map((recipe) => (
                                                <PreviewRecipeCard recipe={recipe} key={recipe._id} onSelect={updatedSelectedRecipe} selectedPreviewRecipeId={selectedPreviewRecipe}/>
                                            ))}
                                            {recipes.length === 0 && <div className="general-category-page-create" onClick={() => navigate('/create/recipe')}><HiOutlinePlus/> New Recipe</div> }
                                        </div>
                                    </div>
                                </div> 
                            </div>

                            <div className="general-preview-list-pane-container">
                                {selectedPreviewRecipe &&
                                    <RecipePreviewPane globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary} recipeId={selectedPreviewRecipe} setRecipeId={setSelectedPreviewRecipe} />
                                }
                                {!selectedPreviewRecipe && recipes.length > 0 && <div style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "grey"}}>Select a recipe to view more information</div> }
                                {!selectedPreviewRecipe && recipes.length === 0 && <div onClick={() => navigate('/create/recipe')} style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "black", cursor: "pointer"}}>Create your first recipe</div> }
                            </div>

                        </div>
                        }
                        {view === "favorites" &&
                        <div className="general-preview-list-page-container">

                            <div className="general-preview-list-list-container">
                                <div className="general-category-page-utilities">
                                    <GeneralPageSearchBar searchTerm={favoritesQuery} setSearchTerm={setFavoritesQuery} />
                                </div>
                                
                                <div className="general-card-list-recipes-surrogate">
                                    <div className="general-card-list-recipe-positions" style={{top: "260px", paddingRight: "12px"}}>
                                        <div className="general-card-list-recipes" style={{paddingLeft: "0px"}}>
                                            {favoriteRecipes.map((recipe) => (
                                                <PreviewRecipeCard recipe={recipe} key={recipe._id} onSelect={updateSelectedFavoritesRecipe} selectedPreviewRecipeId={selectedPreviewRecipeFavorite}/>
                                            ))}
                                            {favoriteRecipes.length === 0 && <div>No favorites yet</div> }
                                        </div>
                                    </div>
                                </div> 
                            </div>

                            <div className="general-preview-list-pane-container">
                                {selectedPreviewRecipeFavorite &&
                                    <RecipePreviewPane globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary} recipeId={selectedPreviewRecipeFavorite} setRecipeId={setSelectedPreviewRecipeFavorite} />
                                }
                                {!selectedPreviewRecipeFavorite && favoriteRecipes.length > 0 && <div style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "grey"}}>Select a recipe to view more information</div> }
                                {!selectedPreviewRecipeFavorite && favoriteRecipes.length === 0 && <div style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "grey"}}>You don't have any favorite recipes</div> }
                            </div>

                        </div>
                        }
                        
                        {globalState.collections.length > 0 && view === "collections" && globalState.library_loaded &&
                        <>
                        <div className="general-category-page-utilities">
                            <GeneralPageSearchBar searchTerm={collectionsQuery} setSearchTerm={setCollectionsQuery} />
                        </div>
                        <div className="general-card-list-collections" >
                            {getCollectionsByRecent().map((collection) => (
                                <CollectionCard key={collection._id} collection={collection} navLocation={goToPath} globalState={globalState}/>
                            ))}
                            <div style={{flexBasis: "30%"}}></div>
                            <div style={{flexBasis: "30%"}}></div>
                        </div>
                        </>
                        }
                        
                        {globalState.collections.length === 0 && view === "collections" && globalState.library_loaded &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>You don't have any collections</h3>
                                <div className="general-empty-state-instructions">
                                    Collections let you group recipes together
                                </div>
                            </div>
                        </div>
                        }
                        
                        {view === "archive" && globalState.archived_recipes.length > 0 &&
                        <div style={{transform: "translateY(-1px)"}}>
                            <p style={{margin: "16px 12px", marginBottom: "24px", textAlign: "center"}}>Selecting a recipe will restore it</p>
                            <div className="general-card-list-recipes general-card-list-recipes-archived-desktop">
                                {globalState.archived_recipes.map((recipe) => (
                                    <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={false} altAction={() => restoreSpecificRecipe(recipe._id)} globalState={globalState}/>
                                ))}
                                <div style={{flexBasis: "30%"}}></div>
                                <div style={{flexBasis: "30%"}}></div>
                            </div>
                        </div>
                        }

                        {globalState.archived_recipes.length === 0 && view === "archive" && globalState.library_loaded &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <h3>The archive is empty</h3>
                                <div className="general-empty-state-instructions">
                                    Archived recipes will appear here
                                </div>
                            </div>
                        </div>
                        }

                    </div>
                </div>
                }

                {globalState.recipes.length === 0 && view === "all" &&
                <div className="general-empty-state-container">
                    <div className="general-empty-state-description">
                        <div className="general-empty-state-icon-container">
                            <MdDocumentScanner/>
                        </div>
                        <h3>You don't have any recipes</h3>
                        <div className="general-empty-state-instructions">
                            Recipes allow you to create candle batches with specific ingredients like fragrance oils, vessels, wax and more.
                        </div>
                    </div>
                    
                    <button onClick={() => navigate('/create/recipe')}>Create new recipe</button>

                </div>
                }
                
                <div className="responsive-recipe-container">
                    {/* {globalState.recipes.length === 0 && view === "all" &&
                    <div className="general-empty-state-container">
                        <div className="general-empty-state-description">
                            <div className="general-empty-state-icon-container">
                                <MdDocumentScanner/>
                            </div>
                            <h3>You don't have any recipes</h3>
                            <div className="general-empty-state-instructions">
                                Recipes allow you to create candle batches with specific ingredients like fragrance oils, vessels, wax and more.
                            </div>
                        </div>
                        
                        <button onClick={() => navigate('/create/recipe')}>Create new recipe</button>

                    </div>
                    } */}
                    
                    
                    {view === "archive" && globalState.archived_recipes.length > 0 &&
                    <div style={{transform: "translateY(-88px)"}}>
                        <p style={{margin: "16px 12px", marginBottom: "24px", textAlign: "center"}}>Tapping a recipe will restore it</p>
                        <div className="general-card-list-recipes" style={additionalRecipeListStyles()}>
                            {globalState.archived_recipes.map((recipe) => (
                                <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={false} altAction={() => restoreSpecificRecipe(recipe._id)} globalState={globalState}/>
                            ))}
                        </div>
                    </div>
                    }

                    {view === "archive" && globalState.library_loaded && globalState.archived_recipes.length === 0 &&
                    <div className="general-empty-state-container">
                        <div className="general-empty-state-description">
                            <h3>The archive is empty</h3>
                            <div className="general-empty-state-instructions">
                                Archived recipes will appear here
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
            {!globalState.library_loaded &&
                <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div>
            }
        </div>
    )
}
