import React from 'react'
import { useState, useEffect, useReducer } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import LoadingSymbol from '../misc/LoadingSymbol'
import HeaderReturnToPage from '../misc/HeaderReturnToPage'
import { capitalizeFirstLetter, gramsToOuncesString, getPrimaryDomain } from '../../common/Helpers'
import Api from '../../common/APIUtils'
import ModalConfirmAction from '../modals/ModalConfirmAction'

export default function InventorySupplyItem({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const { supplyId } = useParams()
    const navigate = useNavigate();
    const [supplyItem, setSupplyItem] = useState(() => { return false })
    const [isLoading, setIsLoading] = useState(() => { return true })
    const [isEditing, setIsEditing] = useState(() => { return false })
    const [proposesReactivation, setProposesReactivation] = useState(() => { return false })
    const [proposesArchive, setProposesArchive] = useState(() => { return false })
    const [proposedSupplyItem, proposedSupplyItemDispatch] = useReducer((state, action) => {
        if (action.type === "reset") {
            let update = { ...action.value, loaded: true }
            if ("unit_price_pennies" in action.value) update["unit_price_dollars"] = (action.value["unit_price_pennies"]/100).toFixed(2)
            return { ...state, ...update }
        }
        if (action.type === "update") {
            let update = {}
            update[action.name] = action.value
            return { ...state, ...update }
        }
        return state
    },
    {
        loaded: false
    })
    const updateSupplyItem = () => {
        try {
            setIsLoading(true)
            const supply = globalState.supplies.find(s => s._id === supplyId)
            setSupplyItem(supply)
            setIsLoading(false)
        } catch (error) {
            return
        }
    }
    const openSupplierWebsite = () => {
        try {
            if (!supplyItem.supplier_website) return false
            if (!getPrimaryDomain(supplyItem.supplier_website)) return false
            return window.open(supplyItem.supplier_website, '_blank').focus()
        } catch (error) {
            return false
        }
    }
    const saveChanges = () => {
        // Validate changes

        // submit changes
        let payload = {
            supply_name: proposedSupplyItem.supply_name,
            supplier_name: proposedSupplyItem.supplier_name,
            supplier_website: proposedSupplyItem.supplier_website,
            unit_price_pennies: (proposedSupplyItem.unit_price_dollars * 100).toFixed(0),
            notes: proposedSupplyItem.notes,
            units: proposedSupplyItem.units
        }
        api.updateSupplyItem(payload, supplyId)
        .then((res) => {
            setIsEditing(false)
            fetchUserLibrary()
        })
        .catch((err) => {
            setIsEditing(false)
            return
        })
    }
    const getVesselCapacity = () => {
        try {
            return parseFloat(supplyItem.properties.vessel_capacity_grams).toFixed(0)
        } catch (error) {
            return "-"
        }
    }
    const cancelChanges = () => {
        setIsEditing(false)
        proposedSupplyItemDispatch({type: "reset", value: supplyItem })
    }
    const attemptReactivation = () => {
        setProposesReactivation(false)
        let payload = {
            status: "active"
        }
        api.updateSupplyItem(payload, supplyId)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            return
        })
    }
    const attemptArchive = () => {
        setIsEditing(false)
        setProposesArchive(false)
        let payload = {
            status: "archived"
        }
        api.updateSupplyItem(payload, supplyId)
        .then((res) => {
            fetchUserLibrary()
            navigate('/candles/inventory')
        })
        .catch((err) => {
            return navigate('/candles/inventory')
        })
    }
    const proposeArchive = () => {
        setProposesArchive(true)
        setIsEditing(false)
    }
    const getCostOfGoodsSoldUnitSelector = () => {
        if (!isEditing) return <p>{supplyItem.units}</p>
        try {
            if (supplyItem.type === "wax") {
                return (
                    <div className="modal-create-simple-input select">
                        <select value={proposedSupplyItem.units} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "units", value: e.target.value })}>
                            <option value="lb">Pounds (lb)</option>
                            <option value="kg">Kilograms (kg)</option>
                        </select>
                    </div>
                )
            }
            if (proposedSupplyItem.type === "fragrance") {
                return (
                    <div className="modal-create-simple-input select">
                        <select value={proposedSupplyItem.units} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "units", value: e.target.value })}>
                            <option value="oz">Ounces (oz)</option>
                            <option value="ml">Milliliters (mL)</option>
                        </select>
                    </div>
                )
            }
            return <p>{supplyItem.units}</p>
        } catch (error) {
            return <p>{supplyItem.units}</p>
        }
    }
    useEffect(() => {
        if (!supplyId) return
        if (!globalState) return
        updateSupplyItem()
    // eslint-disable-next-line
    }, [globalState, supplyId])
    useEffect(() => {
        if (!supplyItem) return
        proposedSupplyItemDispatch({type: "reset", value: supplyItem })
    }, [supplyItem])
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    return (
        <div>
            {isLoading && <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div> }
            {!isLoading && supplyItem &&
            <div className="general-category-page-main general-category-page-main-flex">
                {proposesReactivation && <ModalConfirmAction hideModal={() => setProposesReactivation(false)} confirmButtonText="Reactivate" text={`Reactivate ${supplyItem.supply_name}?  \n\nThis will not impact any recipes or candles using this item.`} heading="Reactivate Supply" takeActionFunction={attemptReactivation} /> }
                {proposesArchive && <ModalConfirmAction hideModal={() => setProposesArchive(false)} confirmButtonText="Archive" text={`Archive ${supplyItem.supply_name}?  \n\nThis will not impact any recipes or candles using this item.`} heading="⚠️ Archive Supply" takeActionFunction={attemptArchive} /> }
                <div style={{marginTop: "24px"}}><HeaderReturnToPage navigationUrl={'/candles/inventory'} title="Back to inventory"/></div>
                <div className="general-category-page-header general-category-page-main-flex">
                    <h2>{supplyItem.supply_name}</h2>
                    {supplyItem.status === "active" &&
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        {isEditing && <div className="general-category-page-create" onClick={saveChanges} style={{backgroundColor: "var(--ml-color-accent)"}}>Save Changes</div>}
                        {isEditing && !proposesArchive && <div className="general-category-page-create" onClick={proposeArchive} style={{backgroundColor: "var(--ml-color-val-red)"}}>Archive</div>}
                        {isEditing && <div className="general-category-page-create general-category-page-create-inverse" onClick={cancelChanges}>Cancel</div>}
                        {!isEditing && <div className="general-category-page-create general-category-page-create-inverse" onClick={() => setIsEditing(true)}>Manage</div>}
                    </div>
                    }
                    {supplyItem.status === "archived" &&
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <div className="general-category-page-create" onClick={() => setProposesReactivation(true)} style={{backgroundColor: "var(--ml-color-accent)"}}>Reactivate</div>
                    </div>
                    }
                </div>
                <div className="inventory-item-main">
                    <div className="inventory-item-section-column inventory-item-section-column-60">
                        <div className="inventory-item-section">
                            <h3>Information</h3>
                            <div className="inventory-item-section-content">
                                <div>
                                    <div className="inventory-item-input-label">Supply Name</div>
                                    {isEditing && <input type="text" placeholder={supplyItem.supply_name} value={proposedSupplyItem.supply_name} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supply_name", value: e.target.value })} />}
                                    {!isEditing && <p>{supplyItem.supply_name}</p> }
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Type</div>
                                    <p>{capitalizeFirstLetter(supplyItem.type)}</p>
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Notes</div>
                                    {isEditing && <textarea value={proposedSupplyItem.notes} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "notes", value: e.target.value })}></textarea> }
                                    {!isEditing && !supplyItem.notes && <p>-</p> }
                                    {!isEditing && supplyItem.notes && <p className="inventory-item-multiline">{supplyItem.notes}</p> }
                                </div>
                            </div>
                        </div>
                        {supplyItem.properties && supplyItem.type === "container" &&
                        <div className="inventory-item-section">
                            <h3>Properties</h3>
                            <div className="inventory-item-section-content">
                                {supplyItem.properties.vessel_capacity_grams &&
                                <div>
                                    {/* Allow changing this if there are no batches using it */}
                                    <div className="inventory-item-input-label">Vessel Capacity</div>
                                    <p>{getVesselCapacity()} g ({gramsToOuncesString(supplyItem.properties.vessel_capacity_grams)})</p>
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <div className="inventory-item-section-column inventory-item-section-column-40">
                        <div className="inventory-item-section">
                            <h3>Suppliers</h3>
                            <div className="inventory-item-section-content">
                                <div>
                                    <div className="inventory-item-input-label">Supplier Name</div>
                                    {isEditing && <input type="text" placeholder={supplyItem.supplier_name ? supplyItem.supplier_name : "Enter a supplier name"} value={proposedSupplyItem.supplier_name} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supplier_name", value: e.target.value })} />}
                                    {!isEditing && <p>{supplyItem.supplier_name ? supplyItem.supplier_name : "-"}</p> }
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Supplier Website</div>
                                    {isEditing && <input type="text" placeholder={supplyItem.supplier_website ? supplyItem.supplier_website : "Enter a website for this supplier or supply"} value={proposedSupplyItem.supplier_website} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supplier_website", value: e.target.value })} />}
                                    {!isEditing && supplyItem.supplier_website && <p className="inventory-item-hyperlink" onClick={openSupplierWebsite}>{getPrimaryDomain(supplyItem.supplier_website)}</p> }
                                    {!isEditing && !supplyItem.supplier_website && <p>-</p> }
                                </div>
                            </div>
                        </div>
                        <div className="inventory-item-section">
                            <h3>Economics</h3>
                            <div className="inventory-item-section-content">
                                <div>
                                    <div className="inventory-item-input-label">Units</div>
                                    {getCostOfGoodsSoldUnitSelector()}
                                </div>
                                <div>
                                    <div className="inventory-item-input-label">Unit Pricing</div>
                                    {!isEditing && <p>${(supplyItem.unit_price_pennies/100).toFixed(2)}</p>}
                                    {isEditing &&
                                    <div className="util-row util-align-center" style={{gap: "4px"}}>
                                        <span>$</span>
                                        <input type="number" placeholder={0} min={0} value={proposedSupplyItem.unit_price_dollars} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "unit_price_dollars", value: e.target.value })} />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
