import Api from './APIUtils'

const api = new Api()

export function logout() {
    api.logout()
}

export function loginWithGoogle() {
    if (process.env.NODE_ENV === "production") {
        window.open(`/api/auth/google`, "_self")
    } else {
        window.open(`http://localhost:5001/auth/google`, "_self")
    }
}