import React from 'react'
import { useState } from 'react'

export default function Description({description, updateDescription}) {
    const [isEditing, setIsEditing] = useState(() => { return false })
    const [proposedDescription, setProposedDescription] = useState(() => { return description })
    const revertUpdate = () => {
        setProposedDescription(description)
        setIsEditing(false)
    }
    const saveChanges = () => {
        if (updateDescription) updateDescription(proposedDescription)
        setIsEditing(false)
    }
    return (
        <div className="notes-container">
            {!isEditing &&
            <div>
                {proposedDescription && <p>{proposedDescription}</p>}
                {/* {!proposedDescription && <p style={{cursor: "pointer"}} onClick={() => setIsEditing(true)}>Add a description</p>} */}
                {!proposedDescription && 
                <div className="notes-container-edit-description-button">
                    <span onClick={() => setIsEditing(true)}>Add description</span>
                </div>
                }
            </div>
            }
            {!isEditing && proposedDescription &&
            <div className="notes-container-edit-description-button">
                <span onClick={() => setIsEditing(true)}>Edit description</span>
            </div>
            }

            {isEditing && 
            <textarea placeholder="Enter a description" value={proposedDescription} onChange={(e) => setProposedDescription(e.target.value)} rows="8"></textarea>
            }

            {isEditing &&
            <div>
                <div className="general-list-show-more-button" onClick={saveChanges}>Save</div>
                <div className="general-list-show-more-button general-list-show-more-button-cancel" style={{marginTop: "12px"}} onClick={revertUpdate}>Cancel</div>
            </div>
            }
        </div>
    )
}
