import React from 'react'
import { MdSearch } from 'react-icons/md'

export default function GeneralPageSearchBar({searchTerm, setSearchTerm, placeholder}) {
    return (
        <div className="gadget-general-page-search-bar">
            <MdSearch/>
            <input type="text" placeholder={placeholder ? placeholder : "Search"} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </div>
    )
}
