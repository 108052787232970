import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { useNavigate  } from 'react-router-dom'
import { parseISO, format } from 'date-fns'
import Badge from '../misc/Badge'
import { getSupplyNameById } from '../../common/Helpers'

export default function PrimaryCandleCard({candle, navLocation, globalState}) {
    const navigate = useNavigate();
    const getGone = () => {
        if (!navLocation) return
        return navigate(navLocation)
    }
    const getRecipeName = () => {
        try {
            const recipes = Array.from(globalState.recipes) // todo: move these into the common helpers
            const batches = Array.from(globalState.batches)
            const batch = batches.find(b => b._id === candle.batch_id)
            const recipe = recipes.find(r => r._id === batch.recipe_id)
            return <span>{recipe.name} &middot; {recipe.fragrance_load}%</span>
        } catch (error) {
            console.log(error)
            return false
        }
    }
    const getCandleWick = () => {
        try {
            if (candle.wick_id) return getSupplyNameById(globalState.supplies, candle.wick_id)
            return candle.wick
        } catch (error) {
            return ""
        }
    }
    return (
        <div className="slim-batch-item-container" onClick={getGone}>
            <div style={{display: "flex", alignItems: "center", gap: "12px"}}>
                <div className="slim-batch-item">
                    <p style={{fontSize: "16px"}}>{candle.name}</p>
                    <span>{getRecipeName()} &middot; {getCandleWick()}</span>
                    <div style={{fontSize: "12px"}}>{format(parseISO(candle.createdAt), 'PP')}</div>
                    {candle.badges.length > 0 &&
                    <div className="slim-candle-card-badge-container">
                        {candle.badges.slice(0,5).map((badgeName) => (
                            <Badge badgeName={badgeName} key={badgeName} />
                        ))}
                        {candle.badges.length > 6 &&
                            <span>+ {candle.badges.length - 5} others</span>
                        }
                        {candle.badges.length === 6 &&
                            <span>+ 1 more</span>
                        }
                    </div>
                    }
                </div>
            </div>
            <HiArrowNarrowRight/>
        </div>
    )
}
