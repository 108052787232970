import React from 'react'
import { useEffect } from 'react'
import { useNavigate  } from 'react-router-dom'
import { loginWithGoogle } from '../common/Armstrong';
import { BsVectorPen, BsCheck2, BsClipboard, BsFire, BsPlusSlashMinus, BsPuzzle, BsRecycle, BsSnow, BsTruck, BsPieChart } from 'react-icons/bs'

const moonLiteOnPhone = require('../static/assets/moonlite-on-a-phone.png')
const moonLiteOnDesktop = require('../static/assets/moonlite-desktop-hero.png')

export default function Login({globalState, dispatch}) {
    const navigate = useNavigate();
    const attemptLogin = () => {
        loginWithGoogle()
    }
    const frequentlyAsked = [
        {
            question: "What does MoonLite do?",
            answer: "MoonLite helps busy candle makers record and organize every design, batch, candle, and collection.  It automatically creates IDs and gives you a simple place to take notes and test new designs."
        },
        {
            question: "Why should I use it?",
            answer: "MoonLite cuts down on complex spreadsheets and assorted notes everywhere for your designs and tests by making it easy to create, test, and record your candle making operations."
        },
        {
            question: "Does MoonLite calculate anything for me?",
            answer: "Yes!  After you create your candle design (called a Recipe), MoonLite will calculate how much wax, fragrance, drops of color, and wicks you need based on your batch size (which you choose)."
        },
        {
            question: "What kind of waxes and fragrance oils does MoonLite have?",
            answer: "Technically, all of them!  You get to name the wax or fragrance oil you're using."
        },
        {
            question: "Can MoonLite handle multiple waxes or fragrance oils in a single recipe?",
            answer: "Yes!  Mix and match your swanky designs as much as your heart desires to find that perfect blend."
        },
        {
            question: "Where can I use MoonLite?",
            answer: "Anywhere with internet!  MoonLite is a mobile-friendly website so you can bring your workshop on the go.  Or, open the site on your personal computer."
        },
        {
            question: "Will there be an app?",
            answer: "Right now MoonLite is only available as a mobile and desktop-friendly website, but we plan on building apps for iOS and Android someday."
        },
        {
            question: "How much does MoonLite cost?",
            answer: "MoonLite is a $6 monthly subscription that you can cancel at any time."
        },
        {
            question: "Will there be a free version of MoonLite?",
            answer: "MoonLite is only available as a monthly subscription at this time."
        }
    ]
    useEffect(() => {
        if (globalState.authenticated) return navigate('/home')
    // eslint-disable-next-line
    }, [globalState])
    return (
        <div className="login-container">
            
            
            <div className="login-hero-text-container">
                <div className="recipe-container-desktop login-hero-text-desktop">
                    <h2>Run your candle workshop from the palm of your hand</h2>
                    <p>MoonLite is a digital tool that illuminates your candle business by tracking designs, tests, notes, and more.</p>
                    <div className="login-button-google" onClick={attemptLogin}>Get Started</div>
                    <span style={{color: "grey", fontSize: "14px"}}>Monthly subscription service.  Google account required.</span>
                </div>
                <h2 className="responsive-recipe-container">Run your candle workshop from the palm of your hand</h2>
                <p className="responsive-recipe-container">MoonLite is a digital tool that illuminates your candle business by tracking designs, tests, notes, and more.</p>
                <div className="login-button-google responsive-recipe-container" onClick={attemptLogin}>Get Started</div>
                <span className="responsive-recipe-container" style={{color: "grey", fontSize: "14px"}}>Monthly subscription service.  Google account required.</span>
                <div className="login-hero-image-container responsive-recipe-container">
                    <img src={moonLiteOnPhone} alt="MoonLite on a phone" />
                </div>
            </div>
            <div className="login-hero-image-desktop-container recipe-container-desktop">
                <img src={moonLiteOnDesktop} alt="MoonLite on a desktop" />
            </div>
            
            <div className="login-sections responsive-recipe-container" style={{marginTop: "80px", gap: "80px"}}>

                <div className="login-section login-section-dark">
                    <h3>MoonLite-Powered Magic</h3>
                    <div className="login-section-mobile-testimonials">
                        <div className="login-testimonial-desktop login-testimonial-mobile">
                            <p>"MoonLite is absolutely a gift, thank you so much. It made my candle work much easier and safer. I don't get wastage now."</p>
                            <span>Usha Sivan</span>
                        </div>
                        <div className="login-testimonial-desktop login-testimonial-mobile" style={{textAlign: "right"}}>
                            <p style={{textAlign: "right"}}>"This is exactly what I have been looking for and I know it will be extremely beneficial to my business and other candle makers too!"</p>
                            <span>Pour & Penchant</span>
                        </div>
                        <div className="login-testimonial-desktop login-testimonial-mobile">
                            <p>"Thank you for such a great product. It will make life a lot easier."</p>
                            <span>Melonie Evans</span>
                        </div>
                    </div>
                </div>

                <div className="login-section">
                    <span className="login-section-badge">Feature</span>
                    <h3>One place for all your designs</h3>
                    <div className="login-section-image"><img src={require('../static/assets/ml-feature-recipe.png')} alt="MoonLite recipe" /></div>
                    <p><span style={{fontWeight: 600}}>Create recipes</span> for every candle design in your library.</p>
                    <p>Organize your growing candle design catalog by creating collections and favorites for every season and occasion.</p>
                    <button onClick={attemptLogin}>Get Started</button>
                </div>

                <div className="login-section">
                    <span className="login-section-badge">Feature</span>
                    <h3>Built-in calculator</h3>
                    <div className="login-section-image"><img src={require('../static/assets/ml-feature-calculator.png')} alt="MoonLite recipe" /></div>
                    <p><span style={{fontWeight: 600}}>Automatic candle math</span> based on your recipe.</p>
                    <p>Worry about measuring supplies, not calculating them.  MoonLite figures out how much of everything you need for your batch size based on the candle design: wax, wicks, fragrance oils, and color.</p>
                    <p>No matter how complicated your blends are.</p>
                    <button onClick={attemptLogin}>Get Started</button>
                </div>

                <div className="login-section">
                    <span className="login-section-badge">Feature</span>
                    <h3>Manage your To Do list</h3>
                    <div className="login-section-image"><img src={require('../static/assets/ml-feature-tasks.png')} alt="MoonLite recipe" /></div>
                    <p><span style={{fontWeight: 600}}>Stay organized</span> with a built-in task manager.</p>
                    <p>Never forget when a test is over or when to re-order supplies.  Run your candle business with extreme efficiency in a single place.</p>
                    <button onClick={attemptLogin}>Get Started</button>
                </div>

                <div className="login-section">
                    <span className="login-section-badge">Feature</span>
                    <h3 style={{marginBottom: "12px"}}>Run your operations from anywhere</h3>
                    <p><span style={{fontWeight: 600}}>Be flexible</span> on any device.</p>
                    <p>MoonLite runs on desktops, phones, and tablets to help you grow your business from anywhere.</p>
                    <button onClick={attemptLogin}>Get Started</button>
                </div>

                <div className="login-section">
                    <h3>Evolve your candle business</h3>
                    <div className="login-section-additionals">
                        <div className="login-section-additional-cards">
                            <div className="login-section-additional-card">
                                <BsVectorPen/>
                                <p>Record batch and candle notes</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsCheck2/>
                                <p>Manage business logistics</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsClipboard/>
                                <p>Organize and prepare inventory</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsFire/>
                                <p>Track and identify every candle</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsPlusSlashMinus/>
                                <p>Auto calculate candle supplies</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsPuzzle/>
                                <p>Test different wick sizes</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsPieChart/>
                                <p>Design complex fragrance & wax blends</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsTruck/>
                                <p>Discover inventory management patterns</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsSnow/>
                                <p>Curate unique product line collections</p>
                            </div>
                            <div className="login-section-additional-card">
                                <BsRecycle/>
                                <p>Improve candle test records</p>
                            </div>
                        </div>
                    </div>

                </div>
                
                <div className="login-button-google" onClick={attemptLogin} style={{marginTop: "0px"}}>Launch Your Workshop</div>

                <div className="login-long-form-info">
                    <h3>Pricing</h3>
                    <div>Includes unlimited recipes, batches, and candles</div>
                    <div className="login-pricing-card">
                        <h4> <span style={{fontWeight: 400}}>$</span>6</h4>
                        <div className="login-pricing-card-decorator">Per Month</div>
                        <div className="login-button-google" onClick={attemptLogin}>
                            Get started
                        </div>
                        <div className="login-pricing-card-subtitle">MoonLite requires a Google account to use</div>
                    </div>
                </div>
                <div className="login-long-form-info">
                    <h3>Frequently Asked Questions</h3>
                    {frequentlyAsked.map((faqItem) => (
                        <div className="login-faq-question" key={faqItem.question}>
                            <p>{faqItem.question}</p>
                            <p>{faqItem.answer}</p>
                        </div>
                    ))}
                    <div className="login-faq-question">
                        <p>I have a question that isn't answered here!</p>
                        <p>Send us an email at <a href="mailto:support@moonlite.app">our support line</a> and we'll answer as soon as we can!</p>
                    </div>
                </div>
                <div className="login-long-form-info">
                    <h3>Customer Support</h3>
                    <p>If you are an existing customer and need help with your account or use of MoonLite, email us  at <a href="mailto:support@moonlite.app">support@moonlite.app</a> with a description of what you need and we'll help as soon as we can!</p>
                </div>
            </div>
            
            <div className="login-sections-desktop recipe-container-desktop">
                <div className="login-section-desktop-testimonials">
                    <div className="login-testimonial-desktop">
                        <p>"MoonLite is absolutely a gift, thank you so much. It made my candle work much easier and safer. I don't get wastage now."</p>
                        <span>Usha Sivan</span>
                    </div>
                    <div className="login-testimonial-desktop" style={{textAlign: "right"}}>
                        <p>"This is exactly what I have been looking for and I know it will be extremely beneficial to my business and other candle makers too!"</p>
                        <span>Pour & Penchant</span>
                    </div>
                    <div className="login-testimonial-desktop">
                        <p>"Thank you for such a great product. It will make life a lot easier."</p>
                        <span>Melonie Evans</span>
                    </div>
                </div>
                
                <div className="login-section-desktop">
                    <div className="login-section-desktop-ft-text">
                        <div>Feature</div>
                        <h2>One place for your designs</h2>
                        <p><span style={{fontWeight: 600}}>Create recipes</span> for every candle design in your library.</p>
                        <p>Organize your growing candle design catalog by creating collections and favorites for every season and occasion.</p>
                        <button onClick={attemptLogin}>Get Started</button>
                    </div>
                    <div className="login-section-desktop-ft-img">
                        <div className="login-section-desktop-ft-img-backdrop" style={{transform: "rotate(1deg) scale(1.05)", backgroundColor: "var(--ml-color-accent)"}}></div>
                        <img src={require('../static/assets/ml-feature-recipe-d.jpg')} alt="A candle recipe" />
                    </div>
                </div>

                <div className="login-section-desktop login-section-desktop-reverse">
                    <div className="login-section-desktop-ft-text">
                        <div>Feature</div>
                        <h2>Built-in calculator</h2>
                        <p><span style={{fontWeight: 600}}>Automatic candle math</span> based on your recipe.</p>
                        <p>Worry about measuring supplies, not calculating them.  MoonLite figures out how much of everything you need for your batch size based on the candle design: wax, wicks, fragrance oils, and color.</p>
                        <p>No matter how complicated your blends are.</p>
                        <button onClick={attemptLogin}>Get Started</button>
                    </div>
                    <div className="login-section-desktop-ft-img">
                        <div className="login-section-desktop-ft-img-backdrop"></div>
                        <img src={require('../static/assets/ml-feature-supplies-d.jpg')} alt="Supply requirements for a candle recipe batch" />
                    </div>
                </div>

                <div className="login-section-desktop">
                    <div className="login-section-desktop-ft-text">
                        <div>Feature</div>
                        <h2>Manage your To Do list</h2>
                        <p><span style={{fontWeight: 600}}>Stay organized</span> with a built-in task manager.</p>
                        <p>Never forget when a test is over or when to re-order supplies.  Run your candle business with extreme efficiency in a single place.</p>
                        <button onClick={attemptLogin}>Get Started</button>
                    </div>
                    <div className="login-section-desktop-ft-img">
                        <div className="login-section-desktop-ft-img-backdrop" style={{transform: "rotate(-1deg) scale(1.04)", backgroundColor: "var(--ml-color-moonlite-black)"}}></div>
                        <img src={require('../static/assets/ml-feature-tasks-d.jpg')} alt="Set of candle making tasks" />
                    </div>
                </div>

                <div className="login-section-desktop">
                    <div>  
                        <div className="login-section-additionals">
                            <h2>Evolve your candle business operations</h2>
                            <div className="login-section-additional-cards">
                                <div className="login-section-additional-card">
                                    <BsVectorPen/>
                                    <p>Record batch and candle notes</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsCheck2/>
                                    <p>Manage business logistics</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsClipboard/>
                                    <p>Organize and prepare inventory</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsFire/>
                                    <p>Track and identify every candle</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsPlusSlashMinus/>
                                    <p>Auto calculate candle supplies</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsPuzzle/>
                                    <p>Test different wick sizes</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsPieChart/>
                                    <p>Design complex fragrance & wax blends</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsTruck/>
                                    <p>Discover inventory management patterns</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsSnow/>
                                    <p>Curate unique product line collections</p>
                                </div>
                                <div className="login-section-additional-card">
                                    <BsRecycle/>
                                    <p>Improve candle test records</p>
                                </div>
                            </div>
                        </div>
                        <div className="login-button-google" onClick={attemptLogin} style={{marginTop: "0px", width: "240px"}}>Launch Your Workshop</div>
                    </div>
                </div>

                <div style={{maxWidth: "1300px", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column", gap: "120px"}}>
                    <div className="login-long-form-info">
                        <h3>Pricing</h3>
                        <div>Includes unlimited recipes, batches, and candles</div>
                        <div className="login-pricing-card">
                            <h4> <span style={{fontWeight: 400}}>$</span>6</h4>
                            <div className="login-pricing-card-decorator">Per Month</div>
                            <div className="login-button-google" onClick={attemptLogin}>
                                Get started
                            </div>
                            <div className="login-pricing-card-subtitle">MoonLite requires a Google account to use</div>
                        </div>
                    </div>
                    <div className="login-long-form-info">
                        <h3>Frequently Asked Questions</h3>
                        {frequentlyAsked.map((faqItem) => (
                            <div className="login-faq-question" key={faqItem.question}>
                                <p>{faqItem.question}</p>
                                <p>{faqItem.answer}</p>
                            </div>
                        ))}
                        <div className="login-faq-question">
                            <p>I have a question that isn't answered here!</p>
                            <p>Send us an email at <a href="mailto:support@moonlite.app">our support line</a> and we'll answer as soon as we can!</p>
                        </div>
                    </div>
                    <div className="login-long-form-info">
                        <h3>Customer Support</h3>
                        <p>If you are an existing customer and need help with your account or use of MoonLite, email us  at <a href="mailto:support@moonlite.app">support@moonlite.app</a> with a description of what you need and we'll help as soon as we can!</p>
                        <p style={{marginTop: "12px"}}>Join the official Facebook group: <a href="https://www.facebook.com/groups/moonliteofficial" target="_blank" rel="noopener noreferrer">MoonLite Users & Friends</a> </p>
                    </div>
                </div>
            </div>

            <div className="login-footer">
                <div>MoonLite is a product of Armatage Candle Company, LLC</div>
                <div><a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></div>
                <div>© Armatage Candle Company, LLC 2023</div>
            </div>

        </div>
    )
}
