import React from 'react'
import { getBackgroundHexFromName } from '../../common/Helpers'
import { parseISO, format } from 'date-fns'

export default function CollectionCard({collection, navLocation, globalState}) {
    const getCollectionRecipeCount = () => {
        try {
            const recipes = globalState.recipes
            const recipeIds = recipes.map(r => r._id)
            let collectionRecipeIds = Array.from(collection.recipe_ids)
            for (let i = 0; i < collectionRecipeIds.length; i++) {
                if (recipeIds.indexOf(collectionRecipeIds[i]) === -1) {
                  collectionRecipeIds.splice(i, 1);
                  i--;
                }
            }
            return collectionRecipeIds.length
        } catch (error) {
            return false
        }
    }
    const getCollectionDescriptionText = () => {
        try {
            let timeStamp = format(parseISO(collection.createdAt), 'M/d/yy')
            let collectionRecipeCount = getCollectionRecipeCount()
            let text = ""
            if (collectionRecipeCount) text = `${collectionRecipeCount} recipes`
            if (collectionRecipeCount === 0) text = `No recipes yet`
            if (collectionRecipeCount === 1) text = `1 recipe`
            if (!collectionRecipeCount && collectionRecipeCount !== 0) text = false
            return (
                <>
                <span style={{marginRight: "18px"}}>{timeStamp}</span>
                <span>{text}</span>
                </>
            )
        } catch (error) {
            return "Collection"
        }
    }
    const goToCollection = () => {
        return navLocation(`/collection/${collection._id}`)
    }
    return (
        <div style={{backgroundColor: getBackgroundHexFromName(collection.color_name, true), overflow: "hidden", paddingRight: "12px"}} className="card-collection" onClick={goToCollection}>
            <h2>{collection.name}</h2>
            <div style={{fontSize: "14px", color: "rgb(71, 71, 71)", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%"}}>{collection.description}</div>
            <p>{getCollectionDescriptionText()}</p>
        </div>
    )
}
