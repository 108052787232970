import React from 'react'
import { useState, useEffect } from 'react'
import { MdCheckBoxOutlineBlank, MdCheckBox, MdCheck, MdOutlineArrowBackIosNew } from 'react-icons/md'
import { GrRadial, GrRadialSelected } from 'react-icons/gr'
import { getUniqueWaxesFromRecipeList, getUniqueFragranceOilsFromRecipeList } from '../../common/Helpers'

export default function ModalFilter({filter, setFilter, hideModal, globalState}) {
    const [waxList, setWaxList] = useState(() => { return [] })
    const [fragranceList, setFragranceList] = useState(() => { return [] })
    const [waxCount, setWaxCount] = useState(() => { return 5 })
    const [foCount, setFoCount] = useState(() => { return 5 })
    const waxNameInFilter = (waxName) => {
        try {
            if (filter.waxes.includes(waxName)) return true
            return false
        } catch (error) {
            return false
        }
    }
    const wickList = [
        {name: "Single Wick", value: 1},
        {name: "Double Wick", value: 2},
        {name: "Triple Wick", value: 3},
        {name: "Quad Wick", value: 4},
    ]
    const fragranceLoadList = [
        {name: "0% only", value: 0},
        {name: "3% & up", value: 3},
        {name: "7% & up", value: 7},
        {name: "10% & up", value: 10},
    ]
    const fragranceNameInFilter = (fragranceName) => {
        try {
            if (filter.fragrances.includes(fragranceName)) return true
            return false
        } catch (error) {
            return false
        }
    }
    const wickSizeInFilter = (wickValue) => {
        try {
            if (filter.wicks.includes(wickValue)) return true
            return false
        } catch (error) {
            return false
        }
    }
    const matchesFragranceLoadFilter = (fragranceLoadValue) => {
        try {
            if (!filter.fragranceLoad && filter.fragranceLoad !== 0) return false
            if (filter.fragranceLoad === fragranceLoadValue) return true
            return false
        } catch (error) {
            return false
        }
    }
    const additionalWickFilterPillClass = (wickValue) => {
        try {
            if (filter.wicks.includes(wickValue)) return { backgroundColor: "black", color: "white" }
            return {}
        } catch (error) {
            return {}
        }
    }
    const resetFilter = () => {
        setFilter({})
        hideModal()
    }
    const toggleWax = (waxName) => {
        let currentWaxes = []
        if (filter.waxes) currentWaxes = Array.from(filter.waxes)
        const index = currentWaxes.indexOf(waxName)
        if (index > -1) currentWaxes.splice(index, 1)
        if (index < 0) currentWaxes.push(waxName)
        setFilter({...filter, waxes: currentWaxes })
    }
    const toggleFragrance = (fragranceName) => {
        let currentFragrances = []
        if (filter.fragrances) currentFragrances = Array.from(filter.fragrances)
        const index = currentFragrances.indexOf(fragranceName)
        if (index > -1) currentFragrances.splice(index, 1)
        if (index < 0) currentFragrances.push(fragranceName)
        setFilter({...filter, fragrances: currentFragrances })
    }
    const toggleWick = (wickValue) => {
        let currentWicks = []
        if (filter.wicks) currentWicks = Array.from(filter.wicks)
        const index = currentWicks.indexOf(wickValue)
        if (index > -1) currentWicks.splice(index, 1)
        if (index < 0) currentWicks.push(wickValue)
        setFilter({...filter, wicks: currentWicks })
    }
    useEffect(() => {
        if (!globalState) return
        if (!globalState.recipes) return
        const waxes = getUniqueWaxesFromRecipeList(globalState.recipes, globalState.supplies)
        setWaxList(waxes)
        const fragrances = getUniqueFragranceOilsFromRecipeList(globalState.recipes)
        setFragranceList(fragrances)
    }, [globalState])
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <MdOutlineArrowBackIosNew onClick={hideModal} style={{fontSize: "24px", width: "60px"}} />
                            <h2>Filter</h2>
                            <span onClick={resetFilter} style={{width: "60px", cursor: "pointer"}}>Reset</span>
                        </div>

                        <div className="modal-filter-main">
                            {/* <div className="modal-filter-category">
                                <div className="modal-filter-category-header">
                                    <h3>Wax</h3>
                                    <div onClick={() => setFilter({...filter, waxes: []})}>Clear all</div>
                                </div>
                                <div className="modal-filter-category-items">
                                    {waxList.slice(0, waxCount).map((wax) => (
                                       <div className="modal-filter-category-item" key={wax} onClick={() => toggleWax(wax)}>
                                            <div className="modal-filter-category-item-name">
                                                {wax}
                                            </div>
                                            {waxNameInFilter(wax) && <MdCheckBox/>}
                                            {!waxNameInFilter(wax) && <MdCheckBoxOutlineBlank/>}
                                        </div> 
                                    ))}
                                    {waxCount < waxList.length &&
                                    <div className="modal-filter-controls">
                                        <div style={{color: "grey", textDecoration: "underline", cursor: "pointer"}} onClick={() => setWaxCount(waxList.length)}>Show all {waxList.length} waxes</div>
                                    </div>
                                    }
                                </div>
                            </div> */}

{/* 
                            <div className="modal-filter-category">
                                <div className="modal-filter-category-header">
                                    <h3>Fragrance Oil</h3>
                                    <div onClick={() => setFilter({...filter, fragrances: []})}>Clear all</div>
                                </div>
                                <div className="modal-filter-category-items">
                                    {fragranceList.slice(0, foCount).map((fragrance) => (
                                       <div className="modal-filter-category-item" key={fragrance} onClick={() => toggleFragrance(fragrance)}>
                                            <div className="modal-filter-category-item-name">
                                                {fragrance}
                                            </div>
                                            {fragranceNameInFilter(fragrance) && <MdCheckBox/>}
                                            {!fragranceNameInFilter(fragrance) && <MdCheckBoxOutlineBlank/>}
                                        </div> 
                                    ))}
                                    {foCount < fragranceList.length &&
                                    <div className="modal-filter-controls">
                                        <div style={{color: "grey", textDecoration: "underline", cursor: "pointer"}} onClick={() => setFoCount(fragranceList.length)}>Show all {fragranceList.length} fragrance oils</div>
                                    </div>
                                    }
                                </div>
                            </div> */}

                            <div className="modal-filter-category">
                                <div className="modal-filter-category-header">
                                    <h3>Wick Count</h3>
                                    <div onClick={() => setFilter({...filter, wicks: []})}>Clear all</div>
                                </div>
                                <div className="modal-filter-category-items modal-filter-category-items-pills">
                                    {wickList.map((wick) => (
                                       <div className="modal-filter-category-item" key={wick.name} onClick={() => toggleWick(wick.value)} style={additionalWickFilterPillClass(wick.value)}>
                                            <div className="modal-filter-category-item-name">
                                                {wick.name}
                                            </div>
                                            {wickSizeInFilter(wick.value) && <MdCheck/>}
                                        </div> 
                                    ))}
                                </div>
                            </div>

                            <div className="modal-filter-category">
                                <div className="modal-filter-category-header">
                                    <h3>Fragrance Load</h3>
                                    <div onClick={() => setFilter({...filter, fragranceLoad: false})}>Clear</div>
                                </div>
                                <div className="modal-filter-category-items">
                                    {fragranceLoadList.map((fragranceLoad) => (
                                       <div className="modal-filter-category-item modal-filter-category-item-pill" key={fragranceLoad.name} onClick={() => setFilter({...filter, fragranceLoad: fragranceLoad.value })}>
                                            <div className="modal-filter-category-item-name">
                                                {fragranceLoad.name}
                                            </div>
                                            {!matchesFragranceLoadFilter(fragranceLoad.value) && <GrRadial/>}
                                            {matchesFragranceLoadFilter(fragranceLoad.value) && <GrRadialSelected/>}
                                        </div> 
                                    ))}
                                </div>
                            </div>

                            <div className="general-page-create-button-create" style={{marginTop: "24px"}}>
                                <button onClick={hideModal}>Set filter</button>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
