import React from 'react'
import { useNavigate  } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'

export default function HeaderReturnToPage({title, navigationUrl}) {
    const navigate = useNavigate()
    const handleNav = () => {
        if (!navigationUrl) return navigate(-1)
        return navigate(navigationUrl)
    }
    return (
        <div className="header-return-to-page">
            <div onClick={handleNav}>
                <AiOutlineLeft/>
                {title && <h2>{title}</h2>}
                {!title && <h2>Back</h2>}
            </div>
        </div>
    )
}
