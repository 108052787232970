import React from 'react'
import { useState } from 'react'
import { MdClear } from 'react-icons/md'
import { convertCandleTypeRawToDisplay } from '../../common/Helpers'
import { HiStar } from 'react-icons/hi'
import { parseISO, format } from 'date-fns'

export default function ModalRemoveRecipesFromCollection({recipes, hideModal, saveRemovalUpdates, collection}) {
    const [selectedForRemoval, setSelectedForRemoval] = useState(() => { return [] })
    const getDateAsString = (recipe) => {
        try {
            return format(parseISO(recipe.createdAt), 'PPP')
        } catch (error) {
            return ""
        }
    }
    const generateDescription = (recipe) => {
        if (!recipe) return false
        let suffix = "candle"
        if (recipe.recipe_type === "wax_melt") suffix = false
        return<span>{recipe.wax} <span style={{textTransform: "lowercase"}}>{convertCandleTypeRawToDisplay(recipe.recipe_type)}</span> {suffix}</span>
    }
    const getRecipes = () => {
        if (!recipes) return hideModal()
        const sortedRecipes = recipes.sort((a, b) => {
            return parseISO(b.updatedAt) - parseISO(a.updatedAt)
        })
        return sortedRecipes
    }
    const toggleRemoval = (recipeId) => {
        let removalIds = Array.from(selectedForRemoval)
        if (removalIds.includes(recipeId)) {
            const index = removalIds.indexOf(recipeId)
            if (index < 0) return
            removalIds.splice(index, 1)
            setSelectedForRemoval(removalIds)
            return
        }
        removalIds.push(recipeId)
        setSelectedForRemoval(removalIds)
    }
    const removeRecipes = () => {
        if (selectedForRemoval.length === 0) return hideModal()
        let payload = {
            collection_id: collection._id,
            recipe_ids_to_remove: selectedForRemoval
        }
        saveRemovalUpdates(payload)
    }
    const getRecipeRemovalStyle = (recipeId) => {
        if (selectedForRemoval.includes(recipeId)) return { border: "2px solid #47aef4"}
        return { border: "2px solid transparent"}
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>Select recipes to remove from {collection.name}</h2>
                            <MdClear onClick={hideModal}/>
                        </div>
                        <div className="modal-edit-section">
                            <p>{selectedForRemoval.length} selected</p>
                        </div>
                        <div className="modal-edit-section">
                            <div className="general-card-list-recipes modal-collection-recipe-list-scrolls" style={{ flexDirection: "column", padding: 0 }}>
                            {getRecipes().map((recipe) => (
                                <div className="general-card general-card-recipe" key={recipe._id} onClick={() => toggleRemoval(recipe._id)} style={getRecipeRemovalStyle(recipe._id)}>
                                    <div style={{overflow: "hidden"}}>
                                        <div className="general-card-name">{recipe.name}</div>
                                        <div className="general-card-id">{recipe.visual_id} • {getDateAsString(recipe)}</div>
                                        <div className="general-card-description-small">{generateDescription(recipe)}</div>
                                    </div>
                                    {recipe.is_favorite &&
                                    <div className="general-card-favorite-symbol">
                                        <HiStar/>
                                    </div>
                                    }
                                </div>
                            ))}
                            </div>
                        </div>
                        <div className="modal-buttons modal-buttons-small modal-buttons-float-bottom">
                            <span style={{backgroundColor: "white", color: "black", border: "1px solid rgb(200, 200, 200)"}} onClick={hideModal}>Cancel</span>
                            <span onClick={removeRecipes}>Save</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
