import React from 'react'
import { parseISO, format } from 'date-fns'
import { HiStar } from 'react-icons/hi'
import { getSupplyNameById } from '../../common/Helpers'

export default function PrimaryRecipeCard({recipe, navLocation, altAction, globalState}) {
    const getDateAsString = () => {
        try {
            return format(parseISO(recipe.createdAt), 'PPP')
        } catch (error) {
            return ""
        }
    }
    const goToRecipe = () => {
        if (altAction) return altAction()
        if (!navLocation) return
        return navLocation(`/recipe/${recipe._id}`)
    }
    const getWaxName = () => {
        try {
            if (!recipe.schema || recipe.schema === 1) return recipe.wax
            const blends = recipe.wax_blend_map.blends
            if (blends.length === 1) {
                if (blends[0].supply_id) return getSupplyNameById(globalState.supplies, blends[0].supply_id)
                return blends[0].name
            }
            return `${blends.length}-wax blend`
        } catch (error) {
            return ''
        }

    }
    const getFragranceName = () => {
        try {
            if (parseFloat(recipe.fragrance_load) === 0) return "fragrance"
            if (recipe.fragrance_oil && recipe.fragrance_oil !== "false") return recipe.fragrance_oil
            const blends = recipe.fragrance_oil_blend_map.blends
            if (blends.length === 0) return "fragrance"
            if (blends.length === 1) {
                if (blends[0].supply_id) return getSupplyNameById(globalState.supplies, blends[0].supply_id)
                return blends[0].name
            }
            return `multi-fragrance`
        } catch (error) {
            console.log(error)
            return false
        }
    }
    const getWick = () => {
        try {
            if (!recipe.wick_in_recipe) return false
            if (recipe.wick_id) return <span> &middot; {getSupplyNameById(globalState.supplies, recipe.wick_id)}</span>
            return <span> &middot; {recipe.wick_name}</span>
        } catch (error) {
            return false
        }
    }
    const getFragranceOils = () => {
        try {
            let fragranceName = `${recipe.fragrance_load}%`
            if (recipe.fragrance_load === 0) fragranceName = "No"
            return <><br/><span>{fragranceName} {getFragranceName()}</span></>
        } catch (error) {
            return false
        }
    }
    const generateDescription = () => {
        if (!recipe) return false
        return<span>{getWaxName()}{getWick()}{getFragranceOils()}</span>
    }
    return (
        <div className="general-card general-card-recipe" onClick={goToRecipe}>
            <div style={{overflow: "hidden"}}>
                <div className="general-card-name">{recipe.name}</div>
                <div className="general-card-id">{recipe.visual_id} • {getDateAsString()}</div>
                <div className="general-card-description-small">{generateDescription()}</div>
            </div>
            {recipe.is_favorite &&
            <div className="general-card-favorite-symbol">
                <HiStar/>
            </div>
            }
        </div>
    )
}
