import React from 'react'
import { useReducer } from 'react'
import { MdClear } from 'react-icons/md'
import { getColorObject } from '../../common/Helpers'

export default function ModalEditCollection({sendEdits, hideModal, collection}) {
    const [updatedCollection, collectionDispatch] = useReducer((state, action) => {
        if (action.type === "update") {
            let update = {}
            update[action.prop] = action.value
            return { ...state, ...update }
        }
        return state
      }, collection)
    const saveUpdates = () => {
        if (!isValid()) return hideModal()
        let edits = {
            name: updatedCollection.name,
            color_name: updatedCollection.color_name,
            collection_id: collection._id
        }
        sendEdits(edits)
    }
    const isValid = () => {
        try {
            if (updatedCollection.name.length === 0) return false
            return true
        } catch (error) {
            return false
        }
    }
    const getCollectionColorStyle = (colorName) => {
        let style = {
            backgroundColor: getColorObject()[colorName]
        }
        if (colorName === updatedCollection.color_name) style['border'] = "2px solid #47aef4"
        return style
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>Edit Collection</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="modal-edit-section">
                            <h3>Collection Name</h3>
                            <input type="text" placeholder="What should this collection be called?" value={updatedCollection.name} onChange={(e) => collectionDispatch({type: "update", prop: "name", value: e.target.value })} maxLength={48} />
                        </div>

                        <div className="modal-edit-section">
                            <h3>Select a collection color</h3>
                            <div className="modal-grid-colors">
                                {Object.keys(getColorObject()).map((colorName, keyIndex) => (
                                    <div key={colorName} style={getCollectionColorStyle(colorName)} onClick={() => collectionDispatch({type: "update", prop: "color_name", value: colorName})}></div>
                                ))}
                            </div>
                        </div>
                        

                        <div className="modal-buttons">
                            {!isValid() && <span style={{backgroundColor: "grey"}}>Save</span>}
                            {isValid() && <span onClick={saveUpdates}>Save</span>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}