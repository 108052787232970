import React from 'react'
import { useState } from 'react'
import { BiChevronDown, BiChevronRight } from 'react-icons/bi'

export default function CollapsibleListSection({children, title, startCollapsed}) {
    const [isCollapsed, setIsCollapsed] = useState(() => {
        if (startCollapsed) return true
        return false
    })
    return (
        <div className="gadget-collapsible-list-section">
            <div className="gadget-collapsible-list-section-headings" onClick={() => setIsCollapsed(!isCollapsed)}>
                {!isCollapsed && <BiChevronDown />}
                {isCollapsed && <BiChevronRight />}
                <h4>{title}</h4>
            </div>
            {!isCollapsed && children}
        </div>
    )
}
