import React from 'react'
import { useReducer, useState } from 'react'
import { MdClear } from 'react-icons/md'
import { getColorObject, convertCandleTypeRawToDisplay } from '../../common/Helpers'
import { HiStar } from 'react-icons/hi'
import { parseISO, format } from 'date-fns'

export default function ModalNewCollection({globalState, hideModal, createCollection}) {
    const [page, setPage] = useState(() => { return 1 })
    const [errorMessage, setErrorMessage] = useState(() => { return "" })
    const initialCollection = {
        name: "",
        color_name: "default",
        description: "",
        recipe_ids: []
    }
    const [collection, collectionDispatch] = useReducer((state, action) => {
        if (action.type === "update") {
            let update = {}
            update[action.prop] = action.value
            return { ...state, ...update }
        }
        if (action.type === "toggle_recipe") {
            if (!action.value) return state
            const recipeId = action.value
            let recipeIds = Array.from(state.recipe_ids)
            // remove it if it is already here
            if (recipeIds.includes(recipeId)) {
                const index = recipeIds.indexOf(recipeId)
                if (index < 0) return state
                recipeIds.splice(index, 1)
                return { ...state, recipe_ids: recipeIds}
            }
            // assume it needs to be added
            recipeIds.push(recipeId)
            return { ...state, recipe_ids: recipeIds}

        }
        return state
    }, initialCollection)
    const attemptNavFromPageOne = () => {
        if (collection.name) {
            setErrorMessage("")
            return setPage(2)
        }
        setErrorMessage("Provide a collection name to continue")
    }
    const getCurrentRecipes = () => {
        try {
            if (!globalState.recipes) return []
            const sortedRecipes = globalState.recipes.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            return sortedRecipes
        } catch (error) {
            return []
        }
    }
    const getCollectionColorStyle = (colorName) => {
        let style = {
            backgroundColor: getColorObject()[colorName]
        }
        if (colorName === collection.color_name) style['border'] = "2px solid #47aef4"
        return style
    }
    const getRecipeSelectionStyle = (recipeId) => {
        let style = {
            border: "2px solid transparent"
        }
        if (collection.recipe_ids.includes(recipeId)) style['border'] = "2px solid #47aef4"
        return style
    }
    const getDateAsString = (recipe) => {
        try {
            return format(parseISO(recipe.createdAt), 'PPP')
        } catch (error) {
            return ""
        }
    }
    const generateDescription = (recipe) => {
        if (!recipe) return false
        let suffix = "candle"
        if (recipe.recipe_type === "wax_melt") suffix = false
        return<span>{recipe.wax} <span style={{textTransform: "lowercase"}}>{convertCandleTypeRawToDisplay(recipe.recipe_type)}</span> {suffix}</span>
    }
    const additionalRecipeListStyles = () => {
        try {
            if (getCurrentRecipes().length < 3) return { flexDirection: "column" }
            return {}
        } catch (error) {
            return {}
        }
    }
    const saveCollection = () => {
        createCollection(collection)
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>New Collection</h2>
                            <MdClear onClick={hideModal}/>
                        </div>
                        {page === 1 &&
                        <div>
                            <div className="modal-edit-section">
                                <h3>Name your collection</h3>
                                <input type="text" placeholder="Summer Scents, Development, Markets, etc..." value={collection.name} onChange={(e) => collectionDispatch({type: "update", prop: "name", value: e.target.value })} maxLength={48} />
                                {errorMessage && <div className="modal-edit-error-text">{errorMessage}</div> }
                            </div>

                            <div className="modal-edit-section">
                                <h3>Select a collection color</h3>
                                <div className="modal-grid-colors">
                                    {Object.keys(getColorObject()).map((colorName, keyIndex) => (
                                        <div key={colorName} style={getCollectionColorStyle(colorName)} onClick={() => collectionDispatch({type: "update", prop: "color_name", value: colorName})}></div>
                                    ))}
                                </div>
                            </div>

                            <div className="modal-buttons modal-buttons-small">
                                <span style={{backgroundColor: "white", color: "black", border: "1px solid rgb(200, 200, 200)"}} onClick={hideModal}>Cancel</span>
                                <span onClick={attemptNavFromPageOne}>Next</span>
                            </div>
                        </div>
                        }
                        
                        {page === 2 &&
                        <div>
                            <div className="modal-edit-section">
                                <h3>Add recipes to your collection</h3>
                                <div className="general-card-list-recipes modal-collection-recipe-list-scrolls" style={additionalRecipeListStyles()}>
                                {getCurrentRecipes().map((recipe) => (
                                    <div className="general-card general-card-recipe" key={recipe._id} style={getRecipeSelectionStyle(recipe._id)} onClick={() => collectionDispatch({type: "toggle_recipe", value: recipe._id})}>
                                        <div style={{overflow: "hidden"}}>
                                            <div className="general-card-name">{recipe.name}</div>
                                            <div className="general-card-id">{recipe.visual_id} • {getDateAsString(recipe)}</div>
                                            <div className="general-card-description-small">{generateDescription(recipe)}</div>
                                        </div>
                                        {recipe.is_favorite &&
                                        <div className="general-card-favorite-symbol">
                                            <HiStar/>
                                        </div>
                                        }
                                    </div>
                                ))}
                                </div>
                            </div>

                            <div className="modal-buttons modal-buttons-small modal-buttons-float-bottom">
                                <span style={{backgroundColor: "white", color: "black", border: "1px solid rgb(200, 200, 200)"}} onClick={() => setPage(1)}>Back</span>
                                <span onClick={saveCollection}>Create</span>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
