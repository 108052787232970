import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate  } from 'react-router-dom'
import { GiPouringPot } from 'react-icons/gi'
import { HiOutlinePlus } from 'react-icons/hi2'
import { parseISO } from 'date-fns'
import PrimaryBatchCard from './PrimaryBatchCard'
import GeneralPageSearchBar from '../gadgets/GeneralPageSearchBar'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'
import { getRecipeWaxes, standardCardSections, getCardsBySection, getFragranceOilsFromRecipe, getBatchWicks } from '../../common/Helpers'
import PreviewBatchCard from './PreviewBatchCard'
import BatchPreviewPane from './BatchPreviewPane'

export default function Batches({globalState, dispatch, fetchUserLibrary}) {
    const [query, setQuery] = useState(() => { return "" })
    const [batches, setBatches] = useState(() => { return [] })
    const [selectedPreviewBatchId, setSelectedPreviewBatchId] = useState(() => { return false })
    const navigate = useNavigate()
    const getCurrentBatches = () => {
        if (!globalState.batches) return []
        const sortedBatches = globalState.batches.sort((a, b) => {
            return parseISO(b.pour_date) - parseISO(a.pour_date)
        })
        if (!query.trim()) return sortedBatches
        return sortedBatches.filter((batch) => {
            let waxNames = getRecipeWaxes(batch.recipe[0], batch, globalState.supplies)
            let fragrances = getFragranceOilsFromRecipe(batch.recipe[0], batch)
            let wicks = getBatchWicks(batch, batch.recipe[0])
            const batchTextToSearch = `${batch.name}${batch.recipe[0].name}${waxNames}${fragrances}${batch.recipe[0].recipe_type}${wicks}${batch.notes}`
            if (batchTextToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
            return false
        })
    }
    const additionalRecipeListStyles = () => {
        try {
            if (batches.length < 3) return { flexDirection: "column" }
            return { }
        } catch (error) {
            return { }
        }
    }
    const additionalListStyles = (length) => {
        try {
            if (length < 3) return { flexDirection: "column", padding: "0" }
            return { padding: "0" }
        } catch (error) {
            return { padding: "0" }
        }
    }
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        setBatches(getCurrentBatches())
    // eslint-disable-next-line
    }, [globalState, query])
    useEffect(() => {
        dispatch({type: "nav", payload: {view: "batches"}})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (selectedPreviewBatchId) return 
        if (globalState.batches.length === 0) return
        setSelectedPreviewBatchId(globalState.batches[0]._id)
    // eslint-disable-next-line
    }, [])
    return (
        <div>
            {globalState.batches.length > 0 &&
            <div className="general-category-page-main">
                <div className="general-category-page-header">
                    <h2>Batches</h2>
                    <div className="general-category-page-create" onClick={() => navigate('/create/batch')}><HiOutlinePlus/> New Batch</div>
                </div>

                <div className="responsive-recipe-container">
                    <div className="general-category-page-utilities">
                        <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                    </div>

                    {!query &&
                    <div style={{margin: "0px 12px"}}>
                        {standardCardSections.map((batchSection) => (
                            <div key={batchSection.name}>
                                {getCardsBySection(globalState.batches, batchSection, 'pour_date').length > 0 && (
                                    <CollapsibleListSection title={batchSection.name}>
                                        <div className="general-card-list-recipes" style={additionalListStyles(getCardsBySection(globalState.batches, batchSection, 'pour_date').length)}>
                                            {getCardsBySection(globalState.batches, batchSection, 'pour_date').map((batch) => (
                                                <PrimaryBatchCard batch={batch} key={batch._id} navLocation={`/candles/batch/${batch._id}`}/>
                                            ))}
                                        </div>
                                    </CollapsibleListSection>
                                )}
                            </div>
                        ))}
                    </div>
                    }
                    
                    {query && globalState.batches.length > 0 &&
                    <div className="general-card-list-recipes" style={additionalRecipeListStyles()}>
                        {batches.map((batch) => (
                            <PrimaryBatchCard batch={batch} key={batch._id} navLocation={`/candles/batch/${batch._id}`}/>
                        ))}
                    </div>
                    }

                    <div className="general-card-list-recipes">
                        {batches.length === 0 && query &&
                        <div className="general-empty-state-container">
                            <div className="general-empty-state-description">
                                <div className="general-empty-state-icon-container">
                                    <GiPouringPot/>
                                </div>
                                <h3>No batches found!</h3>
                                <div className="general-empty-state-instructions">
                                    Use batches to create candles from a recipe for burning, testing, or selling.
                                </div>
                            </div>
                            
                            <button onClick={() => navigate('/create/batch')}>Create new batch</button>
            
                        </div>
                        }
                    </div>
                </div>

                <div className="recipe-container-desktop">
                    <div className="general-preview-list-page-container" style={{top: "148px"}}>

                        <div className="general-preview-list-list-container">
                            <div className="general-category-page-utilities">
                                <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                            </div>
                            
                            <div className="general-card-list-recipes-surrogate">
                                <div className="general-card-list-recipe-positions" style={{top: "216px", paddingRight: "12px"}}>
                                    <div className="general-card-list-recipes" style={{paddingLeft: "0px"}}>
                                        {batches.map((batch) => (
                                            <PreviewBatchCard batch={batch} key={batch._id} onSelect={setSelectedPreviewBatchId} selectedPreviewId={selectedPreviewBatchId}/>
                                        ))}
                                        {batches.length === 0 && <div className="general-category-page-create" onClick={() => navigate('/create/batch')}><HiOutlinePlus/> New Batch</div> }
                                    </div>
                                </div>
                            </div> 
                        </div>

                        <div className="general-preview-list-pane-container">
                            {selectedPreviewBatchId &&
                                <BatchPreviewPane globalState={globalState} dispatch={dispatch} fetchUserLibrary={fetchUserLibrary} batchId={selectedPreviewBatchId} setId={setSelectedPreviewBatchId} />
                            }
                            {!selectedPreviewBatchId && batches.length > 0 && <div style={{display: "flex", justifyContent: "center", marginTop: "40px", color: "grey"}}>Select a batch to view more information</div> }
                        </div>

                    </div>
                </div>
            </div>
            }

            {globalState.batches.length === 0 && globalState.recipes.length > 0 &&
            <div className="general-empty-state-container">
                
                <div className="general-empty-state-description">
                    <div className="general-empty-state-icon-container">
                        <GiPouringPot/>
                    </div>
                    <h3>You don't have any batches</h3>
                    <div className="general-empty-state-instructions">
                        Use a recipe to create a new batch of candles for burning, testing, or selling.
                    </div>
                </div>
                
                <button onClick={() => navigate('/create/batch')}>Create new batch</button>

            </div>
            }

            {globalState.batches.length === 0 && globalState.recipes.length === 0 &&
            <div className="general-empty-state-container">
                
                <div className="general-empty-state-description">
                    <div className="general-empty-state-icon-container">
                        <GiPouringPot/>
                    </div>
                    <h3>You don't have any batches</h3>
                    <div className="general-empty-state-instructions">
                        To create a batch, you need a recipe.
                    </div>
                </div>
                
                <button onClick={() => navigate('/create/recipe')}>Create new recipe</button>

            </div>
            }
        </div>
    )
}
