import React from 'react'
import { useState } from 'react'
import { MdClear } from 'react-icons/md'

export default function ModalEditBlendWeights({blendWeights, updateBlendWeights, hideModal, modalTitle}) {
    const [proposedBlendWeights, setProposedBlendWeights] = useState(() => { return blendWeights })
    const isValid = () => {
        try {
            if (getTotalAllocatedRawNumber() === 100) return true
            return false
        } catch (error) {
            return false
        }
    }
    const updateBlendWeight = (value, index) => {
        let updatedBlendWeights = Array.from(proposedBlendWeights)
        updatedBlendWeights[index]['weight'] = value
        setProposedBlendWeights(updatedBlendWeights)
    }
    const getTotalAllocatedRawNumber = () => {
        try {
            const blendsWithValidWeights = proposedBlendWeights.filter((blend) => {
                if (isNaN(parseFloat(blend.weight))) return false
                return true
            })
            const allNumbers = blendsWithValidWeights.map(a => parseFloat(a.weight))
            const sum = allNumbers.reduce((partialSum, a) => partialSum + a, 0)
            return Math.round(sum)
        } catch (error) {
            return 0
        }
    }
    const adjustWeightsEqual = () => {
        try {
            const totalBlends = blendWeights.length
            const percentage = (100 / totalBlends).toFixed(1)
            let updatedBlendWeights = Array.from(proposedBlendWeights)
            for (let i = 0; i < updatedBlendWeights.length; i++) {
                updatedBlendWeights[i].weight = percentage
            }
            setProposedBlendWeights(updatedBlendWeights)
        } catch (error) {
            return
        }
        
    }
    const saveAdjustments = () => {
        updateBlendWeights(proposedBlendWeights)
    }
    const getExtraInputStyles = (blendWeight) => {
        const badStyle = {backgroundColor: "rgba(255, 0, 0, 0.266)"}
        try {
            if (isNaN(parseFloat(blendWeight))) return badStyle
            if (parseFloat(blendWeight) < 0) return badStyle
            if (parseFloat(blendWeight) > 100) return badStyle
            return {}
        } catch (error) {
            return {backgroundColor: "rgba(255, 0, 0, 0.266)"}
        }
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>{modalTitle}</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="modal-blend-edit-inputs">
                            {proposedBlendWeights.map((blendWeight, index) => (
                                <div className="modal-blend-item-container" key={blendWeight.name + index}>
                                    <p>{blendWeight.name}</p>
                                    <div className="modal-blend-item-weight" style={getExtraInputStyles(blendWeight.weight)}>
                                        <input type="number" value={blendWeight.weight} onChange={(e) => updateBlendWeight(e.target.value, index)} max={100} min={0}/>
                                        <span>%</span>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="modal-blend-allocation-statement">{getTotalAllocatedRawNumber()}% of 100% allocated</div>
                        {(getTotalAllocatedRawNumber() > 100) && <div style={{textAlign: "center", marginTop: "16px"}}>Blend allocation cannot be larger than 100%</div> }
                        {(getTotalAllocatedRawNumber() < 0) && <div style={{textAlign: "center", marginTop: "16px"}}>Ummm... you need to allocate more than that</div> }

                        <div className="modal-blend-allocate-buttons form-blend-option-buttons">
                            <button className="modal-blend-allocate-equal-button" onClick={adjustWeightsEqual}>Blend equally</button>
                        </div>
                        
                        
                        <div className="modal-buttons">
                            {!isValid() && <span style={{backgroundColor: "grey"}}>Save</span>}
                            {isValid() && <span onClick={saveAdjustments}>Save</span>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}