import React from 'react'
import { MdClear } from 'react-icons/md'
import { Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { parseISO, format } from 'date-fns'

export default function ModalTaskDateSelector({currentDueDate, setDueDate, hideModal}) {
    const handleDateChange = (d) => {
        const formattedDate = format(d, 'yyyy-MM-dd')
        setDueDate(formattedDate)
    }
    const getCalendarFriendlyDate = () => {
        if (!currentDueDate || currentDueDate === "") return new Date()
        return parseISO(currentDueDate)
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>Select A Due Date</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="util-row util-align-center util-justify-center">
                            <Calendar date={getCalendarFriendlyDate()} onChange={handleDateChange}/>
                        </div>                  

                        <div className="modal-buttons">
                            <span onClick={hideModal}>Save</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
