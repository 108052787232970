import React from 'react'
import { useReducer, useState, useEffect } from 'react'
import { HiOutlineCalculator } from 'react-icons/hi'
import { capitalizeFirstLetter } from '../../common/Helpers'
import { MdClear } from 'react-icons/md'
import ModalContainerEstimate from './ModalContainerEstimate'
import Api from '../../common/APIUtils'
import FloatingErrorMessage from '../misc/FloatingErrorMessage'

export default function ModalAddNewInventoryItem({hideModal, specificItemToCreate}) {
    const api = new Api()
    const [proposedSupplyItem, proposedSupplyItemDispatch] = useReducer((state, action) => {
        if (action.type === "update") {
            let update = {}
            update[action.name] = action.value
            if (action.name === "type") {
                if (action.value === "container") update["units"] = "ea"
                if (action.value === "wax") update["units"] = "lb"
                if (action.value === "fragrance") update["units"] = "oz"
                if (action.value === "wick") update["units"] = "ea"
            }
            return { ...state, ...update }
        }
        return state
    },
    {
        supply_name: "",
        type: "container",
        units: "ea",
        unit_price_dollars: 0.00,
        supplier_name: "",
        supplier_website: "",
        notes: "",
    })
    const [supplyAttributes, supplyAttributesDispatch] = useReducer((state, action) => {
        if (action.type === "update") {
            let update = {}
            update[action.name] = action.value
            if (action.name === "vessel_capacity_grams") update["vessel_capacity_raw"] = action.value
            return { ...state, ...update }
        }
        return state
    },
    {
        vessel_capacity_raw: 0,
        vessel_capacity_units: "grams",
        vessel_capacity_grams: 0
    })
    const [isEditingCapacity, setIsEditingCapacity] = useState(() => { return false })
    const [isShowingErrorMessage, setIsShowingErrorMessage] = useState(() => { return false })
    const [errorMessage, setErrorMessage] = useState(() => { return "" })
    const setIsOunces = (isOunces) => {
        if (isOunces) supplyAttributesDispatch({type: "update", name: "vessel_capacity_units", value: "ounces"})
        if (!isOunces) supplyAttributesDispatch({type: "update", name: "vessel_capacity_units", value: "grams"})
    }
    const submitNewSupply = () => {
        try {
            // Validate inputs
            if (!proposedSupplyItem.supply_name || !proposedSupplyItem.supply_name) {
                setErrorMessage(`Missing a name: please provide a name for the ${proposedSupplyItem.type}`)
                setIsShowingErrorMessage(true)
                return
            }
            if (proposedSupplyItem.type === "container" && (!supplyAttributes.vessel_capacity_raw || supplyAttributes.vessel_capacity_raw < 0)) {
                setErrorMessage("Provide a vessel capacity more than 0 to continue")
                setIsShowingErrorMessage(true)
                return
            }

            // Create payload
            let payload = proposedSupplyItem
            payload['properties'] = supplyAttributes
            let supplyPricePennies = 0
            if (proposedSupplyItem.unit_price_dollars) supplyPricePennies = proposedSupplyItem.unit_price_dollars * 100
            payload['unit_price_pennies'] = supplyPricePennies

            // Submit
            api.createNewSupply(payload)
            .then((res) => {
                hideModal(true)
            })
            .catch((err) => {
                // Display error banner
                setErrorMessage("Something went wrong creating the supply... try again in a few minutes")
                setIsShowingErrorMessage(true)
                return
            })
        } catch (error) {
            return 
        }
    }
    const hasSpecificInput = () => {
        try {
            if (["wax", "fragrance", "wick"].includes(specificItemToCreate)) return true
            return false
        } catch (error) {
            return false
        }
    }
    const getCostOfGoodsSoldUnitSelector = () => {
        try {
            if (proposedSupplyItem.type === "wax") {
                return (
                    <select value={proposedSupplyItem.units} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "units", value: e.target.value })}>
                        <option value="lb">Pounds (lb)</option>
                        <option value="kg">Kilograms (kg)</option>
                    </select>
                )
            }
            if (proposedSupplyItem.type === "fragrance") {
                return (
                    <select value={proposedSupplyItem.units} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "units", value: e.target.value })}>
                        <option value="oz">Ounces (oz)</option>
                        <option value="ml">Milliliters (mL)</option>
                    </select>
                )
            }
            return <p>Each</p>
        } catch (error) {
            return <p>Each</p>
        }
    }
    useEffect(() => {
        if (!specificItemToCreate) return
        if (["wax", "fragrance", "wick"].includes(specificItemToCreate)) return proposedSupplyItemDispatch({type: "update", name: "type", value: specificItemToCreate})
    }, [specificItemToCreate])
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    {isEditingCapacity && <ModalContainerEstimate proposedCandleDispatch={supplyAttributesDispatch} setIsOunces={setIsOunces}  hideModal={() => setIsEditingCapacity(false)}/> }
                    {isShowingErrorMessage && <FloatingErrorMessage message={errorMessage} closeErrorMessage={() => setIsShowingErrorMessage(false)}/>}
                    <div className="modal-overlay" style={{maxWidth: "800px"}}>
                        
                        <div className="modal-header">
                            {!specificItemToCreate && <h2>Add new inventory item</h2>}
                            {specificItemToCreate && <h2>Add new {specificItemToCreate}</h2>}
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="modal-create-sections">
                            <div className="modal-create-section">
                                <h3>Description</h3>
                                <div className="modal-create-inputs-container">
                                    <div className="modal-create-simple-input">
                                        <h4>Category</h4>
                                        {hasSpecificInput() && <p>{capitalizeFirstLetter(specificItemToCreate)}</p> }
                                        {!hasSpecificInput() &&
                                        <select value={proposedSupplyItem.type} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "type", value: e.target.value })}>
                                            <option value="container">Container</option>
                                            <option value="wax">Wax</option>
                                            <option value="fragrance">Fragrance</option>
                                            <option value="wick">Wick</option>
                                        </select>
                                        }
                                    </div>

                                    <div className="modal-create-simple-input">
                                        <h4>Name</h4>
                                        <input type="text" placeholder={`Provide a name for this ${proposedSupplyItem.type}`} value={proposedSupplyItem.supply_name} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supply_name", value: e.target.value })} />
                                    </div>

                                    <div className="modal-create-simple-input">
                                        <h4>Notes</h4>
                                        <textarea rows="4" placeholder="Additional information..." value={proposedSupplyItem.notes} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "notes", value: e.target.value })}></textarea>
                                    </div>
                                </div>
                            </div>

                            {proposedSupplyItem.type === "container" &&
                            <div className="modal-create-section">
                                <h3>Vessel Properties</h3>
                                <div className="modal-create-inputs-container">
                                    <div className="modal-create-simple-input">
                                        <h4>Units</h4>
                                        <select value={supplyAttributes.vessel_capacity_units} onChange={(e) => supplyAttributesDispatch({type: "update", name: "vessel_capacity_units", value: e.target.value })}>
                                            <option value="grams">Grams (g)</option>
                                            <option value="ounces">Ounces (oz)</option>
                                        </select>
                                    </div>
                                    <div className="modal-create-simple-input">
                                        <h4>Vessel Capacity</h4>
                                        <div className="util-row util-align-center" style={{gap: "12px"}}>
                                            <input type="number" min={0} value={supplyAttributes.vessel_capacity_raw} onChange={(e) => supplyAttributesDispatch({type: "update", name: "vessel_capacity_raw", value: e.target.value })} />
                                            {supplyAttributes.vessel_capacity_units === "grams" && <span>grams</span>}
                                            {supplyAttributes.vessel_capacity_units === "ounces" && <span>oz</span>}
                                        </div>
                                    </div>
                                    <div className="form-blend-option-buttons form-blend-option-buttons-inverted">
                                        <button className="util-row util-align-center" onClick={() => setIsEditingCapacity(true)}><HiOutlineCalculator/>Open Calculator</button>
                                    </div>
                                </div>
                            </div>
                            }

                            <div className="modal-create-section">
                                <h3>Supplier</h3>
                                <div className="modal-create-inputs-container">
                                    <div className="modal-create-simple-input">
                                        <h4>Business Name</h4>
                                        <input type="text" placeholder="Enter your preferred supplier" value={proposedSupplyItem.supplier_name} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supplier_name", value: e.target.value })} />
                                    </div>
                                    <div className="modal-create-simple-input">
                                        <h4>Website</h4>
                                        <input type="text" placeholder="https://" value={proposedSupplyItem.supplier_website} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "supplier_website", value: e.target.value })}/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="modal-create-section">
                                <h3>Cost of Goods Sold</h3>
                                <div className="modal-create-inputs-container">
                                    <div className="modal-create-simple-input">
                                        <h4>Units</h4>
                                        {getCostOfGoodsSoldUnitSelector()}
                                        {/* <p>{proposedSupplyItem.units}</p> */}
                                    </div>
                                    <div className="modal-create-simple-input">
                                        <h4>Estimated Unit Price</h4>
                                        <div className="util-row util-align-center" style={{gap: "4px"}}>
                                            <span>$</span>
                                            <input type="number" min={0} value={proposedSupplyItem.unit_price_dollars} onChange={(e) => proposedSupplyItemDispatch({type: "update", name: "unit_price_dollars", value: e.target.value })}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="general-page-create-button-create">
                                <button onClick={submitNewSupply}>Add {proposedSupplyItem.type} to supply library</button>
                            </div>

                            {/* <div className="modal-edit-section">
                                <h3>Supplier</h3>
                                New Item
                            </div>

                            <div className="modal-edit-section">
                                <h3>Pricing</h3>
                                New Item
                            </div>

                            <div className="modal-edit-section">
                                <h3>Back Office</h3>
                                SKU
                            </div> */}
                        </div>


                        
                    </div>
                </div>
            </div>
        </div>
    )
}
