import React from 'react'
import Api from '../../common/APIUtils'
import { formatDistanceToNowStrict, formatDistanceToNow, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import HeaderReturnToPage from '../misc/HeaderReturnToPage'
import { gramsToOuncesString, getWaxSchema2, getFOSchema2, getSupplyById, getSupplyNameById } from '../../common/Helpers'
import { useNavigate  } from 'react-router-dom'
import AddBadge from '../forms/AddBadge'
import Notes from '../misc/Notes'
import Badge from '../misc/Badge'

export default function Candle({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const navigate = useNavigate();
    const [candle, setCandle] = useState(() => { return false })
    const [page, setPage] = useState(() => { return "candle" })
    const [batch, setBatch] = useState(() => { return false })
    const [recipe, setRecipe] = useState(() => { return false })
    const [recipeContainer, setRecipeContainer] = useState(() => { return false })
    const { candleId } = useParams()
    const getCandleDescription = () => {
        if (!candle || !batch || !recipe) return false
        let waxType = `${recipe.wax} wax`
        if (recipe.schema === 2) {
            const blends = recipe.wax_blend_map.blends
            if (blends.length === 1) {
                if (blends[0].supply_id) waxType = `${getSupplyNameById(globalState.supplies, blends[0].supply_id)} wax`
                if (!blends[0].supply_id) waxType = `${blends[0].name} wax`
            }
            if (blends.length > 1) waxType = `${blends.length}-wax blend`
        }
        let candleType = "candle"
        let wick = candle.wick
        if (candle.wick_id) wick = getSupplyNameById(globalState.supplies, candle.wick_id)
        if (recipe.recipe_type === "wax_melt") {
            candleType = "melt"
            wick = false
        }
        let description = `${waxType} ${candleType}`
        if (wick) description = `${description} • ${wick}`
        return description
    }
    const getContainer = () => {
        try {
            if (recipe.recipe_type === "wax_melt") return "Clamshell Mold"
            if (recipe.container_name) return recipe.container_name
            return "Mold"
        } catch (error) {
            return false
        }
    }
    const updateBatch = () => {
        try {
            const allBatches = Array.from(globalState.batches)
            const selectedBatch = allBatches.find(b => b._id === candle.batch_id)
            setBatch(selectedBatch)
        } catch (error) {
            return false
        }
    }
    const updateCandleBadgesAndCloseAddForm = (newBadges) => {
        setCandle({ ...candle, badges: newBadges })
        setPage("candle")
        api.updateCandle({candle_id: candleId, badges: newBadges})
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            console.error(err)
        })
    }
    const updateRecipe = () => {
        try {
            const allRecipes = Array.from(globalState.recipes)
            const selectedRecipe = allRecipes.find(r => r._id)
            setRecipe(selectedRecipe)
        } catch (error) {
            return false
        }
    }
    const getAdditionalWickNameTextIfDouble = () => {
        try {
            if (recipe.wick_count === 1) return false
            return <span style={{color: "grey", letterSpacing: "2px"}}> x{recipe.wick_count}</span>
        } catch (error) {
            return false
        }
    }
    const getAdditionalSubtitleIfDouble = () => {
        try {
            if (recipe.wick_count === 1) return false
            return  <span style={{letterSpacing: "2px"}}> x{recipe.wick_count}</span>
        } catch (error) {
            return false
        }
    }
    const updateCandleWithNotes = (notes) => {
        api.updateCandle({candle_id: candleId, notes: notes})
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            console.error(err)
        })
    }
    useEffect(() => {
        if (!recipe) return setRecipeContainer(false)
        if (!recipe.container_id) return setRecipeContainer(false)
        if (!globalState.supplies.length === 0) return setRecipeContainer(false)
        const containerSupply = getSupplyById(recipe.container_id, globalState.supplies)
        if (!containerSupply) return setRecipeContainer(false)
        setRecipeContainer(containerSupply)
    // eslint-disable-next-line
    }, [recipe])
    useEffect(() => {
        if (!candleId) return
        const allCandles = Array.from(globalState.candles)
        const selectedCandle = allCandles.find(r => r._id.toString() === candleId)
        if (!selectedCandle) return navigate('/candles/candles')
        setCandle(selectedCandle)
    // eslint-disable-next-line
    }, [candleId, globalState])
    useEffect(() => {
        if (!candle._id) return
        updateBatch()
    // eslint-disable-next-line
    }, [candle])
    useEffect(() => {
        if (!batch._id) return
        updateRecipe()
    // eslint-disable-next-line
    }, [batch])
    useEffect(() => {
        if (!batch) return
        if (!batch.recipe) return
        if (!batch.recipe[0]) return
        setRecipe(batch.recipe[0])
    // eslint-disable-next-line
    }, [batch])
    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "candles"}})
    // eslint-disable-next-line
    }, [])
    return (
        <div className="general-page-create general-page-recipe">
            {page === "badge" && <AddBadge hideModal={updateCandleBadgesAndCloseAddForm} candle={candle} description={getCandleDescription()}/> }

            {page === "candle" &&
            <>
            {candle &&
            <>
            <div className="util-row util-space-between util-align-start" style={{maxWidth: "100%", boxSizing: "border-box", marginRight: "12px"}}>
                <HeaderReturnToPage title="All candles" navigationUrl={'/candles/candles'}/>
                <span className="general-top-right-text-small">{formatDistanceToNowStrict(parseISO(candle.createdAt))} ago</span>
            </div>
            <div className="general-page-create-section general-page-create-section-borderless general-page-create-section-heading">                                
                <h1>{candle.name}</h1> 
                <p>{getCandleDescription()} {getAdditionalSubtitleIfDouble()}</p>                
            </div>
            </>
            }
            {candle &&
            <div className="general-page-create-section general-page-create-section-borderless">
                <div className="candle-page-badges-section">
                    {candle.badges.map((badgeName) => (
                        <Badge key={badgeName} badgeName={badgeName}/>
                    ))}
                    <div className="badge-container">
                        <div className="badge">
                            <span style={{minWidth: "12px", fontSize: "12px", backgroundColor: "var(--ml-color-dark)", color: "white", fontWeight: 500}} onClick={() => setPage("badge")}>Add badge</span>
                        </div>
                    </div>
                </div>
            </div>
            }
            {candle &&
            <div className="general-page-create-section general-page-create-section-summary-card">
                <h2>Notes</h2>
                <Notes notes={candle.notes} updateNote={updateCandleWithNotes} baseId={candle._id} />
            </div>
            }

            {candle && batch && recipe &&
            <div className="general-page-create-section general-page-create-section-summary-card">
                <div className="util-row util-space-between util-align-start">
                    <h2>Details</h2>
                </div>
                <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                    <div className="recipe-ingredient-row">
                        {(!recipe.schema || recipe.schema === 1) &&
                        <>
                            <div className="recipe-ingredient-name">Wax</div>
                            <div className="recipe-ingredient-value">{recipe.wax}</div>
                        </>
                        }

                        {recipe.schema === 2 &&
                        <>
                        <div className="recipe-ingredient-name">
                            Wax
                            {recipe.wax_blend_map.blends.length > 1 && <span>Blend</span>}
                        </div>
                        {getWaxSchema2(recipe, globalState.supplies)}
                        </>
                        }
                    </div>

                    {(!recipe.schema || recipe.schema === 1 || !recipe.fragrance_oil_blend_map) && parseFloat(recipe.fragrance_load) > 0 &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Fragrance Oil</div>
                            <div className="recipe-ingredient-value">{recipe.fragrance_oil}</div>
                        </div>
                    }

                    {recipe.schema === 2 && recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0 &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">
                                Fragrance Oil
                                {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>Blend</span>}
                            </div>
                            {getFOSchema2(recipe, globalState.supplies)}
                        </div>
                    }

                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Fragrance Load
                            <span>Effective</span>
                        </div>
                        <div className="recipe-ingredient-value">{parseFloat(candle.weight_fragrance_grams / candle.weight_wax_grams * 100).toFixed(1)}%</div>
                    </div>

                    {candle.wick &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            <div>
                                Wick
                                {recipe.wick_count > 1 &&
                                <div className="util-row util-align-center">
                                    <div className="recipe-badge-decoration" style={{margin: 0, marginTop: "4px"}}>Multi Wick</div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="recipe-ingredient-value">{candle.wick}{getAdditionalWickNameTextIfDouble()}</div>
                    </div>
                    }

                    {candle.wick_id &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            <div>
                                Wick
                                {recipe.wick_count > 1 &&
                                <div className="util-row util-align-center">
                                    <div className="recipe-badge-decoration" style={{margin: 0, marginTop: "4px"}}>Multi Wick</div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="recipe-ingredient-value general-supply-clickable" onClick={() => navigate(`/candles/inventory/supplies/${candle.wick_id}`)}>{getSupplyById(candle.wick_id, globalState.supplies).supply_name}{getAdditionalWickNameTextIfDouble()}</div>
                    </div>
                    }

                    {!recipeContainer &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Container</div>
                        <div className="recipe-ingredient-value">{getContainer()}</div>
                    </div>
                    </>
                    }

                    {recipeContainer &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Container</div>
                        <div className="recipe-ingredient-value general-supply-clickable" onClick={() => navigate(`/candles/inventory/supplies/${recipeContainer._id}`)}>{recipeContainer.supply_name}</div>
                    </div>
                    </>
                    }


                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Candle Weight
                            <span>Estimate</span>
                        </div>
                        <div className="recipe-ingredient-value">{candle.weight_fragrance_grams + candle.weight_wax_grams} g {`(${gramsToOuncesString(candle.weight_fragrance_grams + candle.weight_wax_grams)})`}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Wax Weight
                            <span>Estimate</span>
                        </div>
                        <div className="recipe-ingredient-value">{candle.weight_wax_grams} g {`(${gramsToOuncesString(candle.weight_wax_grams)})`}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Fragrance Weight
                            <span>Estimate</span>
                        </div>
                        <div className="recipe-ingredient-value">{candle.weight_fragrance_grams} g {`(${gramsToOuncesString(candle.weight_fragrance_grams)})`}</div>
                    </div>
                </div>
            </div>
            }

            {batch && recipe &&
            <div className="general-page-create-section general-page-create-section-summary-card">
                <div className="util-row util-space-between util-align-start">
                    <h2>Batch</h2>
                    <span className="general-top-right-text-small">{recipe.visual_id}-{batch.visual_id}</span>
                </div>
                <div className="general-page-create-section-summary-card-inner" onClick={() => navigate(`/candles/batch/${batch._id}`)}>
                    <div>
                        <div className="general-page-create-section-summary-card-title">{batch.name}</div>
                        <span className="general-page-create-section-summary-card-subtitle">Poured {formatDistanceToNow(parseISO(batch.pour_date))} ago</span>
                    </div>
                    <HiArrowNarrowRight/>
                </div>
                
            </div>
            }
            {recipe &&
            <div className="general-page-create-section general-page-create-section-summary-card" style={{marginBottom: "40px"}}>
                <div className="util-row util-space-between util-align-start">
                    <h2>Recipe</h2>
                    <span className="general-top-right-text-small">{recipe.visual_id}</span>
                </div>
                <div className="general-page-create-section-summary-card-inner" onClick={() => navigate(`/recipe/${recipe._id}`)}>
                    <div>
                        <div className="general-page-create-section-summary-card-title">{recipe.name}</div>
                        <span className="general-page-create-section-summary-card-subtitle">Created {formatDistanceToNow(parseISO(recipe.createdAt))} ago</span>
                    </div>
                    <HiArrowNarrowRight/>
                </div>
            </div>
            }
            </>
            }
        </div>
    )
}
