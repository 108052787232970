import React from 'react'
import { useReducer, useRef, useState, useEffect } from 'react'
import { useNavigate, useSearchParams   } from 'react-router-dom'
import HeaderReturnToPage from '../misc/HeaderReturnToPage'
import { GrRadial, GrRadialSelected, GrFormSubtract, GrFormAdd, GrLock, GrClose } from 'react-icons/gr'
import { HiOutlineCalculator } from 'react-icons/hi'
import { TiWarning } from 'react-icons/ti'
import { MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md'
import Api from '../../common/APIUtils'
import { pageOptions, getTotalWeightsOfBlend, sortByProperty } from '../../common/Helpers'
import LoadingSymbol from '../misc/LoadingSymbol'
import ModalEditBlendWeights from '../modals/ModalEditBlendWeights'
import ModalContainerEstimate from '../modals/ModalContainerEstimate'

export default function CreateRecipe({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const initialState = {
        wax: false,
        fragrance_load: 7.0,
        recipe_type: "container_supply",
        container_id: "",
        color_in_recipe: false,
        color_name: "",
        color_drops_per_pound: 5,
        color_units: "Drops",
        container_name: "",
        fragrance_oil: "",
        vessel_capacity_grams: "",
        name: "",
        wick_count: 1,
        use_inventory_wick: true,
        collection_id: false,
        wick_name: "",
        wick_id: "",
        wick_in_recipe: false // false means the wick will be chosen when creating a batch, true limits to a single wick
    }
    const [noFragranceOil, setNoFragranceOil] = useState(() => { return false })
    const [inventoryContainers, setInventoryContainers] = useState(() => { return [] })
    const [inventoryWax, setInventoryWax] = useState(() => { return [] })
    const [inventoryFragrance, setInventoryFragrance] = useState(() => { return [] })
    const [inventoryWicks, setInventoryWicks] = useState(() => { return [] })
    const [addToCollection, setAddToCollection] = useState(() => { return false })
    const [totalWaxCount, setTotalWaxCount] = useState(() => { return 1 })
    const [totalFOCount, setTotalFOCount] = useState(() => { return 1 })
    const [waxBlendItems, setWaxBlendItems] = useState(() => { return [{name: "", weight: 100}] })
    const [foBlendItems, setFoBlendItems] = useState(() => { return [{name: "", weight: 100}] })
    const [colorItems, setColorItems] = useState(() => { return [{color_name: "", color_drops_per_pound: "5"}] })
    const [isLoading, setIsLoading] = useState(() => { return false })
    const [isEditingCapacity, setIsEditingCapacity] = useState(() => { return false })
    const [isEditingWaxBlendWeights, setIsEditingWaxBlendWeights] = useState(() => { return false })
    const [isEditingFOBlendWeights, setIsEditingFOBlendWeights] = useState(() => { return false })
    const [isOunces, setIsOunces] = useState(() => { return false })
    const [basedOnRecipe, setBasedOnRecipe] = useState(() => { return false })
    const [proposedCandle, proposedCandleDispatch] = useReducer((state, action) => {
        if (action.type === "inspire") return { ...state, ...action.value }
        if (action.type === "update") {
            let update = {}
            update[action.name] = action.value
            if (action.name === "fragrance_load") {
                if (parseFloat(action.value) <= 0) setNoFragranceOil(true)
                if (parseFloat(action.value) > 0) setNoFragranceOil(false)
            }
            return { ...state, ...update }
        }
        return state
    }, initialState)
    const waxSelRef = useRef(null)
    const fragranceOilRef = useRef(null)
    const vesselCapacityRef = useRef(null)
    const fragranceLoadRef = useRef(null)
    const candleTypeRef = useRef(null)
    const vesselNameRef = useRef(null)
    const vesselIdRef = useRef(null)
    const wickNameRef = useRef(null)
    const colorOptionsRef = useRef(null)
    const generateTypeOptions = () => {
        const types = pageOptions.types
        return (<>
            {types.map((type) => (
                <div className="general-page-create-option-single" key={type.id} onClick={() => proposedCandleDispatch({ type: "update", name: 'recipe_type', value: type.id })}>
                    {proposedCandle.recipe_type === type.id && <GrRadialSelected/>}
                    {proposedCandle.recipe_type !== type.id &&<GrRadial/>}
                    {type.display}
                </div>
            ))}
            </>
        )
    }
    const getAdditionalUnitsClass = (unitIsOunces) => {
        if (unitIsOunces && isOunces) return "general-page-item-unit-selection-selected"
        if (!unitIsOunces && !isOunces) return "general-page-item-unit-selection-selected"
        return ""
    }
    const getAdditionalWickCountClass = (count) => {
        if (count === proposedCandle.wick_count) return "general-page-item-unit-selection-selected"
        return ""
    }
    const getPill = (proposedCandleAttribute, isOptional) => {
        let pillClass = "general-page-create-pill-form-validation"
        if (isOptional && proposedCandle[proposedCandleAttribute]) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Optional</div>
        if (isOptional) return <div className={`${pillClass} general-page-create-pill-form-validation-optional`}>Optional</div>
        if (!proposedCandle[proposedCandleAttribute]) return <div className={pillClass}>Required</div>
        if (proposedCandle[proposedCandleAttribute]) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
    }
    const blendOptionsAreValid = (blendedItemsArray, totalBlendCount) => {
        try {
            const blendChoicesWithPopulatedNamesOrValues = blendedItemsArray.filter((blendItem) => {
                if (blendItem.name && !blendItem.supply_id) return true
                if (blendItem.name && blendItem.supply_id) return true
                if (blendItem.weight) return true
                return false
            })
            // No options
            if (blendChoicesWithPopulatedNamesOrValues.length === 0) return false
            // only one option
            if (blendChoicesWithPopulatedNamesOrValues.length === 1 && totalBlendCount > 1) return false
            // only one option
            if (blendChoicesWithPopulatedNamesOrValues.length === 1) return true
            // multiple options
            if (getTotalWeightsOfBlend(blendedItemsArray) !== 100) return false
            const choicesMissingNames = blendChoicesWithPopulatedNamesOrValues.find(a => !a.name)
            if (choicesMissingNames) return false
            return true
        } catch (error) {
            return false
        }
    }
    const waxOptionsAreValid = () => {
        return blendOptionsAreValid(waxBlendItems, totalWaxCount)
    }
    const fragranceOptionsAreValid = () => {
        if (noFragranceOil) return true
        return blendOptionsAreValid(foBlendItems, totalFOCount)
    }
    const wickOptionIsValid = () => {
        try {
            if (!proposedCandle.wick_in_recipe) return true
            if (proposedCandle.use_inventory_wick && proposedCandle.wick_id) return true
            if (!proposedCandle.use_inventory_wick && proposedCandle.wick_name) return true
            return false
        } catch (error) {
            return false
        }
    }
    const getWickPill = () => {
        let pillClass = "general-page-create-pill-form-validation"
        try {
            if (wickOptionIsValid()) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
            return <div className={pillClass}>Required</div>
        } catch (error) {
            return <div className={pillClass}>Required</div>
        } 
    }
    const getWaxPill = () => {
        let pillClass = "general-page-create-pill-form-validation"
        try {
            if (waxOptionsAreValid()) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
            return <div className={pillClass}>Required</div>
        } catch (error) {
            return <div className={pillClass}>Required</div>
        } 
    }
    const getFragrancePill = () => {
        let pillClass = "general-page-create-pill-form-validation"
        try {
            if (fragranceOptionsAreValid()) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
            return <div className={pillClass}>Required</div>
        } catch (error) {
            return <div className={pillClass}>Required</div>
        }
    }
    const getSymbolForBlendWeightUpdate = () => {
        if (getTotalWeightsOfBlend(waxBlendItems) === 100) return false
        return <TiWarning style={{color: "red", fontSize: "16px"}} />
    }
    const getSymbolForFoBlendWeightUpdate = () => {
        if (getTotalWeightsOfBlend(foBlendItems) === 100) return false
        return <TiWarning style={{color: "red", fontSize: "16px"}} />
    }
    const getWaxValueFromIndex = (index) => {
        if (waxBlendItems[index]) return waxBlendItems[index]
        return { name: "", weight: 0 }
    }
    const getFragranceValueFromIndex = (index) => {
        if (foBlendItems[index]) return foBlendItems[index]
        return { name: "", weight: 0 }
    }
    const removeWaxBlendByIndex = (index) => {
        let waxes = Array.from(waxBlendItems)
        waxes.splice(index, 1)
        setTotalWaxCount(totalWaxCount - 1)
        setWaxBlendItems(waxes)
    }
    const removeFoBlendByIndex = (index) => {
        let fragrances = Array.from(foBlendItems)
        fragrances.splice(index, 1)
        setTotalFOCount(totalFOCount - 1)
        setFoBlendItems(fragrances)
    }
    const addWaxToBlend = () => {
        setTotalWaxCount(totalWaxCount + 1)
    }
    const addFragranceToBlend = () => {
        setTotalFOCount(totalFOCount + 1)
    }
    const addColorToBlend = () => {
        let currentColors = Array.from(colorItems)
        currentColors.push({color_name: "", color_drops_per_pound: 5})
        setColorItems(currentColors)
    }
    const getColorItemNameByIndex = (index) => {
        try {
            return colorItems[index].color_name
        } catch (error) {
            return ""
        }
    }
    const updateColorItemNameByIndex = (value, index) => {
        try {
            let updatedColorItems = Array.from(colorItems)
            updatedColorItems[index].color_name = value
            setColorItems(updatedColorItems)
        } catch (error) {
            return
        }
    }
    const getColorItemValueByIndex = (index) => {
        try {
            return colorItems[index].color_drops_per_pound 
        } catch (error) {
            return 0
        }
    }
    const updateColorItemValueByIndex = (value, index) => {
        try {
            let updatedColorItems = Array.from(colorItems)
            updatedColorItems[index].color_drops_per_pound = value
            setColorItems(updatedColorItems)
        } catch (error) {
            return
        }
    }
    const removeColorItemByIndex = (index) => {
        try {
            let updatedColorItems = Array.from(colorItems)
            updatedColorItems.splice(index, 1)
            setColorItems(updatedColorItems)
        } catch (error) {
            console.log(error)
            return
        }
    }
    const updateBlendWeights = () => {
        setIsEditingWaxBlendWeights(true)
    }
    const updateFoBlendWeights = () => {
        setIsEditingFOBlendWeights(true)
    }
    const blendWeightsNeedUpdating = () => {
        if (totalWaxCount === 1) return false
        return true
    }
    const updateWaxValueBasedOnIndex = (value, index) => {
        let existingWaxes = Array.from(waxBlendItems)
        const existingWax = existingWaxes[index]
        if (existingWax) existingWaxes[index] = { name: value, weight: existingWax['weight'] }
        if (!existingWax) existingWaxes.push({name: value, weight: 0})
        setWaxBlendItems(existingWaxes)
    }
    const updateWaxValueWithInventoryWaxBasedOnIndex = (waxId, index) => {
        try {
            let existingWaxes = Array.from(waxBlendItems)
            const selectedWax = inventoryWax.find(w => w._id === waxId)
            const existingWax = existingWaxes[index]
            if (existingWax && !selectedWax) existingWaxes[index] = { name: "", supply_id: false, weight: existingWax['weight'] }
            if (existingWax && selectedWax) existingWaxes[index] = { name: selectedWax.supply_name, supply_id: selectedWax._id, weight: existingWax['weight'] }
            if (!existingWax && selectedWax) existingWaxes.push({name: selectedWax.supply_name, supply_id: selectedWax._id, weight: 0})
            if (!existingWax && !selectedWax) existingWaxes.push({name: "", supply_id: false, weight: 0})
            setWaxBlendItems(existingWaxes)
        } catch (error) {
            return
        }
    }
    const updateFragranceValueWithInventoryWaxBasedOnIndex = (fragranceId, index) => {
        try {
            let existingFragrances = Array.from(foBlendItems)
            const selectedFragrance = inventoryFragrance.find(f => f._id === fragranceId)
            const existingFragrance = existingFragrances[index]
            if (existingFragrance && !selectedFragrance) existingFragrances[index] = { name: "", supply_id: false, weight: existingFragrance['weight'] }
            if (existingFragrance && selectedFragrance) existingFragrances[index] = { name: selectedFragrance.supply_name, supply_id: selectedFragrance._id, weight: existingFragrance['weight'] }
            if (!existingFragrance && selectedFragrance) existingFragrances.push({name: selectedFragrance.supply_name, supply_id: selectedFragrance._id, weight: 0})
            if (!existingFragrance && !selectedFragrance) existingFragrances.push({name: "", supply_id: false, weight: 0})
            setFoBlendItems(existingFragrances)
        } catch (error) {
            return
        }
    }
    const updateFragranceValueBasedOnIndex = (value, index) => {
        let existingFragrances = Array.from(foBlendItems)
        const existingFragrance = existingFragrances[index]
        if (existingFragrance) existingFragrances[index] = { name: value, weight: existingFragrance['weight'] }
        if (!existingFragrance) existingFragrances.push({name: value, weight: 0})
        setFoBlendItems(existingFragrances)
    }
    const getColorPill = () => {
        let pillClass = "general-page-create-pill-form-validation"
        if (colorOptionsAreValid()) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
        return <div className={pillClass}>Required</div>
    }
    const adjustFragranceLoad = (adjustment) => {
        if (adjustment > 0 && parseFloat(proposedCandle.fragrance_load) === 12) return
        if (adjustment < 0 && parseFloat(proposedCandle.fragrance_load) === 0) return
        const proposedLoad = parseFloat(proposedCandle.fragrance_load) + parseFloat(adjustment)
        proposedCandleDispatch({type: "update", name: "fragrance_load", value: proposedLoad.toFixed(1)})
    }
    const validateVesselCapacity = () => {
        if (proposedCandle.recipe_type === "container_supply") return true // no validation needed - auto pass
        if (proposedCandle.recipe_type === "wax_melt") return true // no validation needed - auto pass
        if (isNaN(parseInt(proposedCandle.vessel_capacity_grams))) return false
        if (parseInt(proposedCandle.vessel_capacity_grams) > 0) return true
        return false
    }
    const colorOptionsAreValid = () => {
        try {
            if (!proposedCandle.color_in_recipe) return true
            const colorItemsWithNameAndValue = colorItems.filter((colorItem) => {
                if (!colorItem.color_name) return false
                if (!colorItem.color_drops_per_pound || colorItem.color_drops_per_pound === "0") return false
                return true
            })
            if (colorItemsWithNameAndValue.length > 0) return true
            return false
        } catch (error) {
            return false
        }

    }
    const validateAndSubmit = () => {
        // Validate
        if (!waxOptionsAreValid()) return waxSelRef.current.scrollIntoView()
        if (!fragranceOptionsAreValid()) return fragranceOilRef.current.scrollIntoView()
        if (!proposedCandle.fragrance_load && parseFloat(proposedCandle.fragrance_load) !== 0) return fragranceLoadRef.current.scrollIntoView()
        if (!proposedCandle.recipe_type) return candleTypeRef.current.scrollIntoView()
        if (proposedCandle.recipe_type === "container" && !proposedCandle.container_name) return vesselNameRef.current.scrollIntoView()
        if (proposedCandle.recipe_type === "container_supply" && !proposedCandle.container_id) return vesselIdRef.current.scrollIntoView()
        if (!wickOptionIsValid()) return waxSelRef.current.scrollIntoView()
        if (!validateVesselCapacity()) return vesselCapacityRef.current.scrollIntoView()
        if (!colorOptionsAreValid()) return colorOptionsRef.current.scrollIntoView()

        // Convert capacity to grams if needed
        let payload = proposedCandle
        if (isOunces && proposedCandle.recipe_type === "container") {
            // const asGrams = Math.round(proposedCandle.vessel_capacity_grams * 28.3495, 0) // Rounding errors are being annoying
            const asGrams = proposedCandle.vessel_capacity_grams * 28.3495
            payload = { ...payload, vessel_capacity_grams: asGrams }
        }
        if (proposedCandle.color_drops_per_pound && proposedCandle.color_in_recipe) {
            payload['color_drops_per_pound'] = parseInt(proposedCandle.color_drops_per_pound)
        }
        payload['wax_blends'] = waxBlendItems
        if (!noFragranceOil) payload['fragrance_oil_blends'] = foBlendItems
        if (proposedCandle.color_in_recipe) payload['color_options'] = colorItems
        if (!addToCollection) payload['collection_id'] = false
        localStorage.setItem("mlLastFragLoad", proposedCandle.fragrance_load)
        // Submit
        api.createNewRecipe(payload)
        .then((res) => {
            fetchUserLibrary()
            return navigate(`/recipe/${res.data._id}`)
        })
        .catch((err) => {
            return navigate('/candles/recipes')
        })
    }
    const fetchRecipeAndInitializeForm = (recipeId) => {
        setIsLoading(true)
        api.getRecipeById(recipeId)
        .then((res) => {
            if (!res.data._id) return setIsLoading(false)
            let inspoRecipe = res.data
            // Upgrade old
            if (inspoRecipe.schema === 1 || !inspoRecipe.schema) {
                setWaxBlendItems([{name: inspoRecipe.wax, weight: 100}])
            }

            // Extract blend value from the map
            if (inspoRecipe.schema === 2) {
                setWaxBlendItems(inspoRecipe.wax_blend_map.blends)
                setTotalWaxCount(inspoRecipe.wax_blend_map.blends.length)
            }

            if (parseFloat(inspoRecipe.fragrance_load) === 0) {
                setNoFragranceOil(true)
            }

            if (!inspoRecipe.fragrance_oil_blend_map) {
                setFoBlendItems([{ name: inspoRecipe.fragrance_oil, weight: 100 }])
            }
            if (inspoRecipe.fragrance_oil_blend_map) {
                setFoBlendItems(inspoRecipe.fragrance_oil_blend_map.blends)
                setTotalFOCount(inspoRecipe.fragrance_oil_blend_map.blends.length)
            }

            if (inspoRecipe.container_id) {
                inspoRecipe["recipe_type"] = "container_supply"
            }

            if (inspoRecipe.color_map) {
                setColorItems(inspoRecipe.color_map.colors)
            }

            setBasedOnRecipe(`Based on recipe: ${res.data.name}`)
            proposedCandleDispatch({type: "inspire", value: inspoRecipe})
            setIsLoading(false)
        })
        .catch((err) => {
            setIsLoading(false)
        })
    }
    const updateAvailableSupplies = () => {
        try {
            if (!globalState) return false
            if (!globalState.supplies) return false
            const baseSupplies = Array.from(globalState.supplies)
            let supplyCopy = baseSupplies.filter((s) => s.status === "active")
            const onlyContainers = supplyCopy.filter(s => s.type === "container")
            const sortedContainers = sortByProperty(onlyContainers, "supply_name")
            setInventoryContainers(sortedContainers)
            if (onlyContainers.length === 0) proposedCandleDispatch({ type: "update", name: 'recipe_type', value: "container" })
            const onlyWax = supplyCopy.filter(s => s.type === "wax")
            const sortedWax = sortByProperty(onlyWax, "supply_name")
            setInventoryWax(sortedWax)
            const onlyFO = supplyCopy.filter(s => s.type === "fragrance")
            const sortedFO = sortByProperty(onlyFO, "supply_name")
            setInventoryFragrance(sortedFO)
            const onlyWicks = supplyCopy.filter(s => s.type === "wick")
            const sortedWicks = sortByProperty(onlyWicks, "supply_name")
            setInventoryWicks(sortedWicks)
            if (onlyWicks.length === 0) proposedCandleDispatch({ type: "update", name: "use_inventory_wick", value: false })
        } catch (error) {
            console.log(error)
            return
        }
    }
    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "recipes"}})
        if (localStorage.getItem("mlLastFragLoad") !== null) proposedCandleDispatch({type: "update", name: "fragrance_load", value: parseFloat(localStorage.getItem("mlLastFragLoad"))})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if(searchParams.get("based_on")) fetchRecipeAndInitializeForm(searchParams.get("based_on"))
    // eslint-disable-next-line
    }, [searchParams])
    useEffect(() => {
        if (noFragranceOil && parseFloat(proposedCandle.fragrance_load) !== 0) return proposedCandleDispatch({type: "update", name: "fragrance_load", value: 0})
        if (!noFragranceOil && parseFloat(proposedCandle.fragrance_load) === 0) return proposedCandleDispatch({type: "update", name: "fragrance_load", value: 7.0})
    // eslint-disable-next-line
    }, [noFragranceOil])
    useEffect(() => {
        if (!globalState) return
        if (!globalState.supplies) return
        updateAvailableSupplies()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        fetchUserLibrary()
        updateAvailableSupplies()
    // eslint-disable-next-line
    }, [])
    return (
        <div className="general-page-create">

            {isEditingWaxBlendWeights && <ModalEditBlendWeights blendWeights={waxBlendItems} hideModal={() => setIsEditingWaxBlendWeights(false)} modalTitle="Update wax blend" updateBlendWeights={() => setIsEditingWaxBlendWeights(false)} />}
            {isEditingFOBlendWeights && <ModalEditBlendWeights blendWeights={foBlendItems} hideModal={() => setIsEditingFOBlendWeights(false)} modalTitle="Update fragrance blend" updateBlendWeights={() => setIsEditingFOBlendWeights(false)} />}
            {isEditingCapacity && <ModalContainerEstimate proposedCandleDispatch={proposedCandleDispatch} setIsOunces={setIsOunces}  hideModal={() => setIsEditingCapacity(false)}/> }

            <div className="general-page-create-section">
                <HeaderReturnToPage title="Back" navigationUrl={false}/>                
                <h1>New Recipe</h1>
                <p>Recipes contain the core attributes of a candle design.</p>
                {basedOnRecipe && <div style={{marginLeft: "12px", color: "grey", fontSize: "12px"}}>{basedOnRecipe}</div>}
            </div>

            {!isLoading && <>
            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Recipe Name</h2>
                    <div>{getPill('name', true)}</div>
                </div>
                <div className="general-page-create-input-text">
                    <input type="text" placeholder="What should this recipe be called?" value={proposedCandle.name} onChange={(e) => proposedCandleDispatch({type: "update", name: "name", value: e.target.value})} maxLength={48} />
                </div>
            </div>

            <div className="general-page-create-section" ref={waxSelRef}>
                <div className="general-page-create-section-header">
                    <h2>Wax Blend Design</h2>
                    <div>{getWaxPill()}</div>
                </div>
                <p>Select a base wax or blend multiple waxes. {totalWaxCount > 1 && <span style={{color: "grey", marginTop: "14px", marginLeft: "12px"}}>Waxes set to 0% will be ignored</span>}</p>
                <div className="general-page-create-options-single general-page-create-option-single-weighted-blends">
                    
                    {[...Array(totalWaxCount)].map((e, index) => (
                        <div className="general-page-create-input-text general-page-create-option-single-weighted-blend" key={index} style={{marginTop: "24px", marginBottom: "24px"}}>
                            {totalWaxCount > 1 && <span>{getWaxValueFromIndex(index).weight}%</span>}
                            <WaxSelectionInput value={getWaxValueFromIndex(index).name} index={index} updateWaxValueBasedOnIndex={updateWaxValueBasedOnIndex} inventoryWax={inventoryWax} updateWaxValueWithInventoryWaxBasedOnIndex={updateWaxValueWithInventoryWaxBasedOnIndex}/>
                            {index === 0 && totalWaxCount > 1 && <GrLock/>}
                            {index > 0 && totalWaxCount > 1 && <GrClose className="form-blend-option-remove" onClick={() => removeWaxBlendByIndex(index)}/>}
                        </div>
                    ))}

                    {totalWaxCount > 1 && getTotalWeightsOfBlend(waxBlendItems) !== 100 && 
                    <div style={{marginLeft: "12px", marginTop: "8px", fontStyle: "italic"}}>
                        Wax blend needs to total 100%
                    </div>
                    }
                    <div className="form-blend-option-buttons">
                        <button onClick={addWaxToBlend}>Add another wax to the blend</button>
                        {blendWeightsNeedUpdating() && <button onClick={updateBlendWeights}>{getSymbolForBlendWeightUpdate()}Update blend weights</button>}
                    </div>
                    
                </div>
            </div>

            <div className="general-page-create-section" ref={fragranceLoadRef}>
                <div className="general-page-create-section-header">
                    <h2>Select Fragrance Load</h2>
                </div>
                <div className="general-page-create-options-single">
                    <input autoComplete="off" type='range' step="0.1" min="0" max="12" list='size' value={proposedCandle.fragrance_load} onChange={(e) => proposedCandleDispatch({type: "update", name: "fragrance_load", value: e.target.value})}/>
                    <datalist id="size">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option value="7"></option>
                        <option value="8"></option>
                        <option value="9"></option>
                        <option value="10"></option>
                        <option value="11"></option>
                    </datalist>
                </div>
                <div className="general-page-create-options-single">
                    <div className="util-row util-align-center" style={{gap: "14px"}}>
                        <span className="general-page-create-button-adjust" onClick={() => adjustFragranceLoad(-0.1)}><GrFormSubtract/></span>
                        <div className="general-page-create-option-single">{proposedCandle.fragrance_load}%</div>
                        <span className="general-page-create-button-adjust" onClick={() => adjustFragranceLoad(0.1)}><GrFormAdd/></span>
                    </div>
                </div>
                <div className="general-page-create-options-single">
                    <div className="general-page-create-option-single" onClick={() => setNoFragranceOil(!noFragranceOil)}>
                        {noFragranceOil && <MdCheckBox/>}
                        {!noFragranceOil &&<MdCheckBoxOutlineBlank/>}
                        No fragrance oil
                    </div>
                </div>
            </div>

            <div className="general-page-create-section" ref={fragranceOilRef}>
                <div className="general-page-create-section-header">
                    <h2>Fragrance Design</h2>
                    {!noFragranceOil && <div>{getFragrancePill()}</div>}
                </div>
                {!noFragranceOil &&
                <>
                <p>Choose a fragrance or blend multiples. {totalFOCount > 1 && <span style={{color: "grey", marginTop: "14px", marginLeft: "12px"}}>Fragrances set to 0% will be ignored</span>}</p>
                <div className="general-page-create-options-single general-page-create-option-single-weighted-blends">
                    
                    {[...Array(totalFOCount)].map((e, index) => (
                        <div className="general-page-create-input-text general-page-create-option-single-weighted-blend" key={index} style={{marginTop: "24px", marginBottom: "24px"}}>
                            {totalFOCount > 1 && <span>{getFragranceValueFromIndex(index).weight}%</span>}

                            <FragranceSelectionInput value={getFragranceValueFromIndex(index).name} index={index} updateFragranceValueBasedOnIndex={updateFragranceValueBasedOnIndex} inventoryFragrance={inventoryFragrance} updateFragranceValueWithInventoryWaxBasedOnIndex={updateFragranceValueWithInventoryWaxBasedOnIndex}/>

                            {index === 0 && totalFOCount > 1 && <GrLock/>}
                            {index > 0 && totalFOCount > 1 && <GrClose className="form-blend-option-remove" onClick={() => removeFoBlendByIndex(index)}/>}
                        </div>
                    ))}

                    {totalFOCount > 1 && getTotalWeightsOfBlend(foBlendItems) !== 100 && 
                    <div style={{marginLeft: "12px", marginTop: "8px", fontStyle: "italic"}}>
                        Fragrance blend needs to total 100%
                    </div>
                    }
                    <div className="form-blend-option-buttons">
                        <button onClick={addFragranceToBlend}>Add fragrance</button>
                        {totalFOCount > 1 && <button onClick={updateFoBlendWeights}>{getSymbolForFoBlendWeightUpdate()}Update blend weights</button>}
                    </div>
                    
                </div>
                </>
                }
                {noFragranceOil && <>
                    <p>Set the fragrance load higher than 0% to select fragrance oils</p>
                    <div className="general-page-create-input-text general-page-create-option-single-weighted-blend">
                        <input placeholder="Enter a fragrance oil name" type="text" maxLength={48} readOnly disabled={true}/>
                    </div>
                </>
                
            }
            </div>

            {inventoryContainers.length > 0 &&
            <div className="general-page-create-section" ref={candleTypeRef}>
                <div className="general-page-create-section-header">
                    <h2>Select Vessel</h2>
                    <div>{getPill('recipe_type')}</div>
                </div>
                <div className="general-page-create-options-single">
                    {generateTypeOptions()}
                </div>
            </div>
            }

            {proposedCandle.recipe_type === "container_supply" &&
            <div className="general-page-create-section" ref={vesselIdRef}>
                <div className="general-page-create-section-header">
                    <h2>Container:</h2>
                    <div>{getPill('container_id')}</div>
                </div>
                <div className="general-page-create-input-text">
                    <select value={proposedCandle.container_id} onChange={(e) => proposedCandleDispatch({type: "update", name: "container_id", value: e.target.value })}>
                        <option value="">-- Select a container --</option>
                        {inventoryContainers.map((inventoryContainer) => (
                            <option key={inventoryContainer._id} value={inventoryContainer._id}>{inventoryContainer.supply_name}</option>
                        ))}
                    </select>
                </div>
            </div>
            }

            {proposedCandle.recipe_type === "container" &&
            <div className="general-page-create-section" ref={vesselNameRef}>
                <div className="general-page-create-section-header">
                    <h2>Vessel Name</h2>
                    <div>{getPill('container_name')}</div>
                </div>
                <div className="general-page-create-input-text">
                    <input type="text" placeholder="What do you want to call this vessel?" value={proposedCandle.container_name} onChange={(e) => proposedCandleDispatch({type: "update", name: "container_name", value: e.target.value})} />
                </div>
            </div>
            }
            
            {proposedCandle.recipe_type === "container" &&
            <div className="general-page-create-section" ref={vesselCapacityRef}>
                <div className="general-page-create-section-header">
                    {!proposedCandle.recipe_type && <h2>Capacity</h2>}
                    {proposedCandle.recipe_type === "container" && <h2>Vessel Capacity</h2>}
                    {proposedCandle.recipe_type === "containerless" && <h2>Mold Capacity</h2>}
                    <div>{getPill('vessel_capacity_grams')}</div>
                </div>
                <p>Indicate the <a href="https://armatagecandlecompany.com/blog/candle-math-equations/" rel="noreferrer" target="_blank">total weight</a> of the candle.  Use grams for higher accuracy.</p>
                <div className="general-page-create-input-text">
                    <input type="number" placeholder="How much can this container hold?" value={proposedCandle.vessel_capacity_grams} onChange={(e) => proposedCandleDispatch({type: "update", name: "vessel_capacity_grams", value: e.target.value})} />
                </div>
                <div className="form-blend-option-buttons form-blend-option-buttons-inverted">
                    <button className="util-row util-align-center" onClick={() => setIsEditingCapacity(true)}><HiOutlineCalculator/>Open Calculator</button>
                </div>
                <div className="general-page-item-unit-selection">
                    <div className="general-page-create-option-label">Select Units</div>
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <span onClick={() => setIsOunces(false)} className={getAdditionalUnitsClass(false)}>grams</span>
                        <span onClick={() => setIsOunces(true)} className={getAdditionalUnitsClass(true)}>ounces</span> 
                    </div>
                </div>
            </div>
            }

            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Include Wick in Recipe</h2>
                    <div>{getPill('wick_in_recipe', true)}</div>
                </div>
                <p>Check this if you do not need to test different wicks</p>
                <div className="general-page-create-options-single">
                    <div className="general-page-create-option-single" onClick={() => proposedCandleDispatch({ type: "update", name: 'wick_in_recipe', value: !proposedCandle.wick_in_recipe })}>
                        {proposedCandle.wick_in_recipe && <MdCheckBox/>}
                        {!proposedCandle.wick_in_recipe &&<MdCheckBoxOutlineBlank/>}
                        Include Wick
                    </div>
                </div>
            </div>

            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Wick Count</h2>
                </div>
                <p>How many wicks per candle?</p>
                <div className="general-page-item-unit-selection">
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <span onClick={() => proposedCandleDispatch({type: "update", name: "wick_count", value: 1})} className={getAdditionalWickCountClass(1)}>Single</span>
                        <span onClick={() => proposedCandleDispatch({type: "update", name: "wick_count", value: 2})} className={getAdditionalWickCountClass(2)}>Double</span> 
                        <span onClick={() => proposedCandleDispatch({type: "update", name: "wick_count", value: 3})} className={getAdditionalWickCountClass(3)}>Triple</span> 
                        <span onClick={() => proposedCandleDispatch({type: "update", name: "wick_count", value: 4})} className={getAdditionalWickCountClass(4)}>Quad</span> 
                    </div>
                </div>
            </div>
            

            {proposedCandle.wick_in_recipe &&
            <div className="general-page-create-section" ref={wickNameRef}>
                <div className="general-page-create-section-header">
                    <h2>Select Wick</h2>
                </div>

                <div className="general-page-create-options-single">
                    {inventoryWicks.length > 0 &&
                    <div className="general-page-create-option-single" onClick={() => proposedCandleDispatch({ type: "update", name: 'use_inventory_wick', value: true })}>
                        {proposedCandle.use_inventory_wick && <GrRadialSelected/>}
                        {!proposedCandle.use_inventory_wick &&<GrRadial/>}
                        Select from inventory
                    </div>
                    }
                    <div className="general-page-create-option-single" onClick={() => proposedCandleDispatch({ type: "update", name: 'use_inventory_wick', value: false })}>
                        {!proposedCandle.use_inventory_wick && <GrRadialSelected/>}
                        {proposedCandle.use_inventory_wick &&<GrRadial/>}
                        New wick
                    </div>
                </div>                
            </div>
            }

            {proposedCandle.wick_in_recipe &&
            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Wick</h2>
                    <div>{getWickPill()}</div>
                </div>

                {!proposedCandle.use_inventory_wick &&
                <div className="general-page-create-input-text">
                    <input type="text" placeholder="Which wick are you using?" value={proposedCandle.wick_name} onChange={(e) => proposedCandleDispatch({type: "update", name: "wick_name", value: e.target.value})} />
                </div>
                }

                {proposedCandle.use_inventory_wick &&
                <div className="general-page-create-input-text">
                    <select value={proposedCandle.wick_id} onChange={(e) => proposedCandleDispatch({type: "update", name: "wick_id", value: e.target.value })}>
                        <option value="">-- Select a wick --</option>
                        {inventoryWicks.map((inventoryWick) => (
                            <option key={inventoryWick._id} value={inventoryWick._id}>{inventoryWick.supply_name}</option>
                        ))}
                    </select>
                </div>
                }
                
            </div>
            }

            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Color Options</h2>
                </div>
                <div className="general-page-create-options-single">
                    <div className="general-page-create-option-single" onClick={() => proposedCandleDispatch({ type: "update", name: 'color_in_recipe', value: false })}>
                        {!proposedCandle.color_in_recipe && <GrRadialSelected/>}
                        {proposedCandle.color_in_recipe &&<GrRadial/>}
                        No color
                    </div>
                    <div className="general-page-create-option-single" onClick={() => proposedCandleDispatch({ type: "update", name: 'color_in_recipe', value: true })}>
                        {proposedCandle.color_in_recipe && <GrRadialSelected/>}
                        {!proposedCandle.color_in_recipe && <GrRadial/>}
                        Add color
                    </div>
                </div>
            </div>

            {proposedCandle.color_in_recipe &&
            <div className="general-page-create-section" ref={colorOptionsRef}>
                <div className="general-page-create-section-header">
                    <h2>Color Design</h2>
                    <div>{getColorPill()}</div>
                </div>
                <p>Add one or more colors. <span style={{color: "grey", marginTop: "14px", marginLeft: "12px"}}>Colors set to 0 will be ignored</span></p>
                <div className="general-page-create-options-single general-page-create-option-single-weighted-blends">
                    <div className="general-page-create-option-small-headings" style={{alignItems: "flex-end"}}>
                        <span>Name</span>
                        <select className="general-page-create-select-units-small" value={proposedCandle.color_units} onChange={(e) => proposedCandleDispatch( { type: "update", name: "color_units", value: e.target.value })}>
                            <option value="Drops">Drops per pound</option>
                            <option value="Ounces">Ounces per pound</option>
                            <option value="Grams">Grams per pound</option>
                        </select>
                    </div>
                    {colorItems.map((color, index) => (
                        <div className="general-page-create-input-text general-page-create-option-single-weighted-blend" key={index}>
                            <input placeholder="Enter a color name" type="text" maxLength={48} value={getColorItemNameByIndex(index)} onChange={(e) => updateColorItemNameByIndex(e.target.value, index)}/>
                            <input className="general-page-create-option-small-input" type="number" min="0" value={getColorItemValueByIndex(index)} onChange={(e) => updateColorItemValueByIndex(e.target.value, index)} />
                            {index === 0 && colorItems.length > 1 && <GrLock/>}
                            {index > 0 && colorItems.length > 1 && <GrClose className="form-blend-option-remove" onClick={() => removeColorItemByIndex(index)}/>}
                        </div>
                    ))}
                    <div className="form-blend-option-buttons">
                        <button onClick={addColorToBlend}>Add color</button>
                    </div>
                    
                </div>
            </div>
            }

            {globalState.collections.length > 0 &&
            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Collection</h2>
                    <div>{getPill('collection_id', true)}</div>
                </div>
                <p>Add this recipe to an existing collection</p>
                <div className="general-page-create-options-single">
                    <div className="general-page-create-option-single" onClick={() => setAddToCollection(false)}>
                        {!addToCollection && <GrRadialSelected/>}
                        {addToCollection &&<GrRadial/>}
                        No collection
                    </div>
                    <div className="general-page-create-option-single" onClick={() => setAddToCollection(true)}>
                        {addToCollection && <GrRadialSelected/>}
                        {!addToCollection && <GrRadial/>}
                        Add to collection
                    </div>
                </div>
                {addToCollection && 
                <div className="modal-filter-category-items" style={{padding: "0px 12px", boxSizing: "border-box"}}>
                    {globalState.collections.map((collection) => (
                        <div className="modal-filter-category-item" key={collection._id} onClick={() => proposedCandleDispatch({ type: "update", name: 'collection_id', value: collection._id })}>
                            <div className="modal-filter-category-item-name">
                                {collection.name}
                            </div>
                            {collection._id === proposedCandle.collection_id && <MdCheckBox/>}
                            {collection._id !== proposedCandle.collection_id && <MdCheckBoxOutlineBlank/>}
                        </div> 
                    ))}
                </div>
                }
            </div>
            }


            <div className="general-page-create-section general-page-create-section-borderless">
                <div className="general-page-create-button-create">
                    <button onClick={validateAndSubmit}>Create recipe</button>
                </div>
                
            </div>
            </>}

            {isLoading && <div style={{display: "flex", justifyContent: "center"}}><LoadingSymbol/></div>}

        </div>
    )
}

function WaxSelectionInput({value, index, updateWaxValueBasedOnIndex, updateWaxValueWithInventoryWaxBasedOnIndex, inventoryWax, }) {
    const [selectedInventoryWaxId, setSelectedInventoryWaxId] = useState(() => { return false })
    const [createNewWax, setCreateNewWax] = useState(() => { return false })
    useEffect(() => {
        updateWaxValueWithInventoryWaxBasedOnIndex(selectedInventoryWaxId, index)
    // eslint-disable-next-line
    }, [selectedInventoryWaxId])
    return (
        <div className="util-row" style={{width: "100%"}}>
            {inventoryWax.length === 0 &&
                <input placeholder="Enter a wax name" type="text" value={value} onChange={(e) => updateWaxValueBasedOnIndex(e.target.value, index)} maxLength={48}/>
            }
            {inventoryWax.length > 0 && !createNewWax &&
            <div style={{width: "100%"}}>
                <div className="general-page-create-input-text" style={{width: "100%", marginLeft: "0"}}>
                    <select value={selectedInventoryWaxId} onChange={(e) => setSelectedInventoryWaxId(e.target.value)}>
                        <option value="">-- Select a wax --</option>
                        {inventoryWax.map((inventoryWaxSupply) => (
                            <option key={inventoryWaxSupply._id} value={inventoryWaxSupply._id}>{inventoryWaxSupply.supply_name} {inventoryWaxSupply.supplier_name && `(${inventoryWaxSupply.supplier_name})`} </option>
                        ))}
                    </select>
                </div>
                <div onClick={() => setCreateNewWax(true)} className="general-page-create-input-toggle-button-text">
                    <span>Create new wax</span>
                </div>
            </div>
            }
            {createNewWax &&
            <div style={{width: "100%"}}>
                <input placeholder="Enter a wax name" type="text" value={value} onChange={(e) => updateWaxValueBasedOnIndex(e.target.value, index)} maxLength={48}/>
                <div onClick={() => setCreateNewWax(false)} className="general-page-create-input-toggle-button-text">
                    <span>Use existing wax</span>
                </div>
            </div>
            }
        </div>
        
    )
}

function FragranceSelectionInput({value, index, updateFragranceValueBasedOnIndex, updateFragranceValueWithInventoryWaxBasedOnIndex, inventoryFragrance, }) {
    const [selectedInventoryFragranceId, setSelectedInventoryFragranceId] = useState(() => { return false })
    const [createNewFragrance, setCreateNewFragrance] = useState(() => { return false })
    useEffect(() => {
        updateFragranceValueWithInventoryWaxBasedOnIndex(selectedInventoryFragranceId, index)
    // eslint-disable-next-line
    }, [selectedInventoryFragranceId])
    return (
        <div className="util-row" style={{width: "100%"}}>
            {inventoryFragrance.length === 0 &&
                <input placeholder="Enter a wax name" type="text" value={value} onChange={(e) => updateFragranceValueBasedOnIndex(e.target.value, index)} maxLength={48}/>
            }
            {inventoryFragrance.length > 0 && !createNewFragrance &&
            <div style={{width: "100%"}}>
                <div className="general-page-create-input-text" style={{width: "100%", marginLeft: "0"}}>
                    <select value={selectedInventoryFragranceId} onChange={(e) => setSelectedInventoryFragranceId(e.target.value)}>
                        <option value="">-- Select a fragrance --</option>
                        {inventoryFragrance.map((inventoryFragranceSupply) => (
                            <option key={inventoryFragranceSupply._id} value={inventoryFragranceSupply._id}>{inventoryFragranceSupply.supply_name} {inventoryFragranceSupply.supplier_name && `(${inventoryFragranceSupply.supplier_name})`} </option>
                        ))}
                    </select>
                </div>
                <div onClick={() => setCreateNewFragrance(true)} className="general-page-create-input-toggle-button-text">
                    <span>Create new fragrance</span>
                </div>
            </div>
            }
            {createNewFragrance &&
            <div style={{width: "100%"}}>
                <input placeholder="Enter a wax name" type="text" value={value} onChange={(e) => updateFragranceValueBasedOnIndex(e.target.value, index)} maxLength={48}/>
                <div onClick={() => setCreateNewFragrance(false)} className="general-page-create-input-toggle-button-text">
                    <span>Use existing fragrance</span>
                </div>
            </div>
            }
        </div>
        
    )
}