import React from 'react'
import { useEffect, useState } from 'react'
import { getSupplyObjectById } from '../../common/Helpers'

export default function InventoryItemNotes({globalState, supplyIds}) {
    const [supplyNotes, setSupplyNotes] = useState(() => { return false })
    const updateSupplyNotes = () => {
        try {
            const inventorySupplies = globalState.supplies
            if (!inventorySupplies) return setSupplyNotes(false)
            let suppliesWithNotes = []
            for (let i = 0; i < supplyIds.length; i++) {
                const supply = getSupplyObjectById(inventorySupplies, supplyIds[i])
                if (!supply) continue
                if (supply.notes) suppliesWithNotes.push(supply)
            }
            if (suppliesWithNotes.length > 0) setSupplyNotes(suppliesWithNotes)
        } catch (error) {
            return setSupplyNotes(false)
        }
    }
    useEffect(() => {
        if (!globalState) return
        if (!supplyIds) return
        if (supplyIds.length === 0) return
        updateSupplyNotes()
    }, [globalState, supplyIds])
    return (
        <div>
            {supplyNotes &&
            <div className="inventory-item-notes">
                <h3>Supply Notes</h3>
                <div className="card-grid">
                    {supplyNotes.map((supply) => (
                        <div className="card" key={supply._id}>
                            <h2>{supply.supply_name}</h2>
                            <p>{supply.notes}</p>
                        </div>
                    ))}
                </div>
            </div>
            }
        </div>
    )
}