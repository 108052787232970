import axios from "axios";

if (process.env.NODE_ENV === "production") {
  var BASE_URL = "/api"
} else {
  BASE_URL = "http://localhost:5001"
}

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
  }

  init = () => {

    let headers = {
      Accept: "application/json",
    };

    this.client = axios.create({
      baseURL: BASE_URL,
      timeout: 31000,
      headers: headers,
      withCredentials: true // allows cookies
    });

    return this.client;
  };

  /*
  AUTHENTICATION
  */

  // Check if logged in
  isAuthenticated = () => {
    return this.init().get("/auth");
  }

  // Log user out
  logout = () => {
    return this.init().post("/logout");
  }


  purchaseSubscription = () => {
    return this.init().post("/payments/create-checkout-session", {})
  }

  /*
  LIBRARY MANAGEMENT
  */

  fetchUserLibrary = () => {
    return this.init().get("/user/library")
  }

  getRecipeById = (recipeId) => {
    return this.init().get(`/candles/recipe/${recipeId}`)
  }

  createNewRecipe = (payload) => {
    return this.init().post("/candles/recipe", payload)
  }

  getPlanManagementUrl = () => {
    return this.init().get("/payments/get-management-session")
  }

  updateRecipe = (payload) => {
    return this.init().patch(`/candles/recipe/${payload.recipe_id}`, payload)
  }

  archiveRecipe = (payload) => {
    return this.init().patch(`/candles/archive/recipe`, payload)
  }

  restoreRecipe = (payload) => {
    return this.init().patch(`/candles/restore/recipe`, payload)
  }

  updateBatch = (payload) => {
    return this.init().patch(`/candles/batch/${payload.batch_id}`, payload)
  }

  createBatchException = (payload) => {
    return this.init().post(`/candles/batch-exceptions/${payload._id}`, payload)
  }

  updateCollection = (payload) => {
    return this.init().patch(`/candles/collection/${payload.collection_id}`, payload)
  }

  updateCandle = (payload) => {
    return this.init().patch(`/candles/candle/${payload.candle_id}`, payload)
  }

  deleteRecipe = (recipeId) => {
    return this.init().delete(`/candles/recipe/${recipeId}`)
  }

  deleteBatch = (batchId) => {
    return this.init().delete(`/candles/batch/${batchId}`)
  }

  deleteCollection = (collectionId) => {
    return this.init().delete(`/candles/collection/${collectionId}`)
  }

  createNewBatch = (payload) => {
    return this.init().post("/candles/batch", payload)
  }

  createNewCollection = (payload) => {
    return this.init().post("/candles/collections", payload)
  }

  createTask = (payload) => {
    return this.init().post("/tasks/create", payload)
  }

  updateTask = (payload) => {
    return this.init().patch(`/tasks/update/${payload.task_id}`, payload)
  }

  deleteTask = (taskId) => {
    return this.init().delete(`/tasks/${taskId}`)
  }

  createNewSupply = (payload) => {
    return this.init().post("/supplies/create", payload)
  }

  updateSupplyItem = (payload, supplyItemId) => {
    return this.init().patch(`/supplies/update/${supplyItemId}`, payload)
  }

}