import React from 'react'
import { getBadgeIcon, getBadgeColorFromName } from '../../common/Helpers'

export default function Badge({badgeName}) {
    return (
        <div className="badge-container">
            <div className="badge">
                <span style={{backgroundColor: getBadgeColorFromName(badgeName)}}>{getBadgeIcon(badgeName)}</span>
            </div>
        </div>
    )
}
