import React from 'react'
import { MdClear } from 'react-icons/md'
import { useEffect, useState } from 'react'

export default function ModalContainerEstimate({ hideModal, proposedCandleDispatch, setIsOunces }) {
    // proposedCandleDispatch({type: "update", name: "vessel_capacity_grams", value: e.target.value})} setIsOunces
    const [page, setPage] = useState(() => { return 1 })
    const [emptyWeight, setEmptyWeight] = useState(() => { return false })
    const [filledWeight, setFilledWeight] = useState(() => { return false })
    const [capacityGrams, setCapacityGrams] = useState(() => { return false })
    const [isGrams, setIsGrams] = useState(() => { return true })
    const selectUnits = (gramsSelected) => {
        setIsGrams(gramsSelected)
        setPage(2)
    }
    const saveEstimateAndCloseModal = () => {
        if (!capacityGrams) return hideModal()
        if (parseFloat(capacityGrams) <= 0) return hideModal()
        if (isGrams) {
            setIsOunces(false)
            proposedCandleDispatch({type: "update", name: "vessel_capacity_grams", value: capacityGrams})
        } else {
            setIsOunces(true)
            let capacityOunces = parseFloat(capacityGrams / 28.3495).toFixed(2)
            proposedCandleDispatch({type: "update", name: "vessel_capacity_grams", value: capacityOunces})
        }
        hideModal()
    }
    const getCapacityString = () => {
        try {
            if (!capacityGrams) return "Calculating..."
            const capacityOunces = parseFloat(capacityGrams / 28.3495).toFixed(2)
            if (isGrams) return `${parseFloat(capacityGrams).toFixed(0)} g (${capacityOunces} oz)`
            return `${capacityOunces} oz (${parseFloat(capacityGrams).toFixed(0)} g)`
        } catch (error) {
            return "Calculating..."
        }
    }
    const getUnitName = () => {
        if (isGrams) return "grams"
        return "ounces"
    }
    const updateCapacity = () => {
        try {
            const waterWeight = filledWeight - emptyWeight
            if (waterWeight <= 0) return setCapacityGrams(false)
            const totalWeight = waterWeight * 0.857
            if (isGrams) return setCapacityGrams(parseFloat(totalWeight).toFixed(0))
            // the number actually represents ounces - convert to grams
            const asGrams = parseFloat(totalWeight * 28.3495).toFixed(0)
            setCapacityGrams(asGrams)
        } catch (error) {
            return setCapacityGrams(false)
        }
    }
    useEffect(() => {
        updateCapacity()
    // eslint-disable-next-line
    }, [emptyWeight, filledWeight])
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>Vessel Capacity Calculator</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        {page === 1 &&
                        <div className="modal-edit-section-instructions">
                            <div className="modal-edit-section">
                                <h3>Units</h3>
                                <p>This calculator uses the Water Method to estimate capacity.  Select the units you would like to use for this estimate:</p>
                            </div>

                            <div className="modal-buttons">
                                <span onClick={() => selectUnits(true)}>Grams (g)</span>
                                <span onClick={() => selectUnits(false)}>Ounces (oz)</span>
                            </div>

                            <div className="modal-edit-section" style={{marginTop: "20px"}}>
                                <p> <em>Please note that using grams will result in greater accuracy.</em></p>
                            </div>
                        </div>
                        }

                        {page === 2 &&
                        <div className="modal-edit-section-instructions">
                            <div className="modal-edit-section">
                                <h3>1. Weight of Empty Container</h3>
                                <p>Place the empty vessel on the scale and record its weight in {getUnitName()}</p>
                                <input type="number" min={0} value={emptyWeight} onChange={(e) => setEmptyWeight(e.target.value)} placeholder={`What is the empty container weight in ${getUnitName()}?`} maxLength={48} />
                            </div>

                            <div className="modal-buttons">
                                <span className="modal-button-secondary" onClick={() => setPage(1)}>{`<`} Back</span>
                                <span onClick={() => setPage(page+1)}>Next</span>
                            </div>
                        </div>
                        }

                        {page === 3 &&
                        <div className="modal-edit-section-instructions">
                            <div className="modal-edit-section">
                                <h3>2. Weight of Water-Filled Container</h3>
                                <p>Now, fill the container with water up to the fill line and record the total weight in {getUnitName()}.</p>
                                <p>This measurement is the weight of the container, including the water weight currently inside.</p>
                                <input type="number" min={0} value={filledWeight} onChange={(e) => setFilledWeight(e.target.value)} placeholder={`What is the water-filled container weight in ${getUnitName()}?`} maxLength={48} />
                            </div>

                            <div className="modal-buttons">
                                <span className="modal-button-secondary" onClick={() => setPage(page-1)}>{`<`} Back</span>
                                {filledWeight && <span onClick={() => setPage(page+1)}>Next</span>}
                                {!filledWeight && <span style={{backgroundColor: "grey"}}>Next</span>}
                            </div>
                        </div>
                        }

                        {page === 4 &&
                        <div className="modal-edit-section-instructions">
                            <div className="modal-edit-section">
                                <h3>3. Estimated Capacity</h3>
                                <p>Based on your measurements, here is the container information:</p>
                                <div className="recipe-ingredients-list">
                                    <div className="recipe-ingredient-row">
                                        <div className="recipe-ingredient-name">Empty Vessel Weight</div>
                                        {isGrams && <div className="recipe-ingredient-value">{parseFloat(emptyWeight).toFixed(0)} g</div>}
                                        {!isGrams && <div className="recipe-ingredient-value">{parseFloat(emptyWeight).toFixed(2)} oz</div>}
                                    </div>
                                    <div className="recipe-ingredient-row">
                                        <div className="recipe-ingredient-name">Water Weight</div>
                                        {isGrams && <div className="recipe-ingredient-value">{parseFloat(filledWeight - emptyWeight).toFixed(0)} g</div>}
                                        {!isGrams && <div className="recipe-ingredient-value">{parseFloat(filledWeight - emptyWeight).toFixed(2)} oz</div>}
                                    </div>
                                    <div className="recipe-ingredient-row">
                                        <div className="recipe-ingredient-name">Container Capacity</div>
                                        <div className="recipe-ingredient-value" style={{fontWeight: 600}}>{getCapacityString()}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-buttons">
                                <span className="modal-button-secondary" onClick={() => setPage(page-1)}>{`<`} Back</span>
                                {capacityGrams && <span onClick={saveEstimateAndCloseModal}>Use {getCapacityString()}</span>}
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
