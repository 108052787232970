import React from 'react'
import { useEffect } from 'react'
import { MdClear } from 'react-icons/md'

export default function FloatingErrorMessage({message, closeErrorMessage}) {
    useEffect(() => {
        const timer = setTimeout(() => {
            closeErrorMessage()
        }, 10000)
        return () => clearTimeout(timer)
    // eslint-disable-next-line
    }, [])
    return (
        <div className="misc-floating-error-message-outer">
            <div className="misc-floating-error-message">
                <span>{message}</span>
                <MdClear onClick={closeErrorMessage}/>
            </div>
        </div>
    )
}
