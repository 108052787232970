import React from 'react'
import { useEffect, useState, useRef } from 'react'
import Api from '../../common/APIUtils'
import { BsCircle, BsCheckCircleFill, BsFillPlusSquareFill, BsCalendar } from 'react-icons/bs'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'
import { format, parseISO, addDays, isBefore, startOfToday } from 'date-fns'
import ModalTaskDateSelector from '../modals/ModalTaskDateSelector'
import ModalEditTask from '../modals/ModalEditTask'

export default function TaskList({globalState, dispatch, fetchUserLibrary }) {
    const api = new Api()
    const dateSelectionMenuRef = useRef(null)
    const [proposedTask, setProposedTask] = useState(() => { return ""})
    const [proposedDueDate, setProposedDueDate] = useState(() => { return ""})
    const [showDateSelector, setShowDateSelector] = useState(() => { return false })
    const [showModalEditTask, setShowModalEditTask] = useState(() => { return false })
    const [taskToEdit, setTaskToEdit] = useState(() => { return false })
    const [showDateSelectionMenu, setShowDateSelectionMenu] = useState(() => { return false })
    const [activeTasks, setActiveTasks] = useState(() => { return []})
    const [urgentActiveTasks, setUrgentActiveTasks] = useState(() => { return []})
    const [nonUrgentActiveTasks, setNonUrgentActiveTasks] = useState(() => { return []})
    const [completedTasks, setCompletedTasks] = useState(() => { return []})
    const SUBMISSION_KEY_CODES = ["enter", "numpadenter"]
    const updateTaskLists = () => {
        try {
            if (!globalState) return 
            if (!globalState.tasks) return
            if (globalState.tasks.length === 0) {
                setActiveTasks([])
                setCompletedTasks([])
                return
            }
            const taskCopy = Array.from(globalState.tasks)
            const foundActiveTasks = taskCopy.filter(t => t.status === "active")
            const foundCompletedTasks = taskCopy.filter(t => t.status === "completed")
            setActiveTasks(foundActiveTasks)
            setCompletedTasks(foundCompletedTasks)
        } catch (error) {
            return
        }
    }
    const updateActiveTaskOrder = () => {
        try {
            if (activeTasks.length === 0) {
                setUrgentActiveTasks([])
                setNonUrgentActiveTasks([])
                return
            }
            const activeTaskCopy = Array.from(activeTasks)  
            let tasksWithDueDates = activeTaskCopy.filter(t => t.date_due !== "")
            let orderedTasksWithDueDates = tasksWithDueDates.sort((a, b) => {
                return parseISO(a.date_due) - parseISO(b.date_due)
            })
            setUrgentActiveTasks(orderedTasksWithDueDates)
            let tasksWithoutDueDates = activeTaskCopy.filter(t => !t.date_due || t.date_due === "")
            let orderedNonurgentDueDates = tasksWithoutDueDates.sort((a,b) => {
                return a.name.localeCompare(b.name)
            })
            setNonUrgentActiveTasks(orderedNonurgentDueDates)
        } catch (error) {
            return
        }
    }
    const createLocalTaskCopy = (taskData) => {
        try {
            let currentActiveTasks = Array.from(activeTasks)
            const d = new Date();
            let tempId = d.toString();
            currentActiveTasks.push({
                _id: tempId,
                name: taskData.name,
                date_due: taskData.date_due,
                status: "active"
            })
            setActiveTasks(currentActiveTasks)
        } catch (error) {
            
        }
    }
    const createTask = () => {
        if (proposedTask.trim() === "") return
        let taskInformation = {
            name: proposedTask,
            date_due: proposedDueDate
        }
        createLocalTaskCopy(taskInformation)
        setProposedTask("")
        setProposedDueDate("")
        api.createTask(taskInformation)
        .then((res) => fetchUserLibrary())
        .catch((err) => fetchUserLibrary())
    }
    const restoreTask = (taskId) => {
        try {
            const existingCompletedTasks = Array.from(completedTasks)
            let updatedCompletedTasks = existingCompletedTasks.filter((task) => {
                if (task._id === taskId) return false
                return true
            })
            setCompletedTasks(updatedCompletedTasks)
            let payload = {
                task_id: taskId,
                status: "active"
            }
            api.updateTask(payload)
            .then((res) => fetchUserLibrary())
            .catch((err) => fetchUserLibrary())
        } catch (error) {
            return
        }
    }
    const completeTask = (taskId) => {
        let payload = {
            task_id: taskId,
            status: "completed"
        }
        api.updateTask(payload)
        .then((res) => fetchUserLibrary())
        .catch((err) => fetchUserLibrary())
    }
    const updateTask = (taskData) => {
        try {
            api.updateTask({...taskData, task_id: taskData._id})
            .then(res => fetchUserLibrary())
            .catch(err => fetchUserLibrary())
        } catch (error) {
            return
        }
    }
    const deleteTask = (taskId) => {
        // remove from its list
        api.deleteTask(taskId)
        .then((res) => fetchUserLibrary())
        .catch((err) => fetchUserLibrary())
    }
    const handleTaskKeyboardPress = (keyCode) => {
        try {
            if (!SUBMISSION_KEY_CODES.includes(keyCode.toLowerCase())) return 
            if (proposedTask.trim() !== "") return createTask()
            return
        } catch (error) {
            return
        }
    }
    const updateProposedDate = (numDays) => {
        setShowDateSelectionMenu(false)
        if (numDays < 0) return setShowDateSelector(true)
        const d = format(addDays(new Date(), numDays), 'yyyy-MM-dd')
        setProposedDueDate(d)
    }
    const removeDueDate = () => {
        setProposedDueDate("")
        setShowDateSelectionMenu(false)
    }
    const editTask = (task) => {
        setTaskToEdit(task)
        setShowModalEditTask(true)
    }
    const stopEditingTask = () => {
        setShowModalEditTask(false)
        setTaskToEdit(false)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dateSelectionMenuRef.current && !dateSelectionMenuRef.current.contains(event.target)) {
                setShowDateSelectionMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    // eslint-disable-next-line
    }, [dateSelectionMenuRef])
    useEffect(() => {
        updateTaskLists()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        updateActiveTaskOrder()
    // eslint-disable-next-line
    }, [activeTasks])
    return (
        <div>
            {showDateSelector && <ModalTaskDateSelector hideModal={() => setShowDateSelector(false) } currentDueDate={proposedDueDate} setDueDate={setProposedDueDate}/>  }
            {taskToEdit && showModalEditTask && <ModalEditTask task={taskToEdit} hideModal={stopEditingTask} completeTask={completeTask} updateTask={updateTask} deleteTask={deleteTask}/> }
            <div className="task-list-items">
                {urgentActiveTasks.map((task) => (
                    <Task key={task._id} task={task} restoreTask={restoreTask} completeTask={completeTask} editTask={editTask}/>
                ))}
                {nonUrgentActiveTasks.map((task) => (
                    <Task key={task._id} task={task} restoreTask={restoreTask} completeTask={completeTask} editTask={editTask}/>
                ))}
            </div>
            <div className="task-list-new-task">
                <div className="task-interaction-symbol"><BsCircle/></div>
                <input type="text" placeholder="Enter task name" value={proposedTask} onChange={(e) => setProposedTask(e.target.value)} onKeyDown={(e) => handleTaskKeyboardPress(e.code)} />
                <div className="recipe-container-desktop" style={{position: "absolute", right: 0, top: "6px", bottom: 0, width: "220px"}}>
                    <div className="task-list-new-task-actions">
                        <div onClick={() => setShowDateSelectionMenu(true)} className="util-row util-align-center" style={{gap: "6px", cursor: "pointer"}}>
                            <BsCalendar/>
                            {proposedDueDate && <span>{format(parseISO(proposedDueDate), 'EEE, LLL do')}</span>}
                        </div>
                        <BsFillPlusSquareFill onClick={createTask}/>
                    </div>
                </div>
                
                {showDateSelectionMenu &&
                <div className="task-list-date-selection-menu" ref={dateSelectionMenuRef}>
                    <span onClick={() => updateProposedDate(0)}>Today</span>
                    <span onClick={() => updateProposedDate(1)}>Tomorrow</span>
                    <span onClick={() => updateProposedDate(7)}>In 1 week</span>
                    <span onClick={() => updateProposedDate(-1)}>Select Date</span>
                    {proposedDueDate && <span onClick={() => removeDueDate()} style={{color: "var(--ml-color-val-red)" }}>Remove due date</span>}
                </div>
                }
            </div>
            <div className="responsive-recipe-container">
                <div className="util-row util-align-center util-justify-end">
                    <div className="task-list-new-task-actions" style={{position: "relative"}}>
                        <div onClick={() => setShowDateSelectionMenu(true)} className="util-row util-align-center" style={{gap: "6px", cursor: "pointer"}}>
                            <BsCalendar/>
                            {proposedDueDate && <span>{format(parseISO(proposedDueDate), 'EEE, LLL do')}</span>}
                        </div>
                        <BsFillPlusSquareFill onClick={createTask}/>
                    </div>
                </div>
            </div>
            
            {completedTasks.length > 0 &&
            <CollapsibleListSection title="Completed tasks" startCollapsed={true}>
                <div className="task-list-items">
                    {completedTasks.map((task) => (
                        <Task key={task._id} task={task} restoreTask={restoreTask} completeTask={completeTask} />
                    ))}
                </div>
            </CollapsibleListSection>
            }
        </div>
    )
}

function Task({task, completeTask, restoreTask, editTask}) {
    const getAdditionalNameStyles = () => {
        let styles = {}
        if (task.status === "completed") styles['textDecoration'] = "line-through"
        if (task.date_due) {
            if (task.status === "active" && isBefore(parseISO(task.date_due), startOfToday())) styles["color"] = "var(--ml-color-val-red)"
        }
        return styles
    }
    const getAdditionalDateStyles = () => {
        let styles = {}
        if (task.date_due) {
            if (task.status === "active" && isBefore(parseISO(task.date_due), startOfToday())) styles["color"] = "var(--ml-color-val-red)"
        }
        return styles
    }
    const attemptTaskEdit = () => {
        if (editTask) editTask(task)
        return
    }
    const handleSymbolClick = () => {
        if (task.status === "completed") return restoreTask(task._id)
        if (task.status === "active") return completeTask(task._id)
        return
    }
    return (
        <div className="task-outer">
            <div className="task-interaction-symbol" onClick={handleSymbolClick}>
                {task.status === "completed" && <BsCheckCircleFill />}
                {task.status === "active" &&
                <>
                    <BsCircle className="task-interaction-active-circle" />
                    <BsCheckCircleFill className="task-interaction-active-circle-preview" />
                </>
                
                }
            </div>
            <div className="task-name-container" onClick={attemptTaskEdit}>
                <div className="task-name" style={getAdditionalNameStyles()}>{task.name}</div>
                {task.date_due && <span style={getAdditionalDateStyles()}>{format(parseISO(task.date_due), 'L/d/yy')}</span>}
                {!task.date_due && <span style={{minWidth: "64px"}}></span>}
            </div>
        </div>
    )
}