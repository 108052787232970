import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate  } from 'react-router-dom'
import { HiOutlinePlus } from 'react-icons/hi2'
import { BiSort } from 'react-icons/bi'
import GeneralPageSearchBar from '../gadgets/GeneralPageSearchBar'
import ModalAddNewInventoryItem from '../modals/ModalAddNewInventoryItem'
import { formatCurrencyUSD, capitalizeFirstWord, sortByProperty, sortByPropertyReverse } from '../../common/Helpers'

export default function InventoryMain({globalState, fetchUserLibrary, dispatch}) {
    const [query, setQuery] = useState(() => { return "" })
    const [inventoryView, setInventoryView] = useState(() => { return "all" })
    const [inventorySortingMethod, setInventorySortingMethod] = useState(() => { return "supply_name" })
    const [isAddingNewItem, setIsAddingNewItem] = useState(() => { return false })
    const [inventoryItemsRaw, setInventoryItemsRaw] = useState(() => { return [] })
    const [inventoryItemsFiltered, setInventoryItemsFiltered] = useState(() => { return [] })
    const navigate = useNavigate();
    const getInventoryNavClassName = (proposedClassName) => {
        if (proposedClassName === inventoryView && inventoryView === "archive") return "selected-archive"
        if (proposedClassName === inventoryView) return "selected"
        return ""
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const AVAILABLE_INVENTORY = [
        { display_name: "All", view: "all" },
        { display_name: "Containers", view: "container" },
        { display_name: "Wax", view: "wax" },
        { display_name: "Fragrance Oil", view: "fragrance" },
        { display_name: "Wicks", view: "wick" },
        { display_name: "Archive", view: "archive" },
        // { display_name: "Colors", view: "colors" },
    ]
    const closeNewCreationModal = (shouldFetch) => {
        if (shouldFetch) fetchUserLibrary()
        setIsAddingNewItem(false)
    }
    const updateFilteredItems = () => {
        try {
            if (!inventoryItemsRaw || inventoryItemsRaw.length === 0) return setInventoryItemsFiltered([])
            const copyOfRawInventory = Array.from(inventoryItemsRaw)
            let filteredByView = copyOfRawInventory
            if (inventoryView !== "all" && inventoryView !== "archive") {
                filteredByView = copyOfRawInventory.filter((inventoryItem) => {
                    if (inventoryItem.status === "archived") return false
                    if (inventoryItem.type === inventoryView) return true
                    return false
                })
            }
            if (inventoryView === "archive") {
                filteredByView = copyOfRawInventory.filter((inventoryItem) => {
                    if (inventoryItem.status === "archived") return true
                    return false
                })
            }
            if (inventoryView === "all") {
                filteredByView = copyOfRawInventory.filter((inventoryItem) => {
                    if (inventoryItem.status === "archived") return false
                    return true
                })
            }
            let sortedItems = filteredByView
            // Sort by current sorting mechanism
            if (inventorySortingMethod === "supply_name") {
                sortedItems = sortByProperty(filteredByView, "supply_name")
            }
            if (inventorySortingMethod === "supply_name_reverse") {
                sortedItems = sortByPropertyReverse(filteredByView, "supply_name")
            }

            if (inventorySortingMethod === "type") {
                sortedItems = sortByProperty(filteredByView, "type")
            }
            if (inventorySortingMethod === "type_reverse") {
                sortedItems = sortByPropertyReverse(filteredByView, "type")
            }

            if (inventorySortingMethod === "unit_price_pennies") {
                sortedItems = sortByProperty(filteredByView, "unit_price_pennies")
            }
            if (inventorySortingMethod === "unit_price_pennies_reverse") {
                sortedItems = sortByPropertyReverse(filteredByView, "unit_price_pennies")
            }
            
            if (!query.trim()) return setInventoryItemsFiltered(sortedItems)
            // Filter by query
            let filteredByQuery = sortedItems.filter((item) => {
                const itemTextToSearch = `${item.supply_name}${item.type}${item.supplier_name}${item.supplier_website}${item.notes}`
                if (itemTextToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
                return false
            })
            setInventoryItemsFiltered(filteredByQuery)
            
        } catch (error) {
            return setInventoryItemsFiltered([])
        }
    }
    const updateSortingMethod = (proposedSortingMethod) => {
        if (proposedSortingMethod === "supply_name" && inventorySortingMethod === "supply_name") return setInventorySortingMethod("supply_name_reverse")
        if (proposedSortingMethod === "type" && inventorySortingMethod === "type") return setInventorySortingMethod("type_reverse")
        if (proposedSortingMethod === "unit_price_pennies" && inventorySortingMethod === "unit_price_pennies") return setInventorySortingMethod("unit_price_pennies_reverse")
        return setInventorySortingMethod(proposedSortingMethod)
    }
    useEffect(() => {
        if (!globalState.loaded) return
        if (globalState.supplies.length > 0) setInventoryItemsRaw(globalState.supplies)
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        fetchUserLibrary()
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        updateFilteredItems()
    // eslint-disable-next-line
    }, [inventoryItemsRaw, inventoryView, query, inventorySortingMethod])
    return (
        <div className="general-category-page-main">
            {isAddingNewItem && <ModalAddNewInventoryItem hideModal={closeNewCreationModal}/>}
            <div className="general-category-page-header">
                <h2>Inventory</h2>
                <div className="util-row util-align-center" style={{gap: "12px"}}>
                    <div className="desktop-only"><GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} /></div>
                    <div className="general-category-page-create" onClick={() => setIsAddingNewItem(true)}><HiOutlinePlus/> New Item</div>
                </div>
            </div>
            <div className="util-row util-align-center util-space-between mobile-only" style={{gap: "12px", margin: "12px", alignItems: "stretch"}}>
                <div className="inventory-nav-mobile">
                    <select onChange={(e) => setInventoryView(e.target.value)} value={inventoryView}>
                        {AVAILABLE_INVENTORY.map((inventory_item) => (
                            <option value={inventory_item.view} key={inventory_item.view}>{inventory_item.display_name}</option>
                        ))}
                    </select>
                </div>
                <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
            </div>

            <div className="inventory-main">
                <div className="desktop-only">
                    <div className="inventory-nav-desktop">
                        {AVAILABLE_INVENTORY.map((inventory_item) => (
                            <span key={inventory_item.view} className={getInventoryNavClassName(inventory_item.view)} onClick={() => setInventoryView(inventory_item.view)}>{inventory_item.display_name}</span>
                        ))}
                    </div>
                </div>
                <div className="general-table-container-full-width">
                    <div className="general-table-flex-row general-table-headings" style={{margin: 0}}>
                        <div className="general-table-col general-table-col-5"></div>
                        <div className="general-table-col general-table-col-30">
                            <div className="general-table-heading-sortable" onClick={() => updateSortingMethod("supply_name")}>Name <BiSort/></div>
                        </div>
                        <div  className="general-table-col general-table-col-25">
                            <div className="general-table-heading-sortable" onClick={() => updateSortingMethod("type")}>Type <BiSort/></div>
                        </div>
                        <div className="general-table-col general-table-col-15">Units</div>
                        <div className="general-table-col general-table-col-15">
                            <div className="general-table-heading-sortable" onClick={() => updateSortingMethod("unit_price_pennies")}>Unit Price <BiSort/></div>
                        </div>
                        <div className="general-table-col general-table-col-10"></div>
                    </div>
                    {inventoryItemsFiltered.map((inventoryItem) => (
                        <div className="general-table-flex-row general-table-row-content" key={inventoryItem._id} style={{}}>
                        <div className="general-table-col general-table-col-5"></div>
                            <div className="general-table-col general-table-col-30">
                                <span className="general-table-col-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${inventoryItem._id}`)}>{inventoryItem.supply_name}</span>
                                {inventoryItem.supplier_name &&
                                <div className="general-table-col-underbadge">
                                    <span>{inventoryItem.supplier_name}</span>
                                </div>
                                }
                            </div>
                            <div className="general-table-col general-table-col-25">
                                <span>{capitalizeFirstWord(inventoryItem.type)}</span>    
                            </div>
                            <div className="general-table-col general-table-col-15">{inventoryItem.units}</div>
                            <div className="general-table-col general-table-col-15">{formatCurrencyUSD(inventoryItem.unit_price_pennies)}</div>
                            <div className="general-table-col general-table-col-10" style={{height: "60px"}}>
                                <div className="general-table-button-inset" onClick={() => goToPath(`/candles/inventory/supplies/${inventoryItem._id}`)}>Details</div>
                            </div>
                        </div>
                    ))}
                    {inventoryItemsFiltered.length === 0 &&
                    <div style={{fontStyle: "italic", marginTop: "12px", textAlign: "center", fontSize: "14px"}}>Nothing found</div>
                    }
                </div>
            </div>

        </div>
    )
}
