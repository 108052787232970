import React from 'react'
import { MdClear } from 'react-icons/md'

export default function ModalConfirmAction({hideModal, takeActionFunction, heading, text, confirmButtonText}) {
    const takeAction = () => {
        hideModal()
        takeActionFunction()
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>{heading}</h2>
                            <MdClear onClick={hideModal}/>
                        </div>

                        <div className="modal-display-text">{text}</div>

                        <div className="modal-buttons" style={{gap: "14px"}}>
                            <span onClick={takeAction}>{confirmButtonText}</span>
                            <span style={{backgroundColor: "grey"}} onClick={hideModal}>Cancel</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
