import React from 'react'
import { useState, useEffect } from 'react'
import { HiFire } from 'react-icons/hi2'
import { BiSort } from 'react-icons/bi'
import { useNavigate  } from 'react-router-dom'
import PrimaryCandleCard from './PrimaryCandleCard'
import GeneralPageSearchBar from '../gadgets/GeneralPageSearchBar'
import { parseISO, format } from 'date-fns'
import { convertCandleTypeRawToDisplay, convertBadgeName, getRecipeWaxes, standardCardSections, getCardsBySection, getRecipeWaxArray, getRecipeFOArray, getSupplyNameById } from '../../common/Helpers'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'

export default function Candles({globalState, dispatch, fetchUserLibrary}) {
    const [query, setQuery] = useState(() => { return "" })
    const [candles, setCandles] = useState(() => { return [] })
    const [sortedCandles, setSortedCandles] = useState(() => { return [] })
    const [sortBy, setSortBy] = useState(() => { return "created" })
    const navigate = useNavigate()
    const goToCandlePage = (candleId) => {
        return navigate(`/candles/candle/${candleId}`)
    }
    const getWickName = (candle) => {
        try {
            if (candle.wick_id) return <span className="general-table-col-clickable" onClick={() => navigate(`/candles/inventory/supplies/${candle.wick_id}`)}>{getSupplyNameById(globalState.supplies, candle.wick_id)}</span>
            if (candle.wick) return candle.wick
            if (!candle.wick && !candle.wick_id) return "-"
            return ""
        } catch (error) {
            return ""
        }
    }
    const getCurrentCandles = () => {
        try {
            setCandles([])
            if (!globalState.candles) return []
            let currentCandles = globalState.candles
            let currentSupplies = globalState.supplies
            if (query.trim()) {
                currentCandles = globalState.candles.filter((candle) => {
                    let recipe = candle.recipe
                    let batch = candle.batch
                    let containerName = recipe.container_name
                    if (recipe.container_id) {
                        const recipeContainer = currentSupplies.find(s => s._id === recipe.container_id)
                        if (recipeContainer) containerName = recipeContainer.supply_name
                    }
                    let waxNames = getRecipeWaxes(recipe, candle.batch, globalState.supplies)
                    const candleBadgeSearch = candle.badges.map(b => convertBadgeName(b))
                    const textToSearch = `${candle.name}${candle.wick}${batch.name}${waxNames}${convertCandleTypeRawToDisplay(recipe.recipe_type)}${recipe.fragrance_oil}${recipe.name}${candle.notes}${batch.notes}${candleBadgeSearch.toString()}${containerName}${recipe.fragrance_load}%`
                    if (textToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
                    return false
                })
            }
            return currentCandles
        } catch (error) {
            return []
        }
    }
    const getWaxName = (waxData) => {
        try {
            if (waxData.supply_id) {
                return <span className="general-table-col-clickable" onClick={() => navigate(`/candles/inventory/supplies/${waxData.supply_id}`)}>{getSupplyNameById(globalState.supplies, waxData.supply_id)}</span> 
            } else {
                return waxData.name
            }
        } catch (error) {
            return waxData.name
        }
    }
    const getFragranceName = (foData) => {
        try {
            if (foData.supply_id) {
                return <span className="general-table-col-clickable" onClick={() => navigate(`/candles/inventory/supplies/${foData.supply_id}`)}>{getSupplyNameById(globalState.supplies, foData.supply_id)}</span> 
            } else {
                return foData.name
            }
        } catch (error) {
            return foData.name
        }
    }
    const updateSortedCandles = () => {
        try {
            let updatedCandles = Array.from(candles)
            let sorted = updatedCandles
            if (sortBy === "created") {
                sorted = updatedCandles.sort((a, b) => {
                    return parseISO(b.updatedAt) - parseISO(a.updatedAt)
                })
            }
            if (sortBy === "created_reverse") {
                sorted = updatedCandles.sort((a, b) => {
                    return parseISO(a.updatedAt) - parseISO(b.updatedAt)
                })
            }
            if (sortBy === "recipe") {
                sorted = updatedCandles.sort((a,b) => {
                    return a.recipe.name.localeCompare(b.recipe.name)
                })
            }
            if (sortBy === "recipe_reverse") {
                sorted = updatedCandles.sort((a,b) => {
                    return b.recipe.name.localeCompare(a.recipe.name)
                })
            }
            if (sortBy === "id") {
                sorted = updatedCandles.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            }
            if (sortBy === "id_reverse") {
                sorted = updatedCandles.sort((a, b) => {
                    return b.name.localeCompare(a.name)
                })
            }
            if (sortBy === "fragrance_load") {
                sorted = updatedCandles.sort((a, b) => {
                    return parseFloat(a.recipe.fragrance_load) - parseFloat(b.recipe.fragrance_load)
                })
            }
            if (sortBy === "fragrance_load_reverse") {
                sorted = updatedCandles.sort((a, b) => {
                    return parseFloat(b.recipe.fragrance_load) - parseFloat(a.recipe.fragrance_load)
                })
            }
            setSortedCandles(sorted)
        } catch (error) {
            setSortedCandles(candles)
        }
    }
    const updateSortMethod = (baseSortName) => {
        if (baseSortName === "recipe" && sortBy === "recipe") return setSortBy("recipe_reverse")
        if (baseSortName === "created" && sortBy === "created") return setSortBy("created_reverse")
        if (baseSortName === "fragrance_load" && sortBy === "fragrance_load") return setSortBy("fragrance_load_reverse")
        if (baseSortName === "id" && sortBy === "id") return setSortBy("id_reverse")
        setSortBy(baseSortName)
    }
    const getExtraHeadingStyle = (baseName) => {
        let reverseBaseName = `${baseName}_reverse`
        if ([baseName, reverseBaseName].includes(sortBy)) return { color: "var(--ml-color-accent)", fontWeight: 600, textDecoration: "underline"}
        return {}
    }
    const getContainerNameFromRecipe = (recipe) => {
        try {
            if (!recipe) return
            if (!recipe.container_id) return
            if (!globalState.supplies.length === 0) return
            const recipeContainer = globalState.supplies.find(s => s._id === recipe.container_id)
            return recipeContainer.supply_name
        } catch (error) {
            return ""
        }
    }
    const getContainerCapacityFromRecipe = (recipe) => {
        try {
            if (!recipe) return
            if (!recipe.container_id) return
            if (!globalState.supplies.length === 0) return
            const recipeContainer = globalState.supplies.find(s => s._id === recipe.container_id)
            return recipeContainer.properties.vessel_capacity_grams
        } catch (error) {
            return 0
        }
    }
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        dispatch({type: "nav", payload: {view: "candles"}})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        setCandles(getCurrentCandles())
    // eslint-disable-next-line
    }, [globalState, query])
    useEffect(() => {
        updateSortedCandles()
    // eslint-disable-next-line
    }, [sortBy, candles])
    return (
        <div>
            {globalState.candles.length > 0 &&
            <div className="general-category-page-main"> 
                <div className="general-category-page-header">
                    <h2>Candles</h2>
                </div>
                <div className="responsive-recipe-container">
                    <div className="general-category-page-utilities">
                        <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                    </div>
                    {!query &&
                    <div style={{margin: "0px 12px"}}>
                        {standardCardSections.map((cardSection) => (
                            <div key={cardSection.name}>
                                {getCardsBySection(globalState.candles, cardSection, 'updatedAt').length > 0 && (
                                    <CollapsibleListSection title={cardSection.name}>
                                        {getCardsBySection(globalState.candles, cardSection, 'updatedAt').map((candle) => (
                                            <PrimaryCandleCard candle={candle} key={candle._id} navLocation={`/candles/candle/${candle._id}`} globalState={globalState} />
                                        ))}
                                    </CollapsibleListSection>
                                )}
                            </div>
                        ))}
                    </div>
                    }

                    {query && globalState.candles.length > 0 &&
                    <div className="general-card-list candles-card-list" style={{gap: 0}}>
                        {candles.map((candle) => (
                            <PrimaryCandleCard candle={candle} key={candle._id} navLocation={`/candles/candle/${candle._id}`} globalState={globalState} />
                        ))}
                    </div>
                    }
                </div>

                <div className="recipe-container-desktop">
                    <div className="general-category-page-utilities">
                        <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                    </div>
                    <div className="general-table-container-full-width">
                        <div className="general-table-flex-row general-table-headings">
                            <div className="general-table-col general-table-col-5"></div>
                            <div className="general-table-col general-table-col-8" onClick={() => updateSortMethod("id")}>
                                <div className="general-table-heading-sortable" style={getExtraHeadingStyle("id")}>ID <BiSort/></div>
                            </div>
                            <div style={getExtraHeadingStyle("recipe")} className="general-table-col general-table-col-grow" onClick={() => updateSortMethod("recipe")}>
                                <div className="general-table-heading-sortable" style={getExtraHeadingStyle("recipe")}>Recipe <BiSort/></div>
                            </div>
                            <div className="general-table-col general-table-col-8">Wick</div>
                            <div style={getExtraHeadingStyle("fragrance_load")} className="general-table-col general-table-col-10" onClick={() => updateSortMethod("fragrance_load")}>
                                <div className="general-table-heading-sortable" style={getExtraHeadingStyle("fragrance_load")}>Load <BiSort/></div>
                            </div>
                            <div className="general-table-col general-table-col-15">Wax</div>
                            <div className="general-table-col general-table-col-15">Fragrance Oil</div>
                            <div className="general-table-col general-table-col-10">Container</div>
                            <div style={getExtraHeadingStyle("created")} className="general-table-col general-table-col-8" onClick={() => updateSortMethod("created")}>
                                <div className="general-table-heading-sortable" style={getExtraHeadingStyle("created")}>Poured On <BiSort/></div>
                            </div>
                            <div className="general-table-col general-table-col-5"></div>
                        </div>
                        <div className="general-table-rows-surrogate">
                            {sortedCandles.map((candle) => (
                                <div className="general-table-flex-row general-table-row-content" key={candle._id}>
                                    <div className="general-table-col general-table-col-5"></div>
                                    <div className="general-table-col general-table-col-8">
                                        <span className="general-table-col-clickable" onClick={() => goToCandlePage(candle._id)}>{candle.name}</span>
                                    </div>
                                    <div className="general-table-col general-table-col-grow">
                                        <span className="general-table-col-clickable" onClick={() => navigate(`/recipe/${candle.recipe._id}`)}>{candle.recipe.name}</span>    
                                    </div>
                                    <div className="general-table-col general-table-col-8">{getWickName(candle)} {candle.recipe.wick_count > 1 && <span style={{color: "grey", fontSize: "0.9em", marginLeft: "4px"}}>x {candle.recipe.wick_count}</span>}</div>
                                    <div className="general-table-col general-table-col-10">{candle.recipe.fragrance_load}%</div>
                                    <div className="general-table-col general-table-col-15">
                                        <div className="general-table-col-stacked-entries">
                                           {getRecipeWaxArray(candle.recipe).map((waxData) => (
                                                <span key={`${waxData.name}-${waxData.weight}`}>{getWaxName(waxData)} {waxData.weight < 100 && <span style={{color: "grey", fontSize: "0.8em", marginLeft: "4px"}}>{waxData.weight}%</span>} </span>
                                            ))} 
                                        </div>
                                    </div>
                                    <div className="general-table-col general-table-col-15">
                                        <div className="general-table-col-stacked-entries">
                                           {getRecipeFOArray(candle.recipe).map((foData) => (
                                                <span key={`${foData.name}-${foData.weight}`}>{getFragranceName(foData)} {foData.weight < 100 && <span style={{color: "grey", fontSize: "0.8em", marginLeft: "4px"}}>{foData.weight}%</span>} </span>
                                            ))}
                                        </div>
                                    </div>
                                    {!candle.recipe.container_id &&
                                    <div className="general-table-col general-table-col-10">
                                        <div>{candle.recipe.container_name ? candle.recipe.container_name : "Containerless"}</div>
                                        <div style={{fontSize: "0.9em", color: "grey"}}>{parseFloat(candle.recipe.vessel_capacity_grams * 0.03527396).toFixed(2)} oz ({parseFloat(candle.recipe.vessel_capacity_grams).toFixed(0)} g)</div>
                                    </div>
                                    }
                                    {candle.recipe.container_id &&
                                    <div className="general-table-col general-table-col-10">
                                        <div><span className="general-table-col-clickable" onClick={() => navigate(`/candles/inventory/supplies/${candle.recipe.container_id}`)}>{getContainerNameFromRecipe(candle.recipe)}</span></div>
                                        <div style={{fontSize: "0.9em", color: "grey"}}>{parseFloat(getContainerCapacityFromRecipe(candle.recipe) * 0.03527396).toFixed(2)} oz ({parseFloat(getContainerCapacityFromRecipe(candle.recipe)).toFixed(0)} g)</div>
                                    </div>
                                    }
                                    <div className="general-table-col general-table-col-8">{format(parseISO(candle.createdAt), 'PP')}</div>
                                    <div className="general-table-col general-table-col-5">
                                        <div className="general-table-button-inset" onClick={() => goToCandlePage(candle._id)}>Details</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            }

            {globalState.candles.length === 0 &&
            <div className="general-empty-state-container">
                
                <div className="general-empty-state-description">
                    <div className="general-empty-state-icon-container">
                        <HiFire/>
                    </div>
                    <h3>No candles</h3>
                    <div className="general-empty-state-instructions">
                        Come back after you create a new batch to see all your candles.
                    </div>
                </div>
                
                {globalState.recipes.length > 0 && <button onClick={() => navigate('/create/batch')}>Create new batch</button>}
                {globalState.recipes.length === 0 && <button onClick={() => navigate('/create/recipe')}>Create new recipe</button>}

            </div>
            }
        </div>
    )
}
