import React from 'react'
import Api from '../../common/APIUtils'
import { useParams } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import HeaderReturnToPage from '../misc/HeaderReturnToPage'
import { getBackgroundHexFromName, standardCardSections, getCardsBySection, getAdditionalCardListStyles, getSupplyNameById } from '../../common/Helpers'
import { useNavigate  } from 'react-router-dom'
import InternalNavigationSections from '../navigation/InternalNavigationSections'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'
import PrimaryRecipeCard from '../recipes/PrimaryRecipeCard'
import PrimaryBatchCard from '../batches/PrimaryBatchCard'
import Description from '../misc/Description'
import CollapsibleListSection from '../gadgets/CollapsibleListSection'
import ModalConfirmAction from '../modals/ModalConfirmAction'
import ModalAddRecipeToCollection from '../modals/ModalAddRecipeToCollection'
import ModalEditCollection from '../modals/ModalEditCollection'
import ModalRemoveRecipesFromCollection from '../modals/ModalRemoveRecipesFromCollection'

export default function Collection({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const navigate = useNavigate();
    const [view, setView] = useState(() => { return "recipes" })
    const [collection, setCollection] = useState(() => { return false })
    const [recipes, setRecipes] = useState(() => { return [] })
    const [batches, setBatches] = useState(() => { return [] })
    const [candles, setCandles] = useState(() => { return [] })
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(() => { return false })
    const [showEditModal, setShowEditModal] = useState(() => { return false })
    const [showRemoveModal, setShowRemoveModal] = useState(() => { return false })
    const { collectionId } = useParams()
    const handleAction = (actionName) => {
        if (actionName === "delete") return setShowConfirmDeleteModal(true)
        if (actionName === "edit") return setShowEditModal(true)
        if (actionName === "add" && getAddableRecipes().length > 0) return setView("add")
        if (actionName === "remove" && getRecipes().length > 0) return setShowRemoveModal(true)
    }
    const goToCandlePage = (candleId) => {
        return navigate(`/candles/candle/${candleId}`)
    }
    const getRecipes = () => {
        try {
            if (!collection) return []
            const filteredRecipes = globalState.recipes.filter((recipe) => {
                if (collection.recipe_ids.includes(recipe._id)) return true
                return false
            })
            return filteredRecipes
        } catch (error) {
            return []
        }
    }
    const getBatches = () => {
        try {
            if (!collection) return []
            if (!recipes || recipes.length === 0) return []
            const recipeIds = recipes.map(r => r._id)
            const filteredBatches = globalState.batches.filter((batch) => {
                if (recipeIds.includes(batch.recipe_id)) return true
                return false
            })
            return filteredBatches
        } catch (error) {
            return []
        }
    }
    const getCandles = () => {
        try {
            if (!collection) return []
            if (!batches || batches.length === 0) return []
            const batchIds = batches.map(r => r._id)
            const filteredCandles = globalState.candles.filter((candle) => {
                if (batchIds.includes(candle.batch_id)) return true
                return false
            })
            return filteredCandles
        } catch (error) {
            return []
        }
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const getAddableRecipes = () => {
        try {
            if (!collection) return []
            const filteredRecipes = globalState.recipes.filter((recipe) => {
                if (collection.recipe_ids.includes(recipe._id)) return false
                return true
            })
            return filteredRecipes
        } catch (error) {
            return []
        }
    }
    const editCollection = (payload) => {
        setShowEditModal(false)
        api.updateCollection(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            fetchUserLibrary()
        })
    }
    const attemptCollectionDelete = () => {
        if (!collectionId) return
        api.deleteCollection(collectionId)
        .then((res) => { return navigate("/candles/recipes")})
        .catch((err) => { return })
    }
    const addRecipeToCollection = (recipeId) => {
        setView("recipes")
        let payload = {
            recipe_id_to_add: recipeId,
            collection_id: collection._id
        }
        api.updateCollection(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            fetchUserLibrary()
        })
    }
    const updateDescription = (description) => {
        let payload = {
            collection_id: collection._id,
            description: description
        }
        api.updateCollection(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            fetchUserLibrary()
        })
    }
    const additionalRecipeListStyles = () => {
        try {
            if (recipes.length < 3) return { flexDirection: "column" }
            return {}
        } catch (error) {
            return {}
        }
    }
    const saveRemovalUpdates = (payload) => {
        setShowRemoveModal(false)
        api.updateCollection(payload)
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            fetchUserLibrary()
        })
        
    }
    const getCandleRecipe = (candle) => {
        try {
            const batch = batches.find(b => b._id === candle.batch_id)
            return batch.recipe[0]
        } catch (error) {
            return false
        }
    }
    useEffect(() => {
        setRecipes(getRecipes())
    // eslint-disable-next-line
    }, [collection])
    useEffect(() => {
        setBatches(getBatches())
    // eslint-disable-next-line
    }, [recipes])
    useEffect(() => {
        setCandles(getCandles())
    // eslint-disable-next-line
    }, [batches])
    useEffect(() => {
        if (!collectionId) return
        const allCollections = Array.from(globalState.collections)
        const selectedCollection = allCollections.find(r => r._id.toString() === collectionId)
        setCollection(selectedCollection)
    // eslint-disable-next-line
    }, [collectionId, globalState])
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "recipes"}})
    // eslint-disable-next-line
    }, [])
    return (
        <div className="general-page-create general-page-recipe">
            {collection &&
            <>
            {showRemoveModal && <ModalRemoveRecipesFromCollection recipes={getRecipes()} saveRemovalUpdates={saveRemovalUpdates} collection={collection} hideModal={() => setShowRemoveModal(false)} />}
            {showConfirmDeleteModal && <ModalConfirmAction hideModal={() => setShowConfirmDeleteModal(false)} confirmButtonText="Delete collection" text={`Delete ${collection.name}?  \n\nThis will only remove the collection.\n\nNo recipes will be deleted.`} heading="⚠️ Delete Collection" takeActionFunction={attemptCollectionDelete} /> }
            {showEditModal && <ModalEditCollection sendEdits={editCollection} collection={collection} hideModal={() => setShowEditModal(false)}/>}
            <div className="util-row util-space-between util-align-start" style={{maxWidth: "100%", boxSizing: "border-box", marginRight: "12px"}}>
                <HeaderReturnToPage title="Collections" navigationUrl={"/candles/recipes?v=collections"}/>
                <EditRecipeMenu handleAction={handleAction}/>
            </div>
            <div className="category-page-header">
                <div className="category-page-heading-titles">
                    <div className="util-row util-align-center" style={{marginBottom: "14px", alignItems: "stretch"}}>
                        {collection.color_name !== "default" && <div className="collection-color-dot" style={{backgroundColor: getBackgroundHexFromName(collection.color_name)}}></div>}
                        <h1 style={{marginBottom: "0px"}}>{collection.name}</h1>
                    </div>
                    <div style={{margin: "4px 12px"}}>
                        <Description description={collection.description} updateDescription={updateDescription} />
                    </div>
                    <div className="util-row util-align-center" style={{marginTop: "12px"}}>
                        <div className="recipe-badge-decoration">Collection</div>
                    </div>
                </div>
            </div>

            <InternalNavigationSections sections={["recipes", "batches", "candles"]} view={view} setView={setView} />

            {view === "recipes" &&
            <div className="general-card-list-recipes" style={{marginBottom: "40px", marginTop: "40px", ...additionalRecipeListStyles()}}>
                {recipes.map((recipe) => (
                    <PrimaryRecipeCard recipe={recipe} key={recipe._id} navLocation={goToPath} globalState={globalState}/>
                ))}
                {/* Empty Card */}
                {getAddableRecipes().length > 0 &&
                <div className="general-card general-card-recipe" style={{flexBasis: "100%", minHeight: "80px", color: "grey", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => setView("add")}>
                    + Add recipe
                </div>
                }
            </div>
            }
            
            {view === "batches" &&
            <div className="general-page-create-section">
                <div style={{margin: "0px 12px"}}>
                    {standardCardSections.map((batchSection) => (
                        <div key={batchSection.name}>
                            {getCardsBySection(batches, batchSection, 'pour_date').length > 0 && (
                                <CollapsibleListSection title={batchSection.name}>
                                    <div className="general-card-list-recipes" style={getAdditionalCardListStyles(getCardsBySection(batches, batchSection, 'pour_date').length)}>
                                        {getCardsBySection(batches, batchSection, 'pour_date').map((batch) => (
                                            <PrimaryBatchCard batch={batch} key={batch._id} navLocation={`/candles/batch/${batch._id}`}/>
                                        ))}
                                    </div>
                                </CollapsibleListSection>
                            )}
                        </div>
                    ))}
                    {batches.length === 0 &&
                        <div className="general-card-list-text-empty">No batches found.</div>
                    }
                </div>
            </div>
            }

            {view === "candles" &&
            <div className="general-page-create-section">
                <div style={{margin: "0px 12px"}}>
                    {standardCardSections.map((section) => (
                        <div key={section.name}>
                            {getCardsBySection(candles, section, 'updatedAt').length > 0 && (
                                <CollapsibleListSection title={section.name}>
                                    <div className="general-card-list-candle-squares">
                                        {getCardsBySection(candles, section, 'updatedAt').map((candle) => (
                                            <SlimCandleItem key={candle._id} candle={candle} goToCandlePage={goToCandlePage} recipe={getCandleRecipe(candle)} globalState={globalState}/>
                                        ))}
                                    </div>
                                </CollapsibleListSection>
                            )}
                        </div>
                    ))}
                    {candles.length === 0 &&
                        <div className="general-card-list-text-empty">No candles found</div>
                    }
                </div>
            </div>
            }

            {view === "add" && getAddableRecipes().length > 0 && 
                <ModalAddRecipeToCollection recipes={getAddableRecipes()} hideModal={() => setView("recipes")} addRecipeToCollection={addRecipeToCollection} collection={collection} globalState={globalState}/>
            }
            </>}
        </div>
    )
}

function EditRecipeMenu({handleAction}) {
    const editMenuRef = useRef(null)
    const [isShowingMenu, setIsShowingMenu] = useState(() => { return false })
    const takeAction = (actionName) => {
        setIsShowingMenu(false)
        handleAction(actionName)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editMenuRef.current && !editMenuRef.current.contains(event.target)) {
                setIsShowingMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [editMenuRef])
    return (
        <div ref={editMenuRef}>
            <div className="edit-menu-container">
                <span className="general-top-right-button-options" onClick={() => setIsShowingMenu(!isShowingMenu)}><HiOutlineDotsHorizontal/></span>
                {isShowingMenu && 
                <div className="edit-menu-options">
                    <div onClick={() => takeAction("edit")}>Edit</div>
                    <div onClick={() => takeAction("add")}>Add recipe</div>
                    <div onClick={() => takeAction("remove")}>Remove recipes</div>
                    <div onClick={() => takeAction("delete")}>Delete</div>
                </div>
                }
            </div>
            
        </div>
        
    )
}

function SlimCandleItem({candle, goToCandlePage, recipe, globalState}) {
    return (
        <div className="candle-card-small-square" onClick={() => goToCandlePage(candle._id)}>
            <div className="candle-card-small-names">
                <div className="candle-card-small-name">{candle.name}</div>
                <div className="candle-card-small-recipe">{recipe.name}</div>
            </div>
            {candle.wick_id && !candle.wick && <div className="candle-card-small-wick">{getSupplyNameById(globalState.supplies, candle.wick_id)}</div> }
            {!candle.wick_id && candle.wick && <div className="candle-card-small-wick">{candle.wick}</div> }
            {!candle.wick_id && !candle.wick && <div className="candle-card-small-wick">Wax Melt</div> }
        </div>
    )
}