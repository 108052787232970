import React from 'react'
import Api from '../../common/APIUtils'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { getWaxSchema2, getFOSchema2, getSupplyById, getSupplyIdsFromRecipe, getSupplyIdsFromBatch } from '../../common/Helpers'
import { useNavigate  } from 'react-router-dom'
import Notes from '../misc/Notes'
import InventoryItemNotes from '../misc/InventoryItemNotes'
import InternalNavigationSections from '../navigation/InternalNavigationSections'
import ModalConfirmAction from '../modals/ModalConfirmAction'
import BatchSuppliesPane from './BatchSuppliesPane'
import SlimCandleCard from '../candles/SlimCandleCard'

export default function BatchPreviewPane({globalState, dispatch, fetchUserLibrary, batchId, setId}) {
    const api = new Api()
    const navigate = useNavigate();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(() => { return false })
    const [view, setView] = useState(() => { return "supplies" })
    const [batch, setBatch] = useState(() => { return false })
    const [recipe, setRecipe] = useState(() => { return false })
    const [supplyIds, setSupplyIds]  = useState(() => { return [] })
    const [recipeContainer, setRecipeContainer] = useState(() => { return false })
    const [candlePreviewSize, setCandlePreviewSize] = useState(() => { return 8 })
    const showMoreCandles = () => {
        setCandlePreviewSize(candlePreviewSize + 5)
    }
    const goToPath = (path) => {
        navigate(path)
    }
    const updateSupplyIdsInUse = () => {
        if (!recipe) return
        if (!batch) return
        try {
            const recipeSupplyIds = getSupplyIdsFromRecipe(recipe)
            const batchSupplyIds = getSupplyIdsFromBatch(batch)
            const uniqueSupplyIdsSet = new Set([...recipeSupplyIds, ...batchSupplyIds])
            const uniqueSupplyIds = Array.from(uniqueSupplyIdsSet)
            setSupplyIds(uniqueSupplyIds)
        } catch (error) {
            return
        }
    }
    const getCandles = () => {
        try {
            let allCandles = Array.from(globalState.candles)
            let relatedCandles = allCandles.filter((candle) => {
                if (batchId === candle.batch_id) return true
                return false
            })
            const sortedCandles = relatedCandles.sort((a, b) => {
                return parseISO(b.updatedAt) - parseISO(a.updatedAt)
            })
            return sortedCandles
        } catch (error) {
            return []
        }
    }
    const attemptRecipeDelete = () => {
        if (!batchId) return
        api.deleteBatch(batchId)
        .then((res) => {
            setId(false)
            fetchUserLibrary()
            navigate('/candles/batches')
        })
        .catch((err) => {
            console.error(err)
        })
    }
    const getContainer = () => {
        try {
            const recipe = batch.recipe[0]
            if (recipe.recipe_type === "wax_melt") return "Clamshell Mold"
            if (recipe.container_name) return recipe.container_name
            return "Mold"
        } catch (error) {
            return false
        }
    }
    const getAdditionalWickNameTextIfDouble = () => {
        try {
            if (recipe.wick_count > 1) return <span style={{color: "grey", letterSpacing: "2px"}}> x{recipe.wick_count}</span>
            if (recipe.wick_count === 2) return <span style={{color: "grey", letterSpacing: "2px"}}> x2</span>
            return false
        } catch (error) {
            return false
        }
    }
    const updateBatchWithNotes = (notes) => {
        api.updateBatch({batch_id: batchId, notes: notes})
        .then((res) => {
            fetchUserLibrary()
        })
        .catch((err) => {
            console.error(err)
        })
    }
    useEffect(() => {
        if (!batchId) return
        const allBatches = Array.from(globalState.batches)
        const selectedBatch = allBatches.find(r => r._id.toString() === batchId)
        setBatch(selectedBatch)
    // eslint-disable-next-line
    }, [batchId, globalState])
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        if (!batch) return
        if (!batch.recipe) return
        if (!batch.recipe[0]) return
        setRecipe(batch.recipe[0])
        updateSupplyIdsInUse()
    }, [batch])
    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "batches"}})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!recipe) return setRecipeContainer(false)
        if (!recipe.container_id) return setRecipeContainer(false)
        if (!globalState.supplies.length === 0) return setRecipeContainer(false)
        const containerSupply = getSupplyById(recipe.container_id, globalState.supplies)
        if (!containerSupply) return setRecipeContainer(false)
        setRecipeContainer(containerSupply)
        updateSupplyIdsInUse()
        // eslint-disable-next-line
    }, [recipe])
    return (
        <div className="general-page-create general-page-recipe general-page-preview-pane" style={{marginBottom: "60px"}}>
            {batch &&
            <>
            {showConfirmDeleteModal && <ModalConfirmAction hideModal={() => setShowConfirmDeleteModal(false)} confirmButtonText="Delete batch" text={`Delete batch ${batch.name}?  \n\n This will also permanently remove all candles associated with this batch.`} heading="⚠️ Delete Batch" takeActionFunction={attemptRecipeDelete} /> }
            <div className="general-preview-list-pane-header">
                <div className="general-page-create-section general-page-create-section-borderless general-page-create-section-heading">
                    <h1>{batch.name}</h1>             
                    <p>Batch {batch.recipe[0].visual_id}-{batch.visual_id} • Poured {formatDistanceToNow(parseISO(batch.pour_date))} ago</p>              
                </div>
                <div className="util-row util-align-center" style={{maxWidth: "100%", boxSizing: "border-box", marginRight: "12px", flexDirection: "row-reverse", gap: "24px"}}>
                    <div className="general-category-page-create" onClick={() => setShowConfirmDeleteModal(true)}>Delete Batch</div>
                </div>
            </div>
            </>
            }
            <InternalNavigationSections view={view} setView={setView} sections={["supplies", "recipe", "notes", "candles"]} />
            {batch &&
            <div>
                {view === "recipe" && recipe &&
                <div className="general-page-create-section general-page-create-section-margin-remove">
                    <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Name</div>
                            <div className="recipe-ingredient-value">{batch.recipe[0].name}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Recipe Number</div>
                            <div className="recipe-ingredient-value">{batch.recipe[0].visual_id}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            {(!batch.recipe[0].schema || batch.recipe[0].schema === 1) && 
                            <>
                                <div className="recipe-ingredient-name">Wax</div>
                                <div className="recipe-ingredient-value">{batch.recipe[0].wax}</div>
                            </>
                            }
                            {batch.recipe[0].schema === 2 &&
                            <>
                                <div className="recipe-ingredient-name">
                                    Wax
                                    {batch.recipe[0].wax_blend_map.blends.length > 1 && <span>Blend</span>}
                                </div>
                                {getWaxSchema2(batch.recipe[0], globalState.supplies)}
                            </>
                            }
                        </div>

                        {(!batch.recipe[0].schema || batch.recipe[0].schema === 1 || !batch.recipe[0].fragrance_oil_blend_map) && parseFloat(batch.recipe[0].fragrance_load) > 0 &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Fragrance Oil</div>
                            <div className="recipe-ingredient-value">{batch.recipe[0].fragrance_oil}</div>
                        </div>
                        }

                        {batch.recipe[0].schema === 2 && batch.recipe[0].fragrance_oil_blend_map && parseFloat(batch.recipe[0].fragrance_load) > 0 &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">
                                Fragrance Oil
                                {batch.recipe[0].fragrance_oil_blend_map.blends.length > 1 && <span>Blend</span>}
                            </div>
                            {getFOSchema2(batch.recipe[0], globalState.supplies)}
                        </div>
                        }
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Fragrance Load</div>
                            <div className="recipe-ingredient-value">{batch.recipe[0].fragrance_load}%</div>
                        </div>

                        {/* Non-supplyitem Vessels */}
                        {recipe.container_name && recipe.recipe_type === "container" &&
                        <>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Container</div>
                            <div className="recipe-ingredient-value">{getContainer()}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Capacity</div>
                            <div className="recipe-ingredient-value">{parseFloat(batch.recipe[0].vessel_capacity_grams).toFixed(0)} g ({parseFloat(batch.recipe[0].vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                        </div>
                        </>
                        }

                        {/* SupplyItem Vessels */}
                        {recipeContainer &&
                        <>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Container</div>
                            <div className="recipe-ingredient-value general-supply-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${recipeContainer._id}`)}>{recipeContainer.supply_name}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Capacity</div>
                            <div className="recipe-ingredient-value">{parseFloat(recipeContainer.properties.vessel_capacity_grams).toFixed(0)} g ({parseFloat(recipeContainer.properties.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                        </div>
                        </>
                        }

                        {/* Wick */}
                        {recipe.wick_in_recipe && recipe.wick_name && 
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Wick</div>
                            <div className="recipe-ingredient-value">{recipe.wick_name}{getAdditionalWickNameTextIfDouble()}</div>
                        </div>
                        }

                        {recipe.wick_in_recipe && recipe.wick_id && 
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Wick</div>
                            <div className="recipe-ingredient-value general-supply-clickable" onClick={() => goToPath(`/candles/inventory/supplies/${recipe.wick_id}`)}>{getSupplyById(recipe.wick_id, globalState.supplies).supply_name}{getAdditionalWickNameTextIfDouble()}</div>
                        </div>
                        }

                        {!recipe.wick_in_recipe && recipe.recipe_type === "container" &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Wick</div>
                            <div className="recipe-ingredient-value recipe-ingredient-value-highlight">Based on Batch</div>
                        </div>
                        }

                        {!batch.recipe[0].color_in_recipe &&
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Color</div>
                            <div className="recipe-ingredient-value">None</div>
                        </div>
                        }
                        {batch.recipe[0].color_in_recipe && !batch.recipe[0].color_map &&
                        <>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">Color</div>
                            <div className="recipe-ingredient-value">{batch.recipe[0].color_name}</div>
                        </div>
                        <div className="recipe-ingredient-row">
                            <div className="recipe-ingredient-name">
                                Color Density
                                <span>{batch.recipe[0].color_units} per Pound</span>
                            </div>
                            <div className="recipe-ingredient-value">{batch.recipe[0].color_drops_per_pound}</div>
                        </div>
                        </>
                        }
                        {batch.recipe[0].color_in_recipe && batch.recipe[0].color_map &&
                        <>
                        <div className="recipe-ingredient-row" style={{borderBottom: "0px solid transparent", paddingBottom: "0px"}}>
                            <div className="recipe-ingredient-name">Color Design</div>
                            <div className="recipe-ingredient-value"></div>
                        </div>
                        <div>
                            <div className="general-page-create-option-small-headings" style={{margin: "0px"}}>
                                <span>Color Name</span>
                                <span>{batch.recipe[0].color_units} per Pound</span>
                            </div>
                            {batch.recipe[0].color_map.colors.map((color, index) => (
                                <div className="recipe-ingredient-row" key={index} style={{margin: "4px 0px"}}>
                                    <div className="recipe-ingredient-value">{color.color_name}</div>
                                    <div className="recipe-ingredient-value">{color.color_drops_per_pound}</div>
                                </div>
                            ))}
                        </div>
                        </>
                        }
                    </div>
                    <div className="general-list-show-more-button" onClick={() => navigate(`/recipe/${recipe._id}`)}>Go to recipe</div>
                </div>
                }

                {view === "supplies" && <BatchSuppliesPane recipeContainer={recipeContainer} recipe={recipe} batch={batch} fetchUserLibrary={fetchUserLibrary} globalState={globalState} /> }

                {view === "notes" &&
                <div className="general-page-create-section">
                    <div>
                        <Notes notes={batch.notes} updateNote={updateBatchWithNotes} baseId={batch._id}/>
                    </div>
                    <div>
                        <InventoryItemNotes globalState={globalState} supplyIds={supplyIds}/>
                    </div>
                </div>
                }
                
                {view === "candles" &&
                <div className="general-page-create-section">
                    <div className="general-card-list">
                        {getCandles().slice(0, candlePreviewSize).map((candle) => (
                            <SlimCandleCard key={candle._id} candle={candle} globalState={globalState}/>
                        ))}
                        {candlePreviewSize < getCandles().length && 
                        <div className="general-list-show-more-button" onClick={showMoreCandles}>Show More</div>
                        }
                        {getCandles().length === 0 &&
                        <div className="general-card-list-text-empty">You haven't made any candles with this recipe yet</div>
                        }
                    </div>
                </div>
                }
            </div>
            }
        </div>
    )
}