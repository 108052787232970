import React from 'react'
import { parseISO, format } from 'date-fns'
import { HiStar } from 'react-icons/hi'

export default function PreviewRecipeCard({recipe, onSelect, selectedPreviewRecipeId}) {
    const getDateAsString = () => {
        try {
            return format(parseISO(recipe.updatedAt), 'PPP')
        } catch (error) {
            return ""
        }
    }
    const goToRecipe = () => {
        onSelect(recipe._id)
    }
    const getAdditionalCardListStyles = () => {
        try {
            if (selectedPreviewRecipeId === recipe._id) return { backgroundColor: "rgb(212, 212, 212)"}
            return {}
        } catch (error) {
            return {}
        }
    }
    return (
        <div className="general-card general-card-recipe" onClick={goToRecipe} style={getAdditionalCardListStyles()}>
            <div style={{overflow: "hidden"}}>
                <div className="general-card-name">{recipe.name}</div>
                <div className="general-card-id">{recipe.visual_id} • {getDateAsString()}</div>
            </div>
            {recipe.is_favorite &&
            <div className="general-card-favorite-symbol">
                <HiStar/>
            </div>
            }
        </div>
    )
}
