import React from 'react'
import { useReducer, useRef, useState, useEffect } from 'react'
import { useNavigate, useParams  } from 'react-router-dom'
import HeaderReturnToPage from '../misc/HeaderReturnToPage'
import { AiOutlineLeft } from 'react-icons/ai'
import { GrFormSubtract, GrFormAdd } from 'react-icons/gr'
import Api from '../../common/APIUtils'
import { convertCandleTypeRawToDisplay, gramsToOuncesString, getWaxSchema2, getFOSchema2, getSupplyById, getSupplyNameById } from '../../common/Helpers'

export default function CreateBatch({globalState, dispatch, fetchUserLibrary}) {
    const api = new Api()
    const navigate = useNavigate();
    const [page, setPage] = useState(() => { return 1 })
    const [isOunces, setIsOunces] = useState(() => { return false })
    const [recipe, setRecipe] = useState(() => { return false })
    const [recipeContainer, setRecipeContainer] = useState(() => { return false })
    const [candles, setCandles] = useState(() => { return [ { id: '1', wick: "" }] })
    const { recipeId } = useParams()
    const initialState = {
        count: 1,
        name: "",
        notes: "",
        recipe_id: "",
        buffer_percentage: 0,
        // wicks: [undefined]
        wicks: []
    }
    const [proposedBatch, proposedBatchDispatch] = useReducer((state, action) => {
        if (action.type === "count") {
            if (isNaN(parseInt(action.value)) && action.value !== "") return state
            if (action.value === "") return { ...state, count: "" }
            return { ...state, count: parseInt(action.value) }
        }
        if (action.type === "buffer") return { ...state, buffer_percentage: action.value }
        if (action.type === "notes") return { ...state, notes: action.value }
        if (action.type === "name") return { ...state, name: action.value }
        if (action.type === "wicks") return { ...state, wicks: action.value }
        return state
    }, initialState)
    const candleGeneratorRef = useRef(null)
    const wickSelectionRef = useRef(null)
    const batchSizeRef = useRef(null)
    const descriptionRef = useRef(null)
    const getPill = (proposedCandleAttribute, isOptional) => {
        let pillClass = "general-page-create-pill-form-validation"
        if (isOptional && proposedBatch[proposedCandleAttribute]) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Optional</div>
        if (isOptional) return <div className={`${pillClass} general-page-create-pill-form-validation-optional`}>Optional</div>
        if (!proposedBatch[proposedCandleAttribute]) return <div className={pillClass}>Required</div>
        if (proposedBatch[proposedCandleAttribute]) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
    }
    const adjustBufferPercentage = (adjustment) => {
        if (adjustment > 0 && parseFloat(proposedBatch.buffer_percentage) === 15) return
        if (adjustment < 0 && parseFloat(proposedBatch.buffer_percentage) === 0) return
        let proposedBuffer = parseFloat(proposedBatch.buffer_percentage) + parseFloat(adjustment)
        if (proposedBuffer > 15) proposedBuffer = 15
        if (proposedBuffer < 0) proposedBuffer = 0
        proposedBatchDispatch({type: "buffer", value: proposedBuffer.toFixed(1)})
    }
    const recipeRequiresWicksToBeSelected = () => {
        if (!recipe) return false
        if (recipe.wick_in_recipe) return false
        if (recipe.recipe_type === "wax_melt") return false
        return true
    }
    const getAdditionalWickNameTextIfDouble = () => {
        try {
            if (recipe.wick_count === 1) return false
            return <span style={{color: "grey", letterSpacing: "2px"}}> x{recipe.wick_count}</span>
        } catch (error) {
            return false
        }
    }
    const getWicksPill = () => {
        let pillClass = "general-page-create-pill-form-validation"
        if (validateWickArray()) return <div className={`${pillClass} general-page-create-pill-form-validation-pass`}>Required</div>
        return <div className={pillClass}>Required</div>
    }
    const getWickSelectionOptions = () => {
        return (
            <div className="general-page-create-options-single" ref={candleGeneratorRef}>
                {candles.map((candle) => (
                    <div key={candle.id} className="general-page-create-option-single">
                        <div className="general-page-create-option-multi-input-text-container">
                            <span>Candle #{candle.id}</span>
                            <input type="text" placeholder="Enter wick name" maxLength={36} onChange={(e) => updateCandleWicks(e.target.value, candle.id)} />
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    const validateWickArray = () => {
        try {
            if (!recipeRequiresWicksToBeSelected()) return true
            if (proposedBatch.wicks.length !== proposedBatch.count) return false
            if (proposedBatch.wicks.includes(undefined)) return false
            if (proposedBatch.wicks.includes("")) return false
            return true
        } catch (error) {
            return false
        }
    }
    const updateCandleWicks = (wickName, candleId) => {
        let wickArray = Array.from(proposedBatch.wicks)
        wickArray[parseInt(candleId) - 1] = wickName.trim()
        proposedBatchDispatch({ type: "wicks", value: wickArray })
    }
    const validateAndContinue = () => {
        // Validate
        if (!proposedBatch.count) return batchSizeRef.current.scrollIntoView()
        if (!validateWickArray()) return candleGeneratorRef.current.scrollIntoView()
        const candleCount = parseInt(proposedBatch.count)
        if (isNaN(candleCount)) return batchSizeRef.current.scrollIntoView()
        setPage(2)
    }
    const getWickDisplayValues = () => {
        try {
            const uniqueValues = {}
            const result = []
            const arr = proposedBatch.wicks
            let wickCount = 1
            if (recipe.wick_count) wickCount = recipe.wick_count
            for (const value of arr) {
                if (uniqueValues.hasOwnProperty(value)) {
                    uniqueValues[value]++
                } else {
                    uniqueValues[value] = 1
                }
            }
            for (const [value, count] of Object.entries(uniqueValues)) {
                result.push((count * wickCount) > 1 ? `${value} x ${count * wickCount}` : value)
            }
            return result 
        } catch (error) {
            return []
        }
    }
    const submitBatch = () => {
        // Submit
        const waxWeightGrams = calculateWax(true)
        const fragranceWeightGrams = calculateFragranceOil(true)
        let payload = { ...proposedBatch, recipe_id: recipeId, weight_wax_grams: waxWeightGrams, weight_fragrance_grams: fragranceWeightGrams }
        // Actually create
        api.createNewBatch(payload)
        .then((res) => {
            fetchUserLibrary(res.data._id)
            return navigate(`/candles/batch/${res.data._id}`)
        })
        .catch((err) => {
            console.error(err)
            return navigate('/candles/batches')
        })        
    }
    const updateSelectedRecipe = () => {
        try {
            const r = globalState.recipes.find(r => r._id === recipeId)
            setRecipe(r)
        } catch (error) {
            setRecipe(false)
        }
    }
    const getWicksSection = () => {
        if (recipe.wick_in_recipe && recipe.wick_name) return `${recipe.wick_name} x ${proposedBatch.count * recipe.wick_count}`
        if (recipe.wick_in_recipe && recipe.wick_id) return `${getSupplyById(recipe.wick_id, globalState.supplies).supply_name} x ${proposedBatch.count * recipe.wick_count}`
        const currentWicks = getWickDisplayValues()
        return (
            <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                {currentWicks.map((wick) => (
                    <span key={wick}>{wick}</span>
                ))}
            </div>
        )
    }
    const hasWicks = () => {
        if (recipe.recipe_type === "wax_melt") return false
        return true
    }
    const calculateFragranceOil = (asGrams) => {
        let totalWeightGrams = getTotalBatchWeightGrams()
        if (!totalWeightGrams) return false
        const fragranceLoad = recipe.fragrance_load
        const waxWeightGrams = totalWeightGrams/(1 + (fragranceLoad/100) )
        const fragranceWeightGrams = totalWeightGrams - waxWeightGrams
        if (!isOunces || asGrams) return parseFloat(fragranceWeightGrams).toFixed(0)
        return parseFloat(fragranceWeightGrams/28.3495).toFixed(2)
    }
    const getWaxWithBuffer = () => {
        // Calculates and returns grams
        try {
            const totalWeightGramsPure = getTotalBatchWeightGrams()
            if (!totalWeightGramsPure) return false
            const bufferedWaxWeightGrams = totalWeightGramsPure / (1 + (recipe.fragrance_load/100)) * (1 + (proposedBatch.buffer_percentage/100))
            return bufferedWaxWeightGrams
        } catch (error) {
            return 0
        }
    }
    const getFragranceWithBuffer = () => {
        // Calculates and returns grams
        try {
            const totalWeightGramsPure = getTotalBatchWeightGrams()
            const totalBufferedWeightGrams = totalWeightGramsPure * (1 + (proposedBatch.buffer_percentage/100))
            const bufferedWaxWeightGrams = getWaxWithBuffer()
            const bufferedFragranceWeight = totalBufferedWeightGrams - bufferedWaxWeightGrams
            return bufferedFragranceWeight
        } catch (error) {
            return 0
        }
    }
    const getAdditionalUnitsClass = (unitIsOunces) => {
        if (unitIsOunces && isOunces) return "general-page-item-unit-selection-selected"
        if (!unitIsOunces && !isOunces) return "general-page-item-unit-selection-selected"
        return ""
    }
    const getCandleCapacityGrams = () => {
        let capacityGrams = 82 // standard size of a wickless candle, clamshell mold
        if (recipeContainer) capacityGrams = recipeContainer.properties.vessel_capacity_grams
        if (recipe.vessel_capacity_grams && recipe.container_name) capacityGrams = recipe.vessel_capacity_grams
        return capacityGrams
    }
    const getColorDropsAsString = (drops) => {
        let colorUnits = "drops"
        try {
            if (recipe.color_units) colorUnits = recipe.color_units.toLowerCase()
            if (!recipe.color_in_recipe || !drops) return `0 ${colorUnits}`
            let totalWeightGramsPure = getTotalBatchWeightGrams()
            let totalWeightGrams = totalWeightGramsPure * (1 + (proposedBatch.buffer_percentage/100))
            let totalWeightOunces = totalWeightGrams /28.3495
            let dropsRaw = drops * totalWeightOunces / 16 // 16 ounces in an ounce
            // let dropsRaw = recipe.color_drops_per_pound * totalWeightOunces / 16 // 16 ounces in an ounce
            let dropsLowEnd = Math.floor(dropsRaw)
            let dropsHighEnd = Math.ceil(dropsRaw)
            return `${dropsLowEnd} - ${dropsHighEnd} ${colorUnits}`
        } catch (error) {
            return `0 ${colorUnits}`
        }        
    }
    const getTotalBatchWeightGrams = () => {
        if (!proposedBatch.count) return false
        let capacityGrams = getCandleCapacityGrams()
        const batchSize = proposedBatch.count
        // const totalWeightGrams = Math.round(batchSize * capacityGrams, 0) // possible source of error
        const totalWeightGrams = batchSize * capacityGrams
        return totalWeightGrams
    }
    const getUnitsLabel = () => {
        if (isOunces) return "oz"
        return "grams"
    }
    const calculateWax = (asGrams) => {
        let totalWeightGrams = getTotalBatchWeightGrams()
        if (!totalWeightGrams) return false
        const fragranceLoad = recipe.fragrance_load
        const waxWeightGrams = totalWeightGrams/(1 + (fragranceLoad/100) )
        // if (!isOunces || asGrams) return Math.round(waxWeightGrams, 0) // possible source of error
        if (!isOunces || asGrams) return parseFloat(waxWeightGrams).toFixed(0)
        return parseFloat(waxWeightGrams/28.3495).toFixed(2)
    }
    const calculatePartialWaxLoadGrams = (weight) => {
        // let waxWeightGrams = calculateWax(true)
        let waxWeightGrams = getWaxWithBuffer()
        if (!waxWeightGrams) return false
        return parseFloat(waxWeightGrams * weight / 100).toFixed(0)
    }
    const calculatePartialFOLoadGrams = (weight) => {
        // let foWeightGrams = calculateFragranceOil(true)
        let foWeightGrams = getFragranceWithBuffer()
        if (!foWeightGrams) return false
        return parseFloat(foWeightGrams * weight / 100).toFixed(0)
    }
    const getWaxBlendLength = () => {
        try {
            if (!recipe) return 0
            return recipe.wax_blend_map.blends.length
        } catch (error) {
            return 0
        }
    }
    const rebuildCandles = () => {
        recipeRequiresWicksToBeSelected()
        // rebuilds anytime the count is changed to build a loopable
        // set of candles that need wicks assigned
        let currentCandles = Array.from(candles)
        const batchCount = proposedBatch.count
        // filter what doesn't belong
        let filteredCandles = currentCandles.filter((candle) => {
            if (parseInt(candle.id) > batchCount) return false
            return true
        })
        // add missing
        let updatedCandles = Array.from(filteredCandles)
        for (let i = 1; i <= batchCount; i++) {
            const existingCandle = filteredCandles.find(c => c.id.toString() === i.toString())
            if (!existingCandle) updatedCandles.push({ id: i.toString(), wick: "" })
        }
        setCandles(updatedCandles)
        // Remove any parts of the wick array that don't belong
        let wickArray = Array.from(proposedBatch.wicks)
        wickArray = wickArray.slice(0, updatedCandles.length)
        proposedBatchDispatch({ type: "wicks", value: wickArray })
    }
    useEffect(() => {
        if (!recipe) return setRecipeContainer(false)
        if (!recipe.container_id) return setRecipeContainer(false)
        if (!globalState.supplies.length === 0) return setRecipeContainer(false)
        const containerSupply = getSupplyById(recipe.container_id, globalState.supplies)
        if (!containerSupply) return setRecipeContainer(false)
        setRecipeContainer(containerSupply)
    // eslint-disable-next-line
    }, [recipe])
    useEffect(() => {
        if (!recipeId) return
        updateSelectedRecipe()
    // eslint-disable-next-line
    }, [recipeId, globalState])
    useEffect(() => {
        if (!proposedBatch.count) return
        rebuildCandles()
    // eslint-disable-next-line
    }, [proposedBatch.count])
    useEffect(() => {
        window.scrollTo(0,0);
        dispatch({type: "nav", payload: {view: "batches"}})
    // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!globalState.library_loaded) fetchUserLibrary()
    // eslint-disable-next-line
    }, [globalState])
    return (
        <div className="general-page-create">
        {page === 1 && recipe &&
        <div>
            <div className="general-page-create-section general-page-create-section-borderless ">
                <HeaderReturnToPage title={recipe.name} navigationUrl={`/recipe/${recipeId}`}/>
                <h1>New Batch</h1>
                <p>Batches are recipes in action.  Choose a recipe, then decide how many candles you want to create with it.</p>
            </div>

            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Batch Name</h2>
                    <div>{getPill('name', true)}</div>
                </div>
                <div className="general-page-create-input-text">
                    <input type="text" placeholder="What should this batch be called?" value={proposedBatch.name} onChange={(e) => proposedBatchDispatch({type: "name", value: e.target.value})} maxLength={48} />
                </div>
            </div>

            {recipe &&
            <div className="general-page-create-section general-page-create-section-readonly">
                <div className="recipe-ingredients-list recipe-ingredients-list-detailed">
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Recipe</div>
                        <div className="recipe-ingredient-value">{recipe.name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Recipe Number</div>
                        <div className="recipe-ingredient-value">{recipe.visual_id}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        {(!recipe.schema || recipe.schema === 1) && 
                        <>
                        <div className="recipe-ingredient-name">Wax</div>
                        <div className="recipe-ingredient-value">{recipe.wax}</div>
                        </>}
                        {recipe.schema === 2 &&
                        <>
                        <div className="recipe-ingredient-name">
                            Wax
                           {getWaxBlendLength() > 1 && <span>Blend</span>} 
                        </div>
                        {getWaxSchema2(recipe, globalState.supplies)}
                        </>
                        }
                        
                    </div>

                    {parseFloat(recipe.fragrance_load) > 0 && 
                    <div className="recipe-ingredient-row">
                        {(!recipe.schema || recipe.schema === 1) && 
                        <>
                        <div className="recipe-ingredient-name">Fragrance Oil</div>
                        <div className="recipe-ingredient-value">{recipe.fragrance_oil}</div>
                        </>
                        }

                        {recipe.schema === 2 && recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0 &&
                        <>
                        <div className="recipe-ingredient-name">
                            Fragrance Oil
                            {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>Blend</span>}
                        </div>
                        {getFOSchema2(recipe, globalState.supplies)}
                        </>
                        }
                        
                        {recipe.schema === 2 && !recipe.fragrance_oil_blend_map &&
                        <>
                        <div className="recipe-ingredient-name">Fragrance Oil</div>
                        <div className="recipe-ingredient-value">{recipe.fragrance_oil}</div>
                        </>
                        }
                        
                    </div>
                    }
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Fragrance Load</div>
                        <div className="recipe-ingredient-value">{recipe.fragrance_load}%</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Type</div>
                        <div className="recipe-ingredient-value">{convertCandleTypeRawToDisplay(recipe.recipe_type)}</div>
                    </div>

                    {recipe.wick_in_recipe && recipe.wick_name && 
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Wick</div>
                        <div className="recipe-ingredient-value">{recipe.wick_name}{getAdditionalWickNameTextIfDouble()}</div>
                    </div>
                    }

                    {recipe.wick_in_recipe && recipe.wick_id && 
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Wick</div>
                        <div className="recipe-ingredient-value general-supply-clickable" onClick={() => navigate(`/candles/inventory/supplies/${recipe.wick_id}`)}>{getSupplyById(recipe.wick_id, globalState.supplies).supply_name}{getAdditionalWickNameTextIfDouble()}</div>
                    </div>
                    }

                    {/* Non-supplyitem Vessels */}
                    {recipe.recipe_type === "container" && recipe.container_name &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel</div>
                        <div className="recipe-ingredient-value">{recipe.container_name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel Size</div>
                        <div className="recipe-ingredient-value">{parseFloat(recipe.vessel_capacity_grams).toFixed(0)} g ({parseFloat(recipe.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                    </div>
                    </>
                    }

                    {/* SupplyItem Vessels */}
                    {recipeContainer &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel</div>
                        <div className="recipe-ingredient-value">{recipeContainer.supply_name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Vessel Size</div>
                        <div className="recipe-ingredient-value">{parseFloat(recipeContainer.properties.vessel_capacity_grams).toFixed(0)} g ({parseFloat(recipeContainer.properties.vessel_capacity_grams/28.3495).toFixed(2)} oz)</div>
                    </div>
                    </>
                    }

                    {!recipe.color_in_recipe &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Color</div>
                        <div className="recipe-ingredient-value">None</div>
                    </div>
                    }
                    {recipe.color_in_recipe && !recipe.color_map &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">Color</div>
                        <div className="recipe-ingredient-value">{recipe.color_name}</div>
                    </div>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Color Density
                            <span>{recipe.color_units} per Pound</span>
                        </div>
                        <div className="recipe-ingredient-value">{recipe.color_drops_per_pound}</div>
                    </div>
                    </>
                    }
                    {recipe.color_in_recipe && recipe.color_map &&
                    <>
                    <div className="recipe-ingredient-row" style={{borderBottom: "0px solid transparent", paddingBottom: "0px"}}>
                        <div className="recipe-ingredient-name">Color Design</div>
                        <div className="recipe-ingredient-value"></div>
                    </div>
                    <div>
                        <div className="general-page-create-option-small-headings" style={{margin: "0px"}}>
                            <span>Name</span>
                            <span>{recipe.color_units} per Pound</span>
                        </div>
                        {recipe.color_map.colors.map((color, index) => (
                            <div className="recipe-ingredient-row" key={index} style={{margin: "4px 0px"}}>
                                <div className="recipe-ingredient-value">{color.color_name}</div>
                                <div className="recipe-ingredient-value">{color.color_drops_per_pound}</div>
                            </div>
                        ))}
                        
                    </div>
                    </>
                    }
                </div>
            </div>
            }

            <div className="general-page-create-section" ref={batchSizeRef}>
                <div className="general-page-create-section-header">
                    <h2>Batch Size</h2>
                    <div>{getPill('count')}</div>
                </div>
                {recipeRequiresWicksToBeSelected() && 
                <>
                <p>Recipes without a specific wick have limited batch sizes</p>
                <div className="general-page-create-options-single">
                    <input autoComplete="off" type='range' min="1" max="8" list='size' value={proposedBatch.count} onChange={(e) => proposedBatchDispatch({type: "count", value: e.target.value})}/>
                    <datalist id="size">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option value="7"></option>
                        <option value="8"></option>
                    </datalist>
                </div>
                <div className="general-page-create-options-single">
                    <div className="general-page-create-option-single">{proposedBatch.count}</div>
                </div>
                </>
                }
                {!recipeRequiresWicksToBeSelected() && 
                <div className="general-page-create-options-single">
                    <input style={{fontSize: "16px", padding: "12px"}} type='number' min="1" value={proposedBatch.count} onChange={(e) => proposedBatchDispatch({type: "count", value: e.target.value})}/>
                </div>
                }                
            </div>

            {recipeRequiresWicksToBeSelected() && hasWicks() &&
            <div className="general-page-create-section" ref={wickSelectionRef}>
                <div className="general-page-create-section-header">
                    <h2>Select Wicks</h2>
                    <div>{getWicksPill()}</div>
                </div>
                <div className="general-page-create-options-single">
                    {getWickSelectionOptions()}
                </div>
            </div>
            }

            {!recipeRequiresWicksToBeSelected() && hasWicks() &&
            <div className="general-page-create-section" ref={wickSelectionRef}>
                <div className="general-page-create-section-header">
                    <h2>Wick</h2>
                    <div className="general-page-create-pill-form-validation" style={{backgroundColor: "var(--ml-color-confirmation)", color: "white"}}>In Recipe</div>
                </div>
                <div className="general-page-create-options-single">
                    {recipe.wick_name && <div>{recipe.wick_name}{getAdditionalWickNameTextIfDouble()}</div>}
                    {recipe.wick_id && <div>{getSupplyById(recipe.wick_id, globalState.supplies).supply_name}{getAdditionalWickNameTextIfDouble()}</div>}
                </div>
            </div>
            }

            <div className="general-page-create-section">
                <div className="general-page-create-section-header">
                    <h2>Production Buffer</h2>
                    <div className="general-page-create-pill-form-validation general-page-create-pill-form-validation-optional">Optional</div>
                </div>
                <p>Additional wax and fragrance to account for loss or shrinkage in your process.  Will not affect the final candle sizes.</p>
                <div className="general-page-create-options-single">
                    <input autoComplete="off" type='range' step="0.1" min="0" max="15" list='size' value={proposedBatch.buffer_percentage} onChange={(e) => proposedBatchDispatch({type: "buffer", value: e.target.value})}/>
                    <datalist id="size">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option value="3"></option>
                        <option value="4"></option>
                        <option value="5"></option>
                        <option value="6"></option>
                        <option value="7"></option>
                        <option value="8"></option>
                        <option value="9"></option>
                        <option value="10"></option>
                        <option value="11"></option>
                        <option value="12"></option>
                        <option value="13"></option>
                        <option value="14"></option>
                        <option value="15"></option>
                    </datalist>
                </div>
                <div className="general-page-create-options-single">
                    <div className="util-row util-align-center" style={{gap: "14px"}}>
                        <span className="general-page-create-button-adjust" onClick={() => adjustBufferPercentage(-0.5)}><GrFormSubtract/></span>
                        <div className="general-page-create-option-single">{proposedBatch.buffer_percentage}%</div>
                        <span className="general-page-create-button-adjust" onClick={() => adjustBufferPercentage(0.5)}><GrFormAdd/></span>
                    </div>
                </div>            
            </div>

            <div className="general-page-create-section" ref={descriptionRef}>
                <div className="general-page-create-section-header">
                    <h2>Notes</h2>
                    <div>{getPill('notes', true)}</div>
                </div>
                <div className="general-page-create-options-single">
                    <textarea style={{fontSize: "16px"}} rows="4" placeholder="Add any notes to this batch" value={proposedBatch.description} onChange={(e) => proposedBatchDispatch({type: "notes", value: e.target.value})}></textarea>
                </div>
            </div>

            <div className="general-page-create-section">
                <div className="general-page-create-button-create">
                    <button onClick={validateAndContinue}>Continue</button>
                </div>
            </div>
        </div>
        }

        {page === 2 &&
        <div>
            <div className="general-page-create-section general-page-create-section-borderless">
                <div className="general-page-create-section-header">
                    <div style={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={() => setPage(1)}>
                        <AiOutlineLeft/>
                        <h2>Review</h2>
                    </div>
                    
                </div>
                <p>Here are your batch details and the supplies required:</p>
            </div>

            <div className="general-page-create-section">
                <div className="general-page-item-view-header">
                    <h3>Required Supplies</h3>
                </div>
                <div className="general-page-item-unit-selection util-justify-center">
                    <div className="util-row util-align-center" style={{gap: "12px"}}>
                        <span onClick={() => setIsOunces(false)} className={getAdditionalUnitsClass(false)}>grams</span>
                        <span onClick={() => setIsOunces(true)} className={getAdditionalUnitsClass(true)}>ounces</span>
                    </div>
                </div>
                
                <div className="recipe-ingredients-list recipe-ingredients-list-detailed">

                    {(recipe.schema === 1 || !recipe.schema) &&
                    <>
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipe.wax}
                            <span>Wax</span>
                        </div>
                        {!isOunces && <div className="recipe-ingredient-value">{calculateWax()} {getUnitsLabel()}</div>}
                        {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculateWax(true))}</div> }
                    </div>
                    </>
                    }
                    {recipe.schema === 2 &&
                    <div className="recipe-ingredient-row-multi">
                        <div className="recipe-ingredient-row recipe-ingredient-row-primary">
                            <div className="recipe-ingredient-name">
                                Wax
                            </div>
                            {!isOunces && <div className="recipe-ingredient-value">{getWaxWithBuffer().toFixed(0)} {getUnitsLabel()}</div>}
                            {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(getWaxWithBuffer())}</div>}
                        </div>
                        {recipe.wax_blend_map.blends.map((blend) => (
                            <div className="recipe-ingredient-row" key={blend.name}>
                                <div className="recipe-ingredient-name">
                                    {blend.supply_id && getSupplyNameById(globalState.supplies, blend.supply_id)}
                                    {!blend.supply_id && blend.name}
                                    {recipe.wax_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                                {!isOunces && <div className="recipe-ingredient-value">{calculatePartialWaxLoadGrams(blend.weight)} grams</div>}
                                {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculatePartialWaxLoadGrams(blend.weight))}</div> }
                            </div>
                        ))}
                    </div>
                    }

                    {(!recipe.schema || recipe.schema === 1) && parseFloat(recipe.fragrance_load) > 0 &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipe.fragrance_oil}
                            <span>Fragrance Oil</span>
                        </div>
                        {!isOunces && <div className="recipe-ingredient-value">{calculateFragranceOil()} {getUnitsLabel()}</div>}
                        {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculateFragranceOil(true))}</div>}
                    </div>
                    }

                    {recipe.schema === 2 && recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0 &&
                    <div className="recipe-ingredient-row-multi">
                        <div className="recipe-ingredient-row recipe-ingredient-row-primary">
                            <div className="recipe-ingredient-name">
                                Fragrance Oil
                            </div>
                            {!isOunces && <div className="recipe-ingredient-value">{getFragranceWithBuffer().toFixed(0)} {getUnitsLabel()}</div>}
                            {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(getFragranceWithBuffer())}</div>}
                        </div>
                        {recipe.fragrance_oil_blend_map.blends.map((blend) => (
                            <div className="recipe-ingredient-row" key={blend.name}>
                                <div className="recipe-ingredient-name">
                                    {blend.supply_id && getSupplyNameById(globalState.supplies, blend.supply_id)}
                                    {!blend.supply_id && blend.name}
                                    {recipe.fragrance_oil_blend_map.blends.length > 1 && <span>{blend.weight}%</span>}
                                </div>
                                {!isOunces && <div className="recipe-ingredient-value">{calculatePartialFOLoadGrams(blend.weight)} grams</div>}
                                {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculatePartialFOLoadGrams(blend.weight))}</div> }
                            </div>
                        ))}
                    </div>
                    }
                    
                    {recipe.schema === 2 && !recipe.fragrance_oil_blend_map && parseFloat(recipe.fragrance_load) > 0 &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipe.fragrance_oil}
                            <span>Fragrance Oil</span>
                        </div>
                        {!isOunces && <div className="recipe-ingredient-value">{calculateFragranceOil()} {getUnitsLabel()}</div>}
                        {isOunces && <div className="recipe-ingredient-value">{gramsToOuncesString(calculateFragranceOil(true))}</div>}
                    </div>
                    }

                    {parseFloat(recipe.fragrance_load) === 0 &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Fragrance Oil
                        </div>
                        <div className="recipe-ingredient-value">None</div>
                    </div>
                    }

                    {hasWicks() &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Wicks
                            {recipe.wick_count === 2 && <span>Double wick</span>}
                            {recipe.wick_count === 3 && <span>Triple wick</span>}
                            {recipe.wick_count === 4 && <span>Quad wick</span>}
                        </div>
                        <div className="recipe-ingredient-value">{getWicksSection()}</div>
                    </div>
                    }
                    {!hasWicks() &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            Clamshell Molds
                            <span>Wax Melts</span>
                        </div>
                        <div className="recipe-ingredient-value">{proposedBatch.count}</div>
                    </div>
                    }
                    {/* Non-supplyitem Vessels */}
                    {recipe.recipe_type === "container" && recipe.container_name &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipe.container_name}
                            <span>Container</span>
                        </div>
                        <div className="recipe-ingredient-value">{proposedBatch.count}</div>
                    </div>
                    }
                    {/* Supplyitem Vessels */}
                    {recipeContainer &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipeContainer.supply_name}
                            <span>Container</span>
                        </div>
                        <div className="recipe-ingredient-value">{proposedBatch.count}</div>
                    </div>
                    }
                    {recipe.color_in_recipe && !recipe.color_map &&
                    <div className="recipe-ingredient-row">
                        <div className="recipe-ingredient-name">
                            {recipe.color_name}
                            <span>Color</span>
                        </div>
                        <div className="recipe-ingredient-value">{getColorDropsAsString(recipe.color_drops_per_pound)}</div>
                    </div>
                    }
                    {recipe.color_in_recipe && recipe.color_map &&
                        <>
                        {recipe.color_map.colors.map((color, index) => (
                        <div className="recipe-ingredient-row" key={index}>
                            <div className="recipe-ingredient-name">
                                {color.color_name}
                                <span>Color</span>
                            </div>
                            <div className="recipe-ingredient-value">{getColorDropsAsString(color.color_drops_per_pound)}</div>
                        </div>   
                        ))}
                        </>
                    }
                </div>
                {Boolean(proposedBatch.buffer_percentage) && Boolean(proposedBatch.buffer_percentage !== "0") && 
                <div className="util-row util-align-center util-justify-center" style={{marginTop: "16px"}}>
                    <span className="batch-buffer-pill">+{proposedBatch.buffer_percentage}% Buffer</span>
                </div>
                }
            </div>

            <div className="general-page-create-section general-page-create-section-borderless">
                <div className="general-page-create-button-create">
                    <button onClick={submitBatch}>Create batch</button>
                </div>
            </div>
        </div>
        }

    </div>
    )
}
