import React from 'react'
import { useState } from 'react'
import { MdClear } from 'react-icons/md'
import { convertCandleTypeRawToDisplay, getSupplyNameById } from '../../common/Helpers'
import { HiStar } from 'react-icons/hi'
import { parseISO, format } from 'date-fns'
import GeneralPageSearchBar from '../gadgets/GeneralPageSearchBar'

export default function ModalAddRecipeToCollection({recipes, hideModal, addRecipeToCollection, collection, globalState}) {
    const [query, setQuery] = useState(() => { return "" })
    const getDateAsString = (recipe) => {
        try {
            return format(parseISO(recipe.createdAt), 'PPP')
        } catch (error) {
            return ""
        }
    }
    const getWaxName = (recipe) => {
        if (!recipe.schema || recipe.schema === 1) return recipe.wax
        if (!recipe.wax_blend_map) return recipe.wax
        const blends = recipe.wax_blend_map.blends
        if (blends.length === 1) {
            if (blends[0].supply_id) return getSupplyNameById(globalState.supplies, blends[0].supply_id)
            return blends[0].name
        }
        return `${blends.length}-wax blend`
    }
    const generateDescription = (recipe) => {
        if (!recipe) return false
        let suffix = "candle"
        if (recipe.recipe_type === "wax_melt") suffix = false
        return<span>{getWaxName(recipe)} <span style={{textTransform: "lowercase"}}>{convertCandleTypeRawToDisplay(recipe.recipe_type)}</span> {suffix}</span>
    }
    const getRecipes = () => {
        if (!recipes) return hideModal()
        const sortedRecipes = recipes.sort((a, b) => {
            return parseISO(b.updatedAt) - parseISO(a.updatedAt)
        })
        if (!query.trim()) return sortedRecipes
        return sortedRecipes.filter((recipe) => {
            const recipeTextToSearch = `${recipe.name}${recipe.fragrance_oil}${recipe.description}${recipe.color_name}${recipe.visual_id}${recipe.wax}${recipe.container_name}${recipe.recipe_type}`
            if (recipeTextToSearch.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) return true
            return false
        })
    }
    return (
        <div className="modal-boundary">
            <div className="modal-backdrop">
                <div className="modal-holder">
                    <div className="modal-overlay">
                        
                        <div className="modal-header">
                            <h2>Add to {collection.name}</h2>
                            <MdClear onClick={hideModal}/>
                        </div>
                        <div className="general-category-page-utilities" style={{padding: 0}}>
                            <GeneralPageSearchBar searchTerm={query} setSearchTerm={setQuery} />
                        </div>
                        <div>
                            <div className="modal-edit-section">
                                <div className="general-card-list-recipes modal-collection-recipe-list-scrolls" style={{ flexDirection: "column", padding: 0 }}>
                                {getRecipes().map((recipe) => (
                                    <div className="general-card general-card-recipe" key={recipe._id} onClick={() => addRecipeToCollection(recipe._id)}>
                                        <div style={{overflow: "hidden"}}>
                                            <div className="general-card-name">{recipe.name}</div>
                                            <div className="general-card-id">{recipe.visual_id} • {getDateAsString(recipe)}</div>
                                            <div className="general-card-description-small">{generateDescription(recipe)}</div>
                                        </div>
                                        {recipe.is_favorite &&
                                        <div className="general-card-favorite-symbol">
                                            <HiStar/>
                                        </div>
                                        }
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
