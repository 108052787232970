import React from 'react'
import { parseISO, formatDistanceToNowStrict } from 'date-fns'

export default function PreviewBatchCard({batch, onSelect, selectedPreviewId}) {
    const getDateAsString = () => {
        try {
            return formatDistanceToNowStrict(parseISO(batch.pour_date))
        } catch (error) {
            return ""
        }
    }
    const getGone = () => {
        onSelect(batch._id)
    }
    const getAdditionalCardListStyles = () => {
        try {
            if (selectedPreviewId === batch._id) return { backgroundColor: "rgb(212, 212, 212)"}
            return {}
        } catch (error) {
            return {}
        }
    }
    return (
        <div className="general-card general-card-recipe" onClick={getGone} style={getAdditionalCardListStyles()}>
            <div>
                <div className="general-card-name">{batch.name}</div>
                <div className="general-card-id">{batch.recipe[0].visual_id}-{batch.visual_id} • {getDateAsString()} ago</div>
            </div>
        </div>
    )
}