import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation  } from 'react-router-dom'
import { logout, loginWithGoogle } from '../common/Armstrong';
import { ReactComponent as Logo } from '../static/assets/moonlite-logo-black.svg'
import { ReactComponent as LogoText } from '../static/assets/moonlite-logo-black-text.svg'
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'
import Api from '../common/APIUtils';

export default function Header({globalState, dispatch}) {
    const api = new Api()
    const location = useLocation();
    const profileMenuRef = useRef(null)
    const PUBLIC_PATHS = ['/privacy-policy']
    const [isShowingMenu, setIsShowingMenu] = useState(() => { return false })
    const navigate = useNavigate();
    const changeLoginState = (loginState) => {
        logout()
        dispatch({type: 'login', payload: {success: loginState}})
    }
    const navigateHome = () => {
        if (globalState.authenticated) return navigate('/home')
        return navigate('/')
    }
    const getAdditionalHeaderStyle = () => {
        if (globalState.authenticated) return { justifyContent: "space-between"}
        return { justifyContent: "center", marginTop: "40px" }
    }
    const goToSubscriptionManager = () => {
        try {
            api.getPlanManagementUrl()
            .then((res) => {
                if (res.data.response.url) return window.location.replace(res.data.response.url)
            })
            .catch((err) => { return })
        } catch (error) {
            return
        }
        // window.open('https://billing.stripe.com/p/login/bIYcOI5Gvg9UgCI3cc', '_blank');
    }
    const openSupportLink = () => {
        window.open('mailto:support@moonlite.app?subject=MoonLite%20Support%20Question');
    }
    const openPrivacyPolicy = () => {
        window.open('/privacy-policy');
    }
    const getNavigationClasses = (viewName) => {
        if (viewName === globalState.view) return "header-navigation-bar-selected"
        return ""
    }
    const updateView = (viewName) => {
        dispatch({type: "nav", payload: {view: viewName}})
        if (viewName === "home") return navigate("/home")
        return navigate(`/candles/${viewName}`);

    }
    useEffect(() => {
        if (PUBLIC_PATHS.includes(location.pathname)) return
        if (!globalState.authenticated && globalState.loaded) return navigate('/')
    // eslint-disable-next-line
    }, [globalState])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
                setIsShowingMenu(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [profileMenuRef])
    return (
        <div className="header-container" style={getAdditionalHeaderStyle()}>
            {!globalState.authenticated &&
            <div className="header-unauth-heading-row">
                <div className="header-logo-main" onClick={navigateHome}><Logo/></div>
                <div className="header-unauth-login-button" onClick={loginWithGoogle}>Log In</div>
            </div>
            
            }
            {globalState.authenticated &&
            <>
            <div className="header-logo-main header-logo-icon" onClick={navigateHome}><LogoText/></div>
            <div className="header-navigation-container">
                <span onClick={() => updateView("recipes")} className={getNavigationClasses("recipes")}>Recipes</span>
                <span onClick={() => updateView("batches")} className={getNavigationClasses("batches")}>Batches</span>
                <span onClick={() => updateView("candles")} className={getNavigationClasses("candles")}>Candles</span>
                <span onClick={() => updateView("inventory")} className={getNavigationClasses("inventory")}>Inventory</span>
            </div>
            <div className="header-profile-menu-container" ref={profileMenuRef}>
                <div className="util-row util-align-center" style={{gap: "12px", cursor: "pointer", fontSize: "16px"}} onClick={() => setIsShowingMenu(!isShowingMenu)}>
                    {globalState.user.picture &&
                    <div className="header-profile-picture">
                        <img src={globalState.user.picture} alt={`Profile for ${globalState.user.first_name}`} />
                    </div>
                    }
                    <div>{globalState.user.first_name}</div>
                    {!isShowingMenu && <HiOutlineChevronDown/>}
                    {isShowingMenu && <HiOutlineChevronUp/>}
                </div>
                {isShowingMenu &&
                <div className="header-profile-menu">
                    <span className="header-auth-button" onClick={goToSubscriptionManager}>My Account</span>
                    <span className="header-auth-button" onClick={openSupportLink}>Support</span>
                    <span className="header-auth-button" onClick={openPrivacyPolicy}>Privacy Policy</span>
                    <span className="header-auth-button" onClick={() => changeLoginState(false)}>Log Out</span>
                </div>
                }
                
            </div>
            </>
            }
        </div>
    )
}
