import React from 'react'
import { useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { getBadgesStringArray, convertBadgeName, getBadgeColorFromName } from '../../common/Helpers'
import { AiFillCheckSquare, AiOutlineBorder } from 'react-icons/ai'

export default function AddBadge({hideModal, candle, description}) {
    const [badges, setBadges] = useState(() => { return candle.badges })
    const removeBadge = (badgeName) => {
        let badgeArray = Array.from(badges)
        if (!badgeArray.includes(badgeName)) return
        const index = badgeArray.indexOf(badgeName)
        if (index < 0) return
        badgeArray.splice(index, 1)
        setBadges(badgeArray)
    }
    const addBadge = (badgeName) => {
        let badgeArray = Array.from(badges)
        if (badgeArray.includes(badgeName)) return
        badgeArray.push(badgeName)
        setBadges(badgeArray)
    }
    const getBadgeSection = () => {
        const allBadgeTypes = getBadgesStringArray()
        return (
            <div className="form-add-badge-list">
            {allBadgeTypes.map((badgeName) => (
                <div key={badgeName} className="form-add-badge-item">
                    <div className="form-add-badge-checkbox">
                        {badges.includes(badgeName) && <AiFillCheckSquare onClick={() => removeBadge(badgeName)}/> }
                        {!badges.includes(badgeName) && <AiOutlineBorder onClick={() => addBadge(badgeName)}/> }
                    </div>

                    <div className="util-row util-align-center form-add-badge-badge-name">
                        <span style={{backgroundColor: getBadgeColorFromName(badgeName)}}>{convertBadgeName(badgeName)}</span>
                    </div>
                    
                </div>
            ))}
            </div>
        )
    }
    return (
        <div className="general-page-create-section general-page-create-section-summary-card">
            <div className="util-row util-space-between util-align-start">
                <h2>Add Badge</h2>
                <MdOutlineClose onClick={() => hideModal(badges)} style={{cursor: "pointer"}}/>
            </div>
            <div className="form-add-badge-name">{candle.name}</div>
            <div className="form-add-badge-description" style={{marginBottom: "12px"}}>{description}</div>
            
            <div className="general-page-create-section-summary-card-inner">
                {getBadgeSection()}
            </div>
        </div>
    )
}
